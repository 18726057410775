import { RoutePaths, Messages } from "app/constants";
import { UNAUTHORIZE_ERROR_CODES } from "app/constants/unauthorizeErrorCodes";
import { CodeResponseApis } from "app/constants/codeResponseApis";
import { Action } from "../models/redux/action";
import { customNotifyError } from 'app/helpers';

const CHECK_AUTHORIZE = "CHECK_AUTHORIZE_EPIC"
const timeOutExpiredNotify = 3000;

/**
 * Handle when call api success
 * @param action 
 * @param data 
 * @param next 
 * @param isBooking 
 */
export const onEpicSuccess = (action: Action, data: any, next: any, isBooking = false, isAdmin = false) => {
  const code = data?.response?.code || data?.response?.error?.code;
  var checkAuthorize = false;
  if (code && UNAUTHORIZE_ERROR_CODES.includes(code)) {
    checkAuthorize = true;
  }
  if (checkAuthorize && !isBooking && !isAdmin) {
    // Login  need remove local storage.
    localStorage.clear();
    customNotifyError(Messages.Auth.AUTHENTICATION_FAILED, timeOutExpiredNotify, RoutePaths.Auth.Login);
    return {
      type: CHECK_AUTHORIZE,
    }
  }
  else {
    if (action.payload.callbacks) {
      if (action.payload.callbacks.onSuccess) {
        action.payload.callbacks.onSuccess(data);
      }
    }
    return next;
  }
};

/**
 * Handle when call api fail
 * @param action 
 * @param data 
 * @param next 
 * @param isBooking 
 */
export const onEpicFailed = (action: Action, data: any, next: any, isBooking = false, isAdmin = false) => {
  const code = data?.response?.code || data?.response?.error?.code;

  var checkAuthorize = false;
  // Error expired token then return screen login
  if (data.status === CodeResponseApis.Https.UNAUTHORIZE || (code && UNAUTHORIZE_ERROR_CODES.includes(code))) {
    checkAuthorize = true;
  }
  if (checkAuthorize && !isBooking) {
    // Login  need remove local storage.
    localStorage.clear();
    if (isAdmin) {
      if(action.payload.callbacks) {
        if (action.payload.callbacks.onFailed) {
          action.payload.callbacks.onFailed(data);
        }
      }
    } else {
      customNotifyError(Messages.Auth.AUTHENTICATION_FAILED, timeOutExpiredNotify, RoutePaths.Auth.Login);
    }
    return {
      type: CHECK_AUTHORIZE,
    }
  }
  else {
    if (action.payload.callbacks) {
      if (action.payload.callbacks.onFailed) {
        action.payload.callbacks.onFailed(data);
      }
    }
    return next;
  }
};
