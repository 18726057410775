import React, { FunctionComponent, useRef } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import './style.scss';

interface Props {
	label: string;
	to: string;
	activeOnlyWhenExact?: boolean;
	activeIcon: string;
	baseIcon: string;
	mobile?: boolean;
}

/**
 * Component CustomMenuLink
 */
const CustomMenuLink: FunctionComponent<Props> = ({ label, to, activeOnlyWhenExact, activeIcon, baseIcon, mobile }) => {
	let match = useRouteMatch({
		path: to,
	});
	const link = useRef<any>(null)

	return (
		<Link
			to={to}
			title={label}
			className={`${match ? 'active' : ' '} custom-link__link ${mobile ? 'custom-link__link--mobile' : ' '} `}
			tabIndex={0}
			onClick={() => {
				if (link?.current) {
					link?.current.blur()
				}
			}}
			ref={link}
		>
			{match ? <img src={activeIcon} alt={label} /> : <img src={baseIcon} alt={label} />}
			<span>{label}</span>
		</Link>
	);
};

export default CustomMenuLink;
