/**
 * Async Load Helper
 * @param data 
 */
export const asyncLoadHelper = (data: { label: string; value: string | number; }[]) => ({
  options: data.map((option: { value: string | number, label: string }) => ({
    value: option.value,
    label: option.label,
  })),
  hasMore: false
});