import { FunctionComponent, useEffect } from "react";
import { connect } from "react-redux";
import { disableLoading, enableLoading } from "../redux/modules/loading";

interface Props {
  enableLoading: (opacity?: number) => void;
  disableLoading: () => void;
}

const mapDispatchToProps = {
  enableLoading,
  disableLoading,
};

/**
 * Component show Splash Screen
 */
const BaseSplashScreen: FunctionComponent<Props> = ({
  enableLoading,
  disableLoading,
}) => {
  useEffect(() => {
    enableLoading(1);
    return () => {
      disableLoading();
    };
  }, []);
  return null;
};

export const SplashScreen = connect(null, mapDispatchToProps)(BaseSplashScreen);
