import { Action } from "app/models/redux/action";
import { ReduxCallbacks } from "app/models/redux/Callbacks";
import { CancelUserContractModel } from "app/models/redux/ContractInfo";

export const GET_USER_CONTRACT = "GET_USER_CONTRACT";
export const GET_USER_CONTRACT_SUCCESS = "GET_USER_CONTRACT_SUCCESS";
export const GET_USER_CONTRACT_FAILED = "GET_USER_CONTRACT_FAILED";
export const GET_USER_CONTRACT_CANCELLED = "GET_USER_CONTRACT_CANCELLED";
export const CANCEL_USER_CONTRACT = "CANCEL_USER_CONTRACT";
export const CANCEL_USER_CONTRACT_SUCCESS = "CANCEL_USER_CONTRACT_SUCCESS";
export const CANCEL_USER_CONTRACT_FAILED = "CANCEL_USER_CONTRACT_FAILED";
export const CANCEL_USER_CONTRACT_CANCELLED = "CANCEL_USER_CONTRACT_CANCELLED";

export interface CancelUserContract {
    form: CancelUserContractModel
};
/**
 * Action get user contract_infomation
 */
export const getUserContract = (): Action => ({
    type: GET_USER_CONTRACT
});

/**
 * Action get user contract_infomation success
 * @param payload
 */
export const getUserContractSuccess = (
    payload: any[]
): Action<any[]> => ({
    type: GET_USER_CONTRACT_SUCCESS,
    payload,
});

/**
 * Action get user contract_infomation failed
 * @param error
 */
export const getUserContractFailed = (error: any): Action => ({
    type: GET_USER_CONTRACT_FAILED,
    payload: error
});

/**
 * Action cancel get user contract_infomation
 */
export const getUserContractCancelled = (): Action => ({
    type: GET_USER_CONTRACT_CANCELLED,
});


/**
 * Action cancel user contract
 * @param body 
 * @param callbacks 
 * @returns 
 */
export const cancelUserContract = (
    body: any,
    callbacks?: ReduxCallbacks
): Action => ({
    type: CANCEL_USER_CONTRACT,
    payload: { body, callbacks }
});

/**
 * Action cancel user contract sucess
 * @param payload 
 * @returns 
 */
export const cancelUserContractSuccess = (payload: any): Action<any> => ({
    type: CANCEL_USER_CONTRACT_SUCCESS,
    payload
});

/**
 * Action cancel user contract failed
 * @param error 
 * @returns 
 */
export const cancelUserContractFailed = (error: any): Action => ({
    type: CANCEL_USER_CONTRACT_FAILED,
    payload: error
});

/**
 * Action cancel user contract cancelled 
 */
 export const cancelUserContractCancelled = (): Action => ({
    type: CANCEL_USER_CONTRACT_CANCELLED,
  });