import { Permission } from "app/models/Permission";
import { ReduxCallbacks } from "app/models/redux/Callbacks";
import { Action } from "app/models/redux/action";
import { User } from "app/models/User";
import { LoginUser } from "app/models/LoginUser";
import { LoginByGoogle } from "app/models/LoginByGoogle";
import { useCallback } from 'react';

export const LOGIN = "LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const LOGIN_CANCELLED = "LOGIN_CANCELLED";

export const LOGIN_GOOGLE = "LOGIN_GOOGLE";
export const LOGIN_GOOGLE_SUCCESS = "LOGIN_GOOGLE_SUCCESS";
export const LOGIN_GOOGLE_FAILED = "LOGIN_GOOGLE_FAILED";
export const LOGIN_GOOGLE_CANCELLED = "LOGIN_GOOGLE_CANCELLED";

export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS';
export const REFRESH_TOKEN_FAILED = 'REFRESH_TOKEN_FAILED';
export const REFRESH_TOKEN_CANCELLED = 'REFRESH_TOKEN_CANCELLED';

export const FETCH_CURRENT_USER = "FETCH_CURRENT_USER";
export const FETCH_CURRENT_USER_SUCCESS = "FETCH_CURRENT_USER_SUCCESS";
export const FETCH_CURRENT_USER_FAILED = "FETCH_CURRENT_USER_FAILED";
export const FETCH_CURRENT_USER_CANCELLED = "FETCH_CURRENT_USER_CANCELLED";

export const FETCH_PERMISSIONS = "FETCH_PERMISSIONS";
export const FETCH_PERMISSIONS_SUCCESS = "FETCH_PERMISSIONS_SUCCESS";
export const FETCH_PERMISSIONS_FAILED = "FETCH_PERMISSIONS_FAILED";
export const FETCH_PERMISSIONS_CANCELLED = "FETCH_PERMISSIONS_CANCELLED";

export const LOGOUT = "LOGOUT";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILED = "LOGOUT_FAILED";

export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILED = "UPDATE_USER_FAILED";
export const UPDATE_USER_CANCELLED = "UPDATE_USER_CANCELLED";

export const UPDATE_SHOWED_GUIDE = "UPDATE_SHOWED_GUIDE";
export const UPDATE_SHOWED_GUIDE_SUCCESS = "UPDATE_SHOWED_GUIDE_SUCCESS";
export const UPDATE_SHOWED_GUIDE_FAILED = "UPDATE_SHOWED_GUIDE_FAILED";
export const UPDATE_SHOWED_GUIDE_CANCELLED = "UPDATE_SHOWED_GUIDE_CANCELLED";

/**
 * Action updateUser
 * @param body 
 * @param callbacks 
 */
export const updateUser = (body: any, callbacks?: ReduxCallbacks): Action => ({
  type: UPDATE_USER,
  payload: { body, callbacks },
});

/**
 * Action updateUserSuccess
 */
export const updateUserSuccess = (): Action => ({ type: UPDATE_USER_SUCCESS });

/**
 * Action update user failed
 * @param error 
 */
export const updateUserFailed = (error: any): Action => ({
  type: UPDATE_USER_FAILED,
  payload: error,
});

/**
 * Action cancel update user 
 */
export const updateUserCancelled = (): Action => ({
  type: UPDATE_USER_CANCELLED,
});

/**
 * Action login by email
 * @param user 
 * @param callbacks 
 */
export const login = (user: LoginUser, callbacks?: ReduxCallbacks): Action => ({
  type: LOGIN,
  payload: { user, callbacks },
});

/**
 * Action login success
 */
export const loginSuccess = ({
  token,
  user_type,
}: {
  token: string;
  user_type?: string;
}): Action => ({
  type: LOGIN_SUCCESS,
  payload: { token, user_type },
});

/**
 * Action login failed
 * @param error 
 */
export const loginFailed = (error: any): Action => ({
  type: LOGIN_FAILED,
  payload: error,
});

/**
 * Action cancel login 
 */
export const loginCancelled = (): Action => ({
  type: LOGIN_CANCELLED,
});

/**
 * Action login by google
 * @param params 
 * @param callbacks 
 */
export const loginByGoogle = (params: LoginByGoogle, callbacks?: ReduxCallbacks): Action => ({
  type: LOGIN_GOOGLE,
  payload: { params, callbacks },
});

/**
 * Action login by google success
 */
export const loginByGoogleSuccess = ({ token, expires_at, refresh_token }: { token: string, expires_at: string, refresh_token: string }): Action => ({
  type: LOGIN_GOOGLE_SUCCESS,
  payload: { token, expires_at, refresh_token },
});

/**
 * Action login by google failed
 */
export const loginByGoogleFailed = (error: any): Action => ({
  type: LOGIN_GOOGLE_FAILED,
  payload: error,
});

/**
 * Action cancel login by google
 */
export const loginByGoogleCancelled = (): Action => ({
  type: LOGIN_GOOGLE_CANCELLED,
});

/**
 * Action refresh token
 * @param refresh_token 
 */
export const refreshToken = (refresh_token: string): Action => ({
  type: REFRESH_TOKEN,
  payload: { refresh_token }
});

/**
 * Action refresh token success
 */
export const refreshTokenSuccess = ({ token, expires_at } : { token: string, expires_at: string }): Action => ({
  type: REFRESH_TOKEN_SUCCESS,
  payload: { token, expires_at },
});

/**
 * Action refresh token failed
 * @param error 
 */
export const refreshTokenFailed = (error: any): Action => ({
  type: REFRESH_TOKEN_FAILED,
  payload: error,
});

/**
 * Action cancel refresh token
 */
export const refreshTokenCancelled = (): Action => ({
  type: REFRESH_TOKEN_CANCELLED,
});

/**
 * Action fetch data current user
 */
export const fetchUser = (callbacks?: ReduxCallbacks): Action => ({
  type: FETCH_CURRENT_USER,
  payload: {callbacks}
});

/**
 * Action fetch data user success
 * @param user 
 */
export const fetchUserSuccess = (user: User): Action => ({
  type: FETCH_CURRENT_USER_SUCCESS,
  payload: user,
});

/**
 * Action fetch data user failed
 * @param error 
 */
export const fetchUserFailed = (error: any): Action => ({
  type: FETCH_CURRENT_USER_FAILED,
  payload: error,
});

/**
 * Action cancel fetch data user
 */
export const fetchUserCancelled = (): Action => ({
  type: FETCH_CURRENT_USER_CANCELLED,
});

/**
 * Action fetch permissions
 * @param unitId 
 */
export const fetchPermissions = (unitId?: number): Action => ({
  type: FETCH_PERMISSIONS,
  payload: { unitId },
});

/**
 * Action fetch permission success
 * @param permissions 
 */
export const fetchPermissionsSuccess = (permissions: Permission[]): Action => ({
  type: FETCH_PERMISSIONS_SUCCESS,
  payload: { permissions },
});

/**
 * Action fetch permission failed
 * @param error 
 */
export const fetchPermissionsFailed = (error: any): Action => ({
  type: FETCH_PERMISSIONS_FAILED,
  payload: error,
});

/**
 * Action cancel fetch permission
 */
export const fetchPermissionsCancelled = (): Action => ({
  type: FETCH_PERMISSIONS_CANCELLED,
});

/**
 * Action logout user
 * @param callbacks 
 */
export const logout = (callbacks?: ReduxCallbacks): Action => ({
  type: LOGOUT,
  payload: { callbacks },
});

/**
 * Action logout user success
 */
export const logoutSuccess = (): Action => ({
  type: LOGOUT_SUCCESS,
});

/**
 * Action logout user failed
 */
export const logoutFailed = (): Action => ({
  type: LOGOUT_FAILED,
});

/**
 * Action update showed guide
 */
export const updateShowedGuide = ( callbacks?: ReduxCallbacks): Action => {
  return {
    type: UPDATE_SHOWED_GUIDE,
    payload : {callbacks}
  }
};

/**
 * Action update showed guide success
 */
export const updateShowedGuideSuccess = (): Action => ({ type: UPDATE_SHOWED_GUIDE_SUCCESS });

/**
 * Action update showed guide failed
 * @param error 
 */
export const updateShowedGuideFailed = (error: any): Action => ({
  type: UPDATE_SHOWED_GUIDE_FAILED,
  payload: error,
});

/**
 * Action cancel update showed guide
 */
export const updateShowedGuideCancelled = (): Action => ({
  type: UPDATE_SHOWED_GUIDE_CANCELLED,
});