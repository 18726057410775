import { BigButton, CancelButton } from 'app/components/Common/Buttons/Buttons';
import { toggleModal } from 'app/redux/modules/popup';
import React, { FunctionComponent } from 'react';
import { useDispatch } from 'react-redux';
import './style.scss';

interface Props {
	description: string;
	question: string;
	labelBtn: string;
	classNameBtn?: string;
	bodyClass?: string;
	onClose: () => void;
	onConfirm: () => void;
}

/**
 * Component ConfirmModal
 */
const ConfirmModal: FunctionComponent<Props> = ({
	description,
	question,
	labelBtn,
	classNameBtn,
	bodyClass,
	onClose,
	onConfirm,
}) => {
	const dispatch = useDispatch()
	return (
		<>
			<div className={`modal__body ${bodyClass}`}>
				<div className="form-list__confirm-body">
					<p>{description}</p>
					<p className="mb-0 question">{question}</p>
				</div>
			</div>
			<div className="footer">
				<BigButton text={labelBtn} className={`m-0 ${classNameBtn}`} onClick={() => {
					onConfirm()
					dispatch(toggleModal(false))
				}} />
				<CancelButton onClick={() => {
					dispatch(toggleModal(false))
					onClose()
				}} />
			</div>
		</>
	);

}
export default ConfirmModal;
