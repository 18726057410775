import { combineReducers } from "redux";
import { combineEpics } from "redux-observable";
import { deactivateUserEpic, getPaymentPlansEpic, getReportEpic, loginAdminEpic, loginAdminReducer, updateInvoiceEpic, updatePaymentMemoEpic } from "./admin";
import {
  authReducer,
  authRefreshReducer,

  fetchPermissionsEpic,

  fetchUserEpic,
  loginByGoogleEpic, loginEpic,
  logoutEpic,
  onFetchUserSuccessEpic,

  refreshTokenEpic, updateShowedGuideEpic,

  updateUserEpic
} from "./auth";
import { deleteBookingTimelineListEpic, fetchBookingTimelineDetailEpic, fetchBookingTimelineListEpic, getBookingTimelineDetailReducer, getBookingTimelineListReducer } from './bookingTimeline';
import { fetchCalendarFormByIdEpic, fetchCreateBookingCalendarEpic, getBookingCalendarReducer } from "./calendar";
import {
  addFormEpic,

  deleteFormEpic, editFormBookingEpic,

  fetchDefaultFormEpic,
  fetchDetailFormEpic, fetchFormListEpic,

  fetchListCalendar, fetchTagsListFormEpic, getFormInitEpic, getFormInitReducer, getFormListReducer,

  updateFormDefaultEpic
} from "./forms";
import { loadingReducer } from "./loading";
import { popupReducer } from "./popup";
import {
  checkIsUseTagEpic, createTagEpic, deleteTagEpic,
  editTagDetailEpic, fetchTagColorsEpic,
  fetchTagDetailEpic, fetchTagsListEpic,

  getTagDetailReducer, getTagsListReducer,

  tagColorsReducer
} from './tags';
import {
  feachUserContractEpic,
  cancelUserContractEpic,
  userContractReducer
} from "./contracts"
import {
  warningRedeucer
} from "./warning"
import {
  feachPaymentInformationEpic,
  mergeUserPaymentEpic,
  createNewBankPaymentTransferEpic,
  updateOnlyPaymentPlanEpic,
  updatePaymentWithCreditCardEpic,
  changePaymentToBankEpic,
  paymentPlanIdReducer
} from "./regist";

import {
  registCreditButtonReducer
} from "./registCreditButton"

/**
 * Root Epic
 */
export const rootEpic: any = combineEpics(
  loginEpic,
  logoutEpic,
  fetchUserEpic,
  onFetchUserSuccessEpic,
  fetchPermissionsEpic,
  updateShowedGuideEpic,
  loginByGoogleEpic,
  fetchFormListEpic,
  refreshTokenEpic,
  addFormEpic,
  editFormBookingEpic,
  updateFormDefaultEpic,
  getFormInitEpic,
  fetchTagsListFormEpic,
  fetchTagsListEpic,
  fetchTagColorsEpic,
  fetchTagDetailEpic,
  createTagEpic,
  editTagDetailEpic,
  deleteTagEpic,
  checkIsUseTagEpic,
  fetchBookingTimelineListEpic,
  fetchBookingTimelineDetailEpic,
  deleteBookingTimelineListEpic,
  fetchCalendarFormByIdEpic,
  fetchCreateBookingCalendarEpic,
  updateUserEpic,
  fetchDefaultFormEpic,
  fetchDetailFormEpic,
  fetchListCalendar,
  deleteFormEpic,
  loginAdminEpic,
  getReportEpic,
  getPaymentPlansEpic,
  updatePaymentMemoEpic,
  deactivateUserEpic,
  updateInvoiceEpic,
  feachUserContractEpic,
  cancelUserContractEpic,
  feachPaymentInformationEpic,
  mergeUserPaymentEpic,
  createNewBankPaymentTransferEpic,
  updateOnlyPaymentPlanEpic,
  updatePaymentWithCreditCardEpic,
  changePaymentToBankEpic
);

export const rootReducer = combineReducers({
  loading: loadingReducer,
  auth: authReducer,
  authRefresh: authRefreshReducer,
  bookingCalendar: getBookingCalendarReducer,
  bookingTimelineList: getBookingTimelineListReducer,
  bookingTimelineDetail: getBookingTimelineDetailReducer,
  bookingList: getFormListReducer,
  bookingInit: getFormInitReducer,
  tagsList: getTagsListReducer,
  tagDetail: getTagDetailReducer,
  tagColors: tagColorsReducer,
  modal: popupReducer,
  loginAdmin: loginAdminReducer,
  contractInfo: userContractReducer,
  warning: warningRedeucer,
  paymentPlanInformation: paymentPlanIdReducer,
  registCreditButton: registCreditButtonReducer
});

export type RootStoreType = ReturnType<typeof rootReducer>;
