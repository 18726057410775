/**
 * Convert to absolute url
 * @param pathname 
 */
export const toAbsoluteUrl = (pathname: string) =>
	process.env.PUBLIC_URL + pathname;

/**
 * Get route with param
 * @param path 
 * @param data 
 */
export const getRoutePathWithParam = (
	path: string,
	data?: { [key: string]: string }
) => {
	let result = path;
	for (const k in data) {
		result = result.replace(`:${k}`, data[k]);
	}
	return result;
};

/**
 * Get current url
 * @param location 
 */
export function getCurrentUrl(location: any) {
	return location.pathname.split(/[?#]/)[0];
}

/**
 * Check is active
 * @param location 
 * @param url 
 */
export function checkIsActive(location: any, url: string) {
	const current = getCurrentUrl(location);
	if (!current || !url) {
		return false;
	}
	if (current === url) {
		return true;
	}
	if (current.indexOf(url) > -1) {
		return true;
	}
	return false;
}
