import React from 'react';
import ReactDOM from 'react-dom';
import './styles/Desktop.scss';
import App from './app/App';
import * as serviceWorker from './serviceWorker';
import smoothscroll from 'smoothscroll-polyfill';

import "react-toastify/dist/ReactToastify.css";
smoothscroll.polyfill();

ReactDOM.render(
  <React.Fragment>
    <App />
  </React.Fragment>,
  document.getElementById('root')
);

/* If you want your app to work offline and load faster, you can change
 unregister() to register() below. Note this comes with some pitfalls.
 Learn more about service workers: https://bit.ly/CRA-PWA */
serviceWorker.unregister();
