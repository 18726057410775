import { Action } from 'app/models/redux/action';
import { ListState } from 'app/models/redux/listState';
import {
	SET_BOOKING_CALENDAR,
	CLEAR_BOOKING_CALENDAR,
	GET_BOOKING_CALENDAR_BY_ID,
	GET_BOOKING_CALENDAR_BY_ID_SUCCESS,
	GET_BOOKING_CALENDAR_BY_ID_FAILED,
	SET_BOOKING_CALENDAR_COMPLETE,
	CLEAR_BOOKING_CALENDAR_COMPLETE
} from './actions';
import {
	baseBookingCalendar,
	clearBookingCalendar,
	clearBookingCalendarComplete,
	fetchDetailState,
	fetchDetailStateFailed,
	fetchDetailStateSuccess,
	setBookingCalendar,
	setBookingCalendarComplete
} from 'app/helpers/reduxAction';

const bookingCalendar: any = {
	...baseBookingCalendar(),
}

/**
 * Set booking calendar
 * @param state 
 * @param action 
 */
export const getBookingCalendarReducer = (state: ListState<any> = bookingCalendar, action: Action) => {
	switch (action.type) {
		case GET_BOOKING_CALENDAR_BY_ID:
			return fetchDetailState(action, state);
		case GET_BOOKING_CALENDAR_BY_ID_SUCCESS:
			return fetchDetailStateSuccess(action, state);
		case GET_BOOKING_CALENDAR_BY_ID_FAILED:
			return fetchDetailStateFailed(action, state);
		case SET_BOOKING_CALENDAR_COMPLETE:
			return setBookingCalendarComplete(action, state);
		case CLEAR_BOOKING_CALENDAR_COMPLETE:
			return clearBookingCalendarComplete(action, state);
		case SET_BOOKING_CALENDAR:
			return setBookingCalendar(action, state);
		case CLEAR_BOOKING_CALENDAR:
			return clearBookingCalendar(action, state);
		default:
			return state;
	}
};
