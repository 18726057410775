import { ButtonWithEffect } from 'app/components/Common/Buttons/Buttons';
import { Messages, NameMethodResponseApis, RoutePaths, TitleOrButton } from 'app/constants';
import { customNotifySuccess, notifyError } from 'app/helpers';
import env from 'app/helpers/env';
import LoadingManager from 'app/helpers/loadingManager';
import useAddEffectButton from 'app/hooks/useAddEffectButton';
import { LoginByGoogle } from 'app/models/LoginByGoogle';
import { ReduxCallbacks } from 'app/models/redux/Callbacks';
import { ListState } from 'app/models/redux/listState';
import { loginByGoogle } from 'app/redux/modules/auth';
import { RootStoreType } from 'app/redux/modules/root';
import React, { useRef, useState } from 'react';
import GoogleLogin from 'react-google-login';
import { connect } from 'react-redux';
import './Login.scss';

interface Props {
	loginByGoogle: (params: LoginByGoogle, callbacks?: ReduxCallbacks) => void;
	authReducer: ListState<any>;
}

const mapStateToProps = (state: RootStoreType) => {
	return {
		authReducer: state.auth,
	};
};

const actions = {
	loginByGoogle,
};

/**
 * Component show Login Screen
 */
const Login: React.FC<Props> = (props) => {
	const { loginByGoogle, authReducer } = props,
		[loading, setLoading] = useState<boolean>(false);
	const refButton = useRef(null);
	useAddEffectButton(refButton);
	const timeOut = 3000;
	var messageNotify = "";
	const responseGoogle = (response: any) => {

		console.log(response)
		if (response?.code !== null && response?.code !== '' && response?.code !== undefined) {
			let params: LoginByGoogle = {
				code: response?.code,
			};
			setLoading(true);
			try {
				loginByGoogle(params, {
					onSuccess: () => {
						setLoading(false);
						customNotifySuccess(Messages.Auth.SUCCESS, 1000, RoutePaths.Dashboard.Index);
					},
					onFailed: (error) => {
						if (error.response) {
							switch (error.response.error.name) {
								case NameMethodResponseApis.Authentication.GOOGLE_AUTHENTICATION_FAILED:
									messageNotify = Messages.Auth.Failed.GOOGLE_AUTHENTICATION_FAILED;
									break;
								case NameMethodResponseApis.Authentication.GOOGLE_AUTHENTICATION_NOT_G_SUITE_ACCOUNT:
									messageNotify = Messages.Auth.Failed.GOOGLE_AUTHENTICATION_NOT_G_SUITE_ACCOUNT;
									break;

								default:
									messageNotify = error?.response?.message || Messages.Auth.Failed.MAIN;
									break;
							}

							console.log(messageNotify)
							notifyError(messageNotify, { autoClose: timeOut });
						}
						setLoading(false);
					},
				});
			} catch (error) {
				setLoading(false);
				console.log(messageNotify)
				notifyError(messageNotify, { autoClose: timeOut });
			}
		} else {
			try {
				switch (response?.error) {
					case NameMethodResponseApis.GoogleLogin.IDPIFRAME_INITIALIZATION_FAILED:
						messageNotify = Messages.GoogleLogin.IDPIFRAME_INITIALIZATION_FAILED;
						break;
					case NameMethodResponseApis.GoogleLogin.POPUP_CLOSED_BY_USER:
						messageNotify = Messages.GoogleLogin.POPUP_CLOSED_BY_USER;
						break;

					case NameMethodResponseApis.GoogleLogin.ACCESS_DENIED:
						messageNotify = Messages.GoogleLogin.ACCESS_DENIED;
						break;

					case NameMethodResponseApis.GoogleLogin.IMMEDIATE_FAILED:
						messageNotify = Messages.GoogleLogin.IMMEDIATE_FAILED;
						break;

					default:
						messageNotify = Messages.GoogleLogin.GOOGLE_LOGIN_FAILED;
						break;
				}
				notifyError(messageNotify, { autoClose: timeOut });
			} catch (error) {
				notifyError(Messages.GoogleLogin.GOOGLE_LOGIN_FAILED, { autoClose: timeOut });
			}
		}
	};
	LoadingManager.setLoading(loading);
	return (
		<div className="login-form login-signin w-100" id="kt_login_signin_form">
			<div className="ass1-login__content">
				<div className="ass1-login__content__title">
					<span>{TitleOrButton.Title.LOGIN_OPTIONS}</span>
				</div>
				<div className="ass1-login__content__google--button--container">
					<ButtonWithEffect className="google-effect">
						<GoogleLogin
							clientId={env.GOOGLE_API}
							buttonText={TitleOrButton.Button.GOOGLE}
							onSuccess={responseGoogle}
							onFailure={responseGoogle}
							cookiePolicy={'single_host_origin'}
							className="google-login ass1-login__content__google--button"
							autoLoad={authReducer.loading}
							responseType="code"
							prompt="consent"
							accessType="offline"
							scope="email profile openid https://www.googleapis.com/auth/calendar"
						/>
					</ButtonWithEffect>
				</div>
				{/* <div className="ass1-login__content__require">
					<span>{TitleOrButton.Title.LOGIN_REQUIRE}</span>
				</div> */}
			</div>
		</div>
	);
};

export default connect(mapStateToProps, actions)(Login);
