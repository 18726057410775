import { Api } from "../api";
import { ListQuery } from "app/models/redux/ListQuery";
import { EndPointApis } from "app/constants";
import { BookingCreate } from "app/redux/modules/forms";

/**
 * Service Form
 */
export class BookingsFormService {
  /**
   * Get form listing
   * @param query
   */
  static getFormList(query: ListQuery) {
    return Api.getNoQuery(
      `${EndPointApis.Form.LIST}${query.page}&per_page=${query.per_page}`
    );
  }

  /**
   * Disable or enable form
   * @param id
   */
  static toggleForm(id: number) {
    return Api.put(`${EndPointApis.Form.INDEX}/${id}/toggle_form`);
  }

  /**
   * Load form init
   */
  static loadFormInit() {
    return Api.getNoQuery(`${EndPointApis.Form.INIT}`);
  }

  /**
   * Create form
   * @param params
   */
  static createFormRequest(params: BookingCreate) {
    return Api.post(`${EndPointApis.Form.INDEX}`, { ...params });
  }

  /**
   * Edit form booking
   * @param id
   * @param params
   */
  static editFormRequest(id:any, params: BookingCreate) {
    return Api.put(`${EndPointApis.Form.INDEX}/${id}`, { ...params });
  }

  /**
   * Get calendar by account login
   * @param query
   * @param next_page_token
   */
  static getCalendarsFormRequest(query: ListQuery, next_page_token: any) {
    return Api.getNoQuery(
      `${EndPointApis.Form.CALENDARS}?next_page_token=${next_page_token}`
    );
  }

  /**
   * Get list tag from request
   */
  static getTagsFormRequest() {
    return Api.getNoQuery(`${EndPointApis.Form.TAGS}`);
  }

  /**
   * Get information of form default
   */
  static getDefaultForm() {
    return Api.getNoQuery(`${EndPointApis.Form.DEFAULT_FORM}`)
  }

  /**
   * Get detail form
   * @param id
   */
  static getDetailForm(id: string) {
    return Api.getNoQuery(`${EndPointApis.Form.INDEX}/${id}`)
  }

  /**
   * Update form default
   * @param params
   */
  static updateFormDefault(params: BookingCreate) {
    return Api.put(`${EndPointApis.Form.UPDATE_DEFAULT_FORM}`, { ...params });
  }

    /**
   * Delete form by id
   * @param id
   */
  static deleteForm(id : string) {
    return Api.delete(`${EndPointApis.Form.DELETE_FORM}/${id}`);
  }



}
