import { ActionsObservable, ofType } from "redux-observable";
import { map, catchError, takeUntil, mergeMap } from "rxjs/operators";
import { of } from "rxjs";
import { Action } from "app/models/redux/action";
import { onEpicSuccess, onEpicFailed } from "app/helpers/reduxEpic";
import { CANCEL_ALL_REQUEST } from "app/redux/default";
import { BookingService } from "app/services/booking";
import * as Actions from "./actions";

/**
 * Watch action type and request to delete booking
 * @param action$ 
 */
export const deleteBookingTimelineListEpic = (action$: ActionsObservable<Action>) =>
  action$.pipe(
    ofType(Actions.DELETE_BOOKING_DETAIL),
    mergeMap((action) =>
      BookingService.deleteBooking(action.payload.id).pipe(
        map((ajaxResponse) => {
          const { response } = ajaxResponse;
          if (response) {
            return onEpicSuccess(
              action,
              ajaxResponse,
              Actions.deleteBookingTimelineDetailSuccess(response)
            );
          }
        }),
        catchError((error) => {
          return of(
            onEpicFailed(action, error, Actions.deleteBookingTimelineDetailFailed(error))
          );
        }),
        takeUntil(
          action$.pipe(
            ofType(Actions.DELETE_BOOKING_DETAIL_CANCELLED, CANCEL_ALL_REQUEST)
          )
        )
      )
    )
  );

/**
 * Watch action type and request to fetch list booking
 * @param action$ 
 */
export const fetchBookingTimelineListEpic = (action$: ActionsObservable<Action>) =>
  action$.pipe(
    ofType(Actions.GET_BOOKING_LIST),
    mergeMap((action) =>
      BookingService.getBookingList(action.payload.query).pipe(
        map((ajaxResponse) => {
          const { response } = ajaxResponse;
          const data = response.data?.records as any[];
          const result = data;
          if (result) {
            return onEpicSuccess(
              action,
              ajaxResponse,
              Actions.getBookingTimelineListSuccess(result)
            );
          }
        }),
        catchError((error) => {
          return of(
            onEpicFailed(action, error, Actions.getBookingTimelineListFailed(error))
          );
        }),
        takeUntil(
          action$.pipe(
            ofType(Actions.GET_BOOKING_LIST_CANCELLED, CANCEL_ALL_REQUEST)
          )
        )
      )
    )
  );

/**
 * Watch action type and request to fetch detail booking
 * @param action$ 
 */
export const fetchBookingTimelineDetailEpic = (action$: ActionsObservable<Action>) =>
  action$.pipe(
    ofType(Actions.GET_BOOKING_DETAIL),
    mergeMap((action) =>
      BookingService.getBookingDetail(action.payload).pipe(
        map((ajaxResponse) => {
          const { response } = ajaxResponse;
          const { data } = response;
          if (data) {
            return onEpicSuccess(
              action,
              ajaxResponse,
              Actions.getBookingTimelineDetailSuccess(data)
            );
          }
        }),
        catchError((error) => {
          return of(
            onEpicFailed(action, error, Actions.getBookingTimelineDetailFailed(error))
          );
        }),
        takeUntil(
          action$.pipe(
            ofType(Actions.GET_BOOKING_DETAIL_CANCELLED, CANCEL_ALL_REQUEST)
          )
        )
      )
    )
  );