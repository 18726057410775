// Constant routes paths
export const RoutePaths = {
	Auth: {
		Index: '/auth',
		Login: '/auth/login',
		ChooseUnit: '/choose-unit',
	},
	Dashboard: {
		Index: '/',
	},
	BookingForm: {
		Index: '/form',
		Create: '/form/create',
		Detail: '/form/detail',
		DetailTemp: '/form/detailTemp',
		Copy: '/form/copy',
		Edit: '/form/edit',
		CopyUrl: '/form/finish'
	},
	BookingFormClone: {
		Index: '/formClone',
		Create: '/formClone/create',
		Detail: '/formClone/edit',
	},
	Booking: {
		Index: '/booking',
		Create: '/booking/create',
		Calendar: '/booking/calendar',
		Edit: '/booking/create',
		Complete: '/booking/complete',
		CopyLink: '/booking/copy',
	},
	Tag: {
		Index: '/tags',
		Create: '/tags/create',
		Detail: '/tags/detail',
	},
	Setting: {
		Index: '/setting',
		Profile: '/setting/profile',
		Account: '/setting/account',
		DefaultForm: '/setting/defaultform',
		Tutorial: '/setting/tutorial',
		Logout: '/setting/logout',
		Contract: '/setting/contract',
		Cancel: '/setting/cancel'
	},
	Admin: {
		Index: '/admin',
		LoginAdmin: '/admin/login',
		Report: '/admin/report',
	},
	Redirect: {
		Index: "/redirect_handle"
	},
	Pay: {
		Index: '/pay'
	},
	NotFound: '/404',
	AccessDenied: '/403',
	RegistPlan: {
		Plan: '/registPlan/plan',
		Payment: '/registPlan/payment',
		Finish: '/registPlan/finish'
	},
	EditPlan: {
		Payment: '/editPlan/payment',
		MethodOnlyPayment: '/editPlan/method_onlypayment',
		MethodOnlyFinish: '/editPlan/method_onlyfinish'
	},
};
