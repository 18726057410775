import { Api } from "../api";
import store from "../redux";
import { LoginUser } from "app/models/LoginUser";
import { LoginByGoogle } from "app/models/LoginByGoogle";
import { EndPointApis } from 'app/constants';

/**
 * Service Auth
 */
export class Auth {

	/**
	 * Login
	 * @param user 
	 */
	static login(user: LoginUser) {
		return Api.post(EndPointApis.Auth.EMAIL, user);
	}

	/**
	 * Login by google
	 * @param params 
	 */
	static loginByGoogle(params: LoginByGoogle) {
		return Api.post(`${EndPointApis.Auth.GOOGLE}${params.code}`);
	}

	/**
	 * Refresh token
	 * @param refresh_token 
	 */
	static refreshToken(refresh_token: string) {
		return Api.put(`${EndPointApis.Auth.REFRESH}${refresh_token}`)
	}

	/**
	 * Fetch user
	 */
	static fetchUser() {
		return Api.getNoQuery(`${EndPointApis.Auth.USER}`);
	}

	/**
	 * Fetch permission
	 * @param unitId 
	 */
	static fetchPermissions(unitId?: number) {
		return Api.get("", {
			unitId: Auth.isSystemUser() ? unitId : undefined,
		});
	}

	/**
	 * Check has permission
	 * @param permission 
	 */
	static hasPermission(permission: string) {
		return true;
	}

	/**
	 * Check is logged in
	 */
	static async isLoggedIn() {
		return true;
	}

	/**
	 * Check is system user
	 */
	static isSystemUser() {
		const state = store.getState();
		return state.auth.user_type === "system";
	}

	/**
	 * Get Profile
	 */
	static getProfile() {
		return Api.get("");
	}

	/**
	 * Update Profile
	 * @param body 
	 */
	static updateProfile(body: any) {
		return Api.put(EndPointApis.Auth.USER, body);
	}

	/**
	 * Change show guide
	 */
	static updateShowedGuide () {
		return Api.put(EndPointApis.Guide.INDEX)
	}
}
