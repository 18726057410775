// Constant name of method when call api.
export const NameMethodResponseApis = {
    Authentication: {
        GOOGLE_AUTHENTICATION_FAILED: "google_authentication_failed",
        GOOGLE_AUTHENTICATION_NOT_G_SUITE_ACCOUNT: "not_g_suite_account"
    },
    GoogleLogin: {
        IDPIFRAME_INITIALIZATION_FAILED : "idpiframe_initialization_failed",
        POPUP_CLOSED_BY_USER: "popup_closed_by_user",
        ACCESS_DENIED: "access_denied",
        IMMEDIATE_FAILED: "immediate_failed"
    }
};
