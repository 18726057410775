import { Action } from "../../../models/redux/action";

export const OPEN_POPUP = "OPEN_POPUP";
export const CLOSE_POPUP = "CLOSE_POPUP";

/**
 * Action Toggle Modal
 * @param isOpen 
 */
export const toggleModal = (isOpen: boolean): Action => {
	if(isOpen){
		return {
			type: OPEN_POPUP
		}
	}
	return {
		type: CLOSE_POPUP
	}
};
