export { TitleOrButton } from "./titleOrButton";
export { Messages } from "./messages";
export { RoutePaths } from "./routes";
export { DEFAULT_PAGE_SIZE } from "./pagination";
export { EndPointApis } from "./enpointApi";
export { CHARACTERS } from "./character";
export { NameMethodResponseApis } from "./nameMethodResponseApis";

// Start time, end time
export const TypeTime = {
  START_TIME: 'startTime',
  END_TIME: 'endTime',
}
