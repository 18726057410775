export const CANCEL_ALL_REQUEST = "CANCEL_ALL_REQUEST";

/**
 * Action cancel all request
 */
export const cancelAllRequest = () => ({
  type: CANCEL_ALL_REQUEST,
});

/**
 * Action cancel request
 * @param type 
 */
export const cancelRequest = (type: string) => ({
  type
});
