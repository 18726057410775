import { createStore, applyMiddleware } from 'redux';
import { createEpicMiddleware, Epic } from 'redux-observable';
import { rootEpic, rootReducer, RootStoreType } from './modules/root';
import { persistStore, persistReducer } from 'redux-persist';

import storage from 'redux-persist/lib/storage';

export type MyEpic = Epic<any, any, RootStoreType, any>;
const epicMiddleware = createEpicMiddleware<any, any, RootStoreType, any>();

const persistConfig = {
	key: 'root',
	storage,
	whitelist: ['auth', 'loginAdmin'],
};

let middleware: any = [epicMiddleware];
const persistedReducer = persistReducer(persistConfig, rootReducer);

export default function configureStore() {
	if (process.env.NODE_ENV !== 'production') {
		middleware = [...middleware];
	}
	const store = createStore(persistedReducer, applyMiddleware(...middleware));
	const persistor = persistStore(store);

	epicMiddleware.run(rootEpic);

	return { store, persistor };
}
