import { useEffect } from 'react';
import { useHistory, withRouter } from 'react-router-dom';

/**
 * Component ScrollToTop
 */
function ScrollToTop() {
	const history = useHistory();

	useEffect(() => {

		/**
		 * Scroll to (0, 0)
		 */
		const unlisten = history.listen(() => {
			window.scrollTo(0, 0);
		});
		return () => {
			unlisten();
		}
	}, []);

	return (null);
}

export default withRouter(ScrollToTop);