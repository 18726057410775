import { OPEN_POPUP, CLOSE_POPUP } from "./actions";
import { Action } from "../../../models/redux/action";

/**
 * Popup Reducer
 * @param state 
 * @param action 
 */
export const popupReducer = (
	state: any = { openModal: false },
	action: Action
) => {
	switch (action.type) {
		case OPEN_POPUP:
			return {
				openModal: true
			};
		case CLOSE_POPUP:
			return {
				openModal: false,
			};

		default:
			return state;
	}
};
