import ConfirmModal from 'app/components/Common/ConfirmModal';
import CustomMenuLink from 'app/components/Common/MenuLink/CustomMenuLink';
import Popup from 'app/components/Common/Popup';
import { RoutePaths, TitleOrButton } from 'app/constants';
import { Menus } from 'app/constants/menu';
import { ReduxCallbacks } from 'app/models/redux/Callbacks';
import { User } from 'app/models/User';
import { logout } from 'app/redux/modules/auth';
import { RootStoreType } from 'app/redux/modules/root';
import { Images } from 'assets/iconListing';
import React, { useCallback, useState } from 'react';
import { DropdownButton } from 'react-bootstrap';
import DropdownItem from 'react-bootstrap/esm/DropdownItem';
import { connect } from 'react-redux';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { path } from './../../../constants/checkPath';
import './style.scss';
import env from 'app/helpers/env';


const { TUTORIAL_PAGE } = env

interface Props {
	user: User;
	logout: (callbacks?: ReduxCallbacks) => void;
}

const mapStateToProps = (state: RootStoreType) => {
	return {
		user: state.auth.user,
	};
};

const actions = {
	logout,
};

/**
 * Component Header
 */
export const Header = connect(
	mapStateToProps,
	actions
)(({ logout, user }: Props) => {
	const [isOpenPopup, setOpenPopup] = useState(false);
	const location = useLocation();
	const history = useHistory();
	// check header on page has route booking/
	let checkRouter = location.pathname.indexOf(path.isBooking) === -1;
	// check page 404
	let isRouterNotFound = location.pathname.indexOf(path.isNotFourd) !== -1;
	// Check if page contains header in mobile
	let hasHeader = /^\/\w+$/.test(location.pathname);
	// check page tutorial
	let isRouterTutorial = location.pathname.indexOf(path.isTutorial) !== -1;

	/**
	 * Handle Logout
	 */
	const handleLogout = useCallback(() => {
		logout();
		history.push(RoutePaths.Auth.Login)
	}, []);

	/**
	 * Toggle popup
	 */
	const togglePopup = useCallback(() => {
		setOpenPopup(!isOpenPopup);
	}, [isOpenPopup]);

	/**
	 * Change to Profile Page
	 */
	const changeProfilePage = () => history.push(RoutePaths.Setting.Account);

	/**
	 * Change to Form Default Page
	 */
	const changeFormDefault = () => history.push(RoutePaths.Setting.DefaultForm);

	/**
	 * Change to Contract Infomation Page
	 */
	const changeContractInfomation = () => history.push(RoutePaths.Setting.Contract);

	/**
	 * handle open window tutorial
	 */

	 const openWindowTutorial = () => {
		let windowObjectReference = window.open(
			TUTORIAL_PAGE,
			"DescriptiveWindowName",
			"outerWidth,outerHeight,toolbar=no,menubar=no,scrollbars,status,screenX,screenY"
		  );

		windowObjectReference?.focus();
	 }

	/**
	 * Create Menu
	 */
	const createMenus = Menus.filter((item) => {
		return item.ForScreen === 1;
	}).map((item, index) => (
		<li className="header__navbar--item nav-item" key={index}>
			<CustomMenuLink
				to={item.Router}
				label={item.Name}
				activeOnlyWhenExact={item.ActiveOnlyWhenExact}
				activeIcon={item.IconActive}
				baseIcon={item.Icon}
			/>
		</li>
	));

	return (
		<header
			className={`header box-shadow ${!checkRouter ? 'header--green' : ''} ${ isRouterTutorial ? "" : !hasHeader && checkRouter ? 'header--hide-mobile' : ''
				}`}
		>
			<Popup header={TitleOrButton.TopBar.SIGNOUT} isActive={isOpenPopup} onClose={togglePopup}>
				<ConfirmModal
					description=""
					question={TitleOrButton.Question.CONFIRM_SIGN_OUT}
					labelBtn={TitleOrButton.Button.YES}
					classNameBtn="btn-red"
					onClose={togglePopup}
					onConfirm={handleLogout}
				/>
			</Popup>

			<NavLink to="/" exact className="no-pointer" >
				{!checkRouter ? (
					<img alt={TitleOrButton.Description.LOGO_DES} src={Images.LogoWhite} className="logo" />
				) : (
						<img alt={TitleOrButton.Description.LOGO_DES} src={Images.Logo} className="logo" />
					)}
			</NavLink>
			{ checkRouter ? (
				<div className={`header__navbar navbar-nav d-lg-flex ${isRouterNotFound ? 'hidden-menu' : null}`}>
					<ul className="nav">{createMenus}</ul>
					<DropdownButton className="header__navbar--dropdown" title={user?.display_name ?? ''} >
						<p className="dropDowm-item__title">{TitleOrButton.TopBar.PROFILE}</p>
						<DropdownItem className="header__navbar--active-item" onClick={changeProfilePage}>
							<p className="header__navbar--btn" >
								{TitleOrButton.TopBar.ACCOUNT}
								<i className="fas fa-chevron-right col-auto p-0 arrow" />
							</p>
						</DropdownItem>
						<p className="dropDowm-item__title">{TitleOrButton.TopBar.RESERVATION_FORM}</p>
						<DropdownItem className="header__navbar--active-item" onClick={changeFormDefault}>
							<p className="header__navbar--btn" >
								{TitleOrButton.TopBar.SETTING_FORM_DEFAULT}
								<i className="fas fa-chevron-right col-auto p-0 arrow" />
							</p>
						</DropdownItem>
						{/* <DropdownItem className="header__navbar--active-item" onClick={changeContractInfomation}>
							<p className="header__navbar--btn" >
								{TitleOrButton.TopBar.CONTRACT}
								<i className="fas fa-chevron-right col-auto p-0 arrow" />
							</p>
						</DropdownItem> */}
						<DropdownItem className="header__navbar--active-item" onClick={openWindowTutorial}>
							<p className="header__navbar--btn">
							{TitleOrButton.TopBar.TUTORIAL} <i className="fas fa-chevron-right col-auto p-0 arrow" />
							</p>
						</DropdownItem>
						<DropdownItem className="header__navbar--active-item" onClick={togglePopup}>
							<p className="header__navbar--btn">
								{TitleOrButton.TopBar.SIGNOUT} <i className="fas fa-chevron-right col-auto p-0 arrow" />
							</p>
						</DropdownItem>
					</DropdownButton>
				</div>
			) : null}
		</header>
	);
});
