/** List endpoint connect api */
export const EndPointApis = {
  Auth: {
    EMAIL: "/TokenAuth/Authenticate",
    GOOGLE: "v1/public/auth?code=",
    REFRESH: "v1/private/auth/token?refresh_token=",
    USER: "v1/private/me",
  },
  Form: {
    LIST: "v1/private/forms?page=",
    INIT: "v1/private/forms/default_form",
    CALENDARS: "v1/private/forms/google_calendars",
    TAGS: "v1/private/tags",
    DEFAULT_FORM: "v1/private/forms/default_form",
    DELETE_FORM: "v1/private/forms",
    INDEX: "v1/private/forms",
    UPDATE_DEFAULT_FORM: "v1/private/default_form"
  },
  Booking: {
    INDEX: "v1/private/bookings",
    GET_TIME_BOOKING: "v1/public/bookings/calendar_adjust",
    FORM: (form_id_encode: string) => `v1/public/forms/${form_id_encode}/bookings`,
  },
  Tag: {
    INDEX: "v1/private/tags",
    COLOR: "v1/private/tag_colors",
    USED: (tag_id: string) => `v1/private/tags/${tag_id}/used`,
  },
  Guide: {
    INDEX: "v1/private/me/showed_guide"
  },
  Admin: {
    LOGIN_ADMIN: "v1/admin/auth",
    GET_REPORT: "v1/admin/users",
    GET_PAYMENT_PLANS: "/v1/admin/payment_plans",
    UPDATE_PAYMENT_MEMO: (idUser: any) => `v1/admin/users/${idUser}/payment/update_note`,
    UPDATE_INVOICE: "v1/admin/payments/invoice_multiple",
    DEACTIVATE_USER: "v1/admin/payments/deactive_multiple",
  },
  Contract: {
    GET_USER_CONTRACT: "v1/private/contract_infomations",
    CANCEL_USER_CONTRACT: "v1/private/contract_infomations"
  },
  Regist: {
    GET_PAYMENT_INFORMATION: "v1/private/payment_informations",
    CREATE_NEW_PAYMENT_CREDIT: "v1/private/payment_informations/new_credit",
    CREATE_NEW_PAYMENT_BANK: "v1/private/payment_informations/new_bank",
    UPDATE_ONLY_PAYMENT_PLAN: "v1/private/payment_informations/only_plan",
    UPDATE_PAYMENT_CREDIT_CARD: "v1/private/payment_informations/change_credit",
    CHANGE_PAYMENT_TO_BANK: "v1/private/payment_informations/change_to_bank"
  }
};
