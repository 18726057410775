import ListingEmpty from './images/listing_empty.png';
import ListingEmpty2 from './images/listing_empty_2.png';
import ArrowLeft from './images/arrow-icon-left.png';
import ArrowLeftColor from './images/arrow-left-color.png';
import ArrowRight from './images/arrow-icon-right.png';
import Calender from './images/calendar.png';
import LogoWhite from './images/logo-icon-white.png';
import Logo from './images/logo.png';
import TopSettingIcon from './images/top-setting-icon.png';
import TopTag from './images/top-tag-icon.png';
import IconTopPopup from './images/icon-top-popup.png';
import Plush from './images/top-create-form-booking-icon.png';
import FreeTime from './images/for-example-free-time.png';
import Appointment from './images/for-example-appointment.png';
import Extend from './images/icon-extend.png';
import Region from './images/icon-region.png';
import Plus from './images/plus.png';
import Close from './images/close.png';
import SelectTimeBookingEmpty from './images/select-time-booking-empty.png';
import ArrowLeftBlack from './images/arrow-left-black.png';
import ArrowRightBlack from './images/arrow-right-black.png';
import End from './images/end.png';
import Start from './images/start.png';
import Step1 from './images/step_1.png';
import Step2 from './images/step_2.png';
import Step3 from './images/step_3.png';
import ogImage from './images/norisuke-nittei_og-image.png'
import VisaCard from './images/visa.png';
import MasterCard from './images/master.png';
import JcbCard from './images/jcb.png';

export const Images = {
	TopTag,
	TopSettingIcon,
	ArrowRight,
	ArrowLeft,
	Calender,
	LogoWhite,
	Logo,
	Plush,
	ListingEmpty,
	IconTopPopup,
	ArrowLeftColor,
	FreeTime,
	Appointment,
	Extend,
	Region,
	ListingEmpty2,
	Plus,
	Close,
	SelectTimeBookingEmpty,
	ArrowLeftBlack,
	ArrowRightBlack,
	End,
	Step1,
	Step2,
	Step3,
	Start,
  ogImage,
  VisaCard,
  MasterCard,
  JcbCard
};
