import useAddEffectButton from 'app/hooks/useAddEffectButton';
import React, { FunctionComponent, useRef } from 'react';
import './button.scss';
import ImageAdd from "../../../../assets/images/add-icon-mobile.png"
import { TitleOrButton } from 'app/constants';

/**
 * Component ButtonWithEffect
 */
export const ButtonWithEffect = (props: any) => {
	const tab = { tabIndex: 0 };
	const refButton = useRef<any>(null);

	/**
	 * Remove focus
	 */
	const removeFocus = () => {
		if (refButton?.current) {
			refButton.current.blur()
		}
	}
	useAddEffectButton(refButton);

	return (
		<button
			{...props}
			ref={refButton}
			{...tab}
			onClick={() => {
				removeFocus()
				if (!!props.onClick) {
					props.onClick();
				}
			}}
		>
			{props.children}
		</button>)
}

interface AddButtonProps {
	text: string;
	icon?: string;
	onClick?: () => void;
	disabled?: boolean;
	className?: any;
}

/**
 * Component AddButton
 */
export const AddButton: React.FC<AddButtonProps> = ({ text, onClick, className, icon }) => {
	return (
		<div className="box-shadow">
			<div className="add-btn__container ">
				<div className="container-page">
					<ButtonWithEffect className={`btn btn-green--outline add-btn btn-round w-100 p-0 ${className}`} onClick={onClick}>
						{!icon && <img src={ImageAdd} />}
						<div>
							{text}
						</div>
						{icon && <i className={`${icon} icon--btn`} />}
					</ButtonWithEffect>
				</div>
			</div>
		</div>
	);
};

interface BigButtonProps {
	text?: string;
	type?: any;
	onClick?: () => void;
	className?: string;
	disabled?: boolean;
	icon?: string;
}

/**
 * Component BigButton
 */
export const BigButton: React.FC<BigButtonProps> = ({ type, text, onClick, className, disabled, icon, children }) => {
	return (
		<ButtonWithEffect className={`btn big-btn d-block ${className}`} onClick={onClick} type={type} disabled={disabled}>
			{icon ? (<span className="d-flex justify-content-center align-items-center"><img src={icon} title="" />{children ? children : text}</span>) : children ? children : text}
		</ButtonWithEffect>
	);
};

interface Props {
	onClick?: () => void;
	type?: any;
	disabled?: boolean;
	className?: string;
	text?: String
}

/**
 * Component SaveButton
 */
export const SaveButton: FunctionComponent<Props> = ({ onClick, type, disabled, text }) => (
	<BigButton type={type} disabled={disabled} className="btn-green" onClick={onClick} >
		{ text ? text : TitleOrButton.Button.CREATE}

	</BigButton>
);

/**
 * Component Cancel Button
 */
export const CancelButton: FunctionComponent<Props> = ({ onClick, type, className, text, disabled }) => {
	const refButton = useRef(null);
	useAddEffectButton(refButton);
	return (
		<button type={type} className={`btn big-btn  ${className}`} onClick={onClick} ref={refButton} disabled={disabled ? disabled : false}>
			{text ? text : TitleOrButton.Button.CANCEL}
		</button>
	)
}

/**
 * Component AddModalButton
 */
export const AddModalButton: FunctionComponent<AddButtonProps> = ({ text, onClick, disabled, className, icon }) => (
	<BigButton icon={icon} disabled={disabled} onClick={onClick} text={text} className={`add-modal-btn btn-outline-green ${!!className ? className : ""}`} />
);

interface PrimaryButtonProps {
	text: string;
	onClick?: () => void;
	className?: string;
	type?: any;
	icon?: string;
	disabled?: boolean;
}

/**
 * Component Primary Button
 */
export const PrimaryButton: React.FC<PrimaryButtonProps> = ({ icon, type, text, onClick, className, disabled }) => {
	return (
		<ButtonWithEffect
			type={type ? type : `button`}
			className={`btn big-btn btn-green btn-round ${className} ${disabled ? "btn-disable" : ""}`}
			onClick={onClick}
			disabled={disabled}
		>
			<div className="d-flex align-items-center btn__text">
				<div className="w-100">{text}</div>
			</div>
		</ButtonWithEffect>
	);
};

/**
 * Component DefaultButton 
 */
export const DefaultButton = (props: any) => {

	const focusRef = useRef<any>(null)

	/**
	 * Remove focus
	 */
	const removeFocus = () => {
		if (focusRef?.current) {
			focusRef.current.blur()
		}
	}

	return (
		<button {...props}
			ref={(ref) => focusRef.current = ref}
			onClick={() => {
				removeFocus()
				if (!!props?.onClick) {
					props.onClick();
				}
			}}
		>
			{props.children}
		</button>
	)
}
