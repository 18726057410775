import React from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { ToastContainer } from "react-toastify";

import store, { persistor } from "app/redux";
import { Routes } from "app/routes";
import LoadingOverlay from "app/components/LoadingOverlay";
import { SplashScreen } from "app/components/SplashScreen";
import LoadingManager from "app/helpers/loadingManager";

const { PUBLIC_URL } = process.env;

/**
 * Component App
 */
const App = () => {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={<SplashScreen />}>
        <React.Suspense fallback={<SplashScreen />}>
          <Routes basename={PUBLIC_URL} />
          <LoadingOverlay
            ref={(ref: LoadingOverlay) => LoadingManager.setLoadingRef(ref)}
          />
          <ToastContainer />
        </React.Suspense>
      </PersistGate>
    </Provider>
  );
};

export default App;
