export const getUserSelector = (stateReducer: any) => stateReducer.auth.user;

export const getTagColorsSelector = (stateReducer: any) => stateReducer.tagColors;
export const getTagsSelector = (stateReducer: any) => stateReducer.tagsList;
export const getTagDetailSelector = (stateReducer: any) => stateReducer.tagDetail;

export const getBookingDetailSelector = (stateReducer: any) => stateReducer.bookingTimelineDetail;
export const getBookingCalendarSelector = (stateReducer: any) => stateReducer.bookingCalendar;

export const getUserContractSelector = (stateReducer: any) => stateReducer.contractInfo;
export const getWarningSelector = (stateReducer: any) => stateReducer.warning;

export const getPaymentPlanInformationSelector = (stateReducer: any) => stateReducer.paymentPlanInformation;
export const getPaymentInformationSelector = (stateReducer: any) => stateReducer.paymentInformation;
export const getRegistCreditButtonStatus = (stateReducer: any) => stateReducer.registCreditButton;