import { Action } from "app/models/redux/action";
import { ReduxCallbacks } from "app/models/redux/Callbacks";
import { ListQuery } from "app/models/redux/ListQuery";

export const GET_TAG_LIST = "GET_TAG_LIST";
export const GET_TAG_LIST_SUCCESS = "GET_TAG_LIST_SUCCESS";
export const GET_TAG_LIST_FAILED = "GET_TAG_LIST_FAILED";
export const GET_TAG_LIST_CANCELLED = "GET_TAG_LIST_CANCELLED";

export const GET_TAG_COLORS = "GET_TAG_COLORS";
export const GET_TAG_COLORS_SUCCESS = "GET_TAG_COLORS_SUCCESS";
export const GET_TAG_COLORS_FAILED = "GET_TAG_COLORS_FAILED";
export const GET_TAG_COLORS_CANCELLED = "GET_TAG_COLORS_CANCELLED";

export const GET_TAG_DETAIL = "GET_TAG_DETAIL";
export const GET_TAG_DETAIL_SUCCESS = "GET_TAG_DETAIL_SUCCESS";
export const GET_TAG_DETAIL_FAILED = "GET_TAG_DETAIL_FAILED";
export const GET_TAG_DETAIL_CANCELLED = "GET_TAG_DETAIL_CANCELLED";

export const CREATE_TAG = "CREATE_TAG";
export const CREATE_TAG_SUCCESS = "CREATE_TAG_SUCCESS";
export const CREATE_TAG_FAILED = "CREATE_TAG_FAILED";
export const CREATE_TAG_CANCELLED = "CREATE_TAG_CANCELLED";

export const EDIT_TAG_DETAIL = "EDIT_TAG_DETAIL";
export const EDIT_TAG_DETAIL_SUCCESS = "EDIT_TAG_DETAIL_SUCCESS";
export const EDIT_TAG_DETAIL_FAILED = "EDIT_TAG_DETAIL_FAILED";
export const EDIT_TAG_DETAIL_CANCELLED = "EDIT_TAG_DETAIL_CANCELLED";

export const CHECK_USE_TAG = "CHECK_USE_TAG";
export const CHECK_USE_TAG_SUCCESS = "CHECK_USE_TAG_SUCCESS";
export const CHECK_USE_TAG_FAILDED = "CHECK_USE_TAG_SUCCESS";
export const CHECK_USE_TAG_CANCELLED = "CHECK_USE_TAG_SUCCESS";

export const DELETE_TAG = "DELETE_TAG";
export const DELETE_TAG_SUCCESS = "DELETE_TAG_SUCCESS";
export const DELETE_TAG_FAILED = "DELETE_TAG_FAILED";
export const DELETE_TAG_CANCELLED = "DELETE_TAG_CANCELLED";

/**
 * Action get list tag color
 */
export const getTagColorsList = (): Action => ({
  type: GET_TAG_COLORS,
});

/**
 * Action get list tag color success
 * @param payload 
 */
export const getTagColorsListSuccess = (
  payload: any[]
): Action<any[]> => ({
  type: GET_TAG_COLORS_SUCCESS,
  payload,
});

/**
 * Action get list tag color failed
 * @param error 
 */
export const getTagColorsListFailed = (error: any): Action => ({
  type: GET_TAG_COLORS_FAILED,
  payload: error,
});

/**
 * Action cancel get list tag color
 */
export const getTagColorsListCancelled = (): Action => ({
  type: GET_TAG_COLORS_CANCELLED,
});

/**
 * Action get list tag 
 * @param query 
 * @param callbacks 
 */
export const getTagsList = (
  query: ListQuery,
  callbacks?: ReduxCallbacks
): Action => ({
  type: GET_TAG_LIST,
  payload: { query, callbacks },
});

/**
 * Action get list tag success
 * @param payload 
 */
export const getTagsListSuccess = (
  payload: any[]
): Action<any[]> => ({
  type: GET_TAG_LIST_SUCCESS,
  payload,
});

/**
 * Action get list tag failed
 * @param error 
 */
export const getTagsListFailed = (error: any): Action => ({
  type: GET_TAG_LIST_FAILED,
  payload: error,
});

/**
 * Action cancel get list tag 
 */
export const getTagsListCancelled = (): Action => ({
  type: GET_TAG_LIST_CANCELLED,
});

/**
 * Action get detail tag
 * @param id 
 */
export const getTagDetail = (id:any): Action => ({
  type: GET_TAG_DETAIL,
  payload: id,
});

/**
 * Action get detail tag success
 * @param payload 
 */
export const getTagDetailSuccess = (
  payload: any[]
): Action<any[]> => ({
  type: GET_TAG_DETAIL_SUCCESS,
  payload,
});

/**
 * Action get detail tag failed
 * @param error 
 */
export const getTagDetailFailed = (error: any): Action => ({
  type: GET_TAG_DETAIL_FAILED,
  payload: error,
});

/**
 * Action cancel get detail tag
 */
export const getTagDetailCancelled = (): Action => ({
  type: GET_TAG_DETAIL_CANCELLED,
});

/**
 * Action create tag
 * @param body 
 * @param callbacks 
 */
export const createTag = (body?: any, callbacks?: ReduxCallbacks): Action => ({
  type: CREATE_TAG,
  payload: { body, callbacks },
});

/**
 * Action create tag success
 */
export const createTagSuccess = (): Action<any[]> => ({
  type: CREATE_TAG_SUCCESS,
});

/**
 * Action create tag failed
 * @param error 
 */
export const createTagFailed = (error: any): Action => ({
  type: CREATE_TAG_FAILED,
  payload: error,
});

/**
 * Action cancel create tag 
 */
export const createTagCancelled = (): Action => ({
  type: CREATE_TAG_CANCELLED,
});

/**
 * Action edit tag
 * @param id 
 * @param body 
 * @param callbacks 
 */
export const editTagDetail = (id:any, body:any, callbacks?: ReduxCallbacks): Action => ({
  type: EDIT_TAG_DETAIL,
  payload: { id, body, callbacks }
});

/**
 * Action edit tag success
 */
export const editTagDetailSuccess = (): Action<any[]> => ({
  type: EDIT_TAG_DETAIL_SUCCESS,
});

/**
 * Action edit tag failed
 * @param error 
 */
export const editTagDetailFailed = (error: any): Action => ({
  type: EDIT_TAG_DETAIL_FAILED,
  payload: error,
});

/**
 * Action cancel edit tag
 */
export const editTagDetailCancelled = (): Action => ({
  type: EDIT_TAG_DETAIL_CANCELLED,
});

/**
 * Action delete tag
 * @param id 
 * @param callbacks 
 */
export const deleteTag = (id:any, callbacks?: ReduxCallbacks): Action => ({
  type: DELETE_TAG,
  payload: { id, callbacks }
});

/**
 * Action delete tag success
 */
export const deleteTagSuccess = (): Action<any[]> => ({
  type: DELETE_TAG_SUCCESS,
});

/**
 * Action delete tag failed
 * @param error 
 */
export const deleteTagFailed = (error: any): Action => ({
  type: DELETE_TAG_FAILED,
  payload: error,
});

/**
 * Action cancel delete tag
 */
export const deleteTagCancelled = (): Action => ({
  type: DELETE_TAG_CANCELLED,
});

/**
 * Action check is use tag in form
 * @param id 
 * @param callbacks 
 */
export const checkUseTag = (id:any, callbacks?: ReduxCallbacks): Action => ({
  type: CHECK_USE_TAG,
  payload: { id, callbacks }
});

/**
 * Action check is use tag in form success
 */
export const checkUseTagSuccess = (): Action<any[]> => ({
  type: CHECK_USE_TAG_SUCCESS,
});

/**
 * Action check is use tag in form failed
 * @param error 
 */
export const checkUseTagFailed = (error: any): Action => ({
  type: DELETE_TAG_FAILED,
  payload: error,
});

/**
 * Action cancel check is use tag in form
 */
export const checkUseTagCancelled = (): Action => ({
  type: CHECK_USE_TAG_CANCELLED,
});