import { Action } from "app/models/redux/action";
import {
    GET_USER_CONTRACT,
    GET_USER_CONTRACT_SUCCESS,
    GET_USER_CONTRACT_FAILED,
    CANCEL_USER_CONTRACT,
    CANCEL_USER_CONTRACT_SUCCESS,
    CANCEL_USER_CONTRACT_FAILED
} from "./actions";
import {
    getUserContract,
    getUserContractSuccess,
    getUserContractFailed,
    cancelUserContract,
    cancelUserContractSuccess,
    cancelUserContractFailed
} from "app/helpers/reduxAction";

export const userContractReducer = (state: any = {}, action: Action) => {
    switch (action.type) {
        case GET_USER_CONTRACT:
            return getUserContract(action, state);
        case GET_USER_CONTRACT_SUCCESS:
            return getUserContractSuccess(action, state);
        case GET_USER_CONTRACT_FAILED:
            return getUserContractFailed(action, state);
        case CANCEL_USER_CONTRACT:
            return cancelUserContract(action, state);
        case CANCEL_USER_CONTRACT_SUCCESS:
            return cancelUserContractSuccess(action, state);
        case CANCEL_USER_CONTRACT_FAILED:
            return cancelUserContractFailed(action, state);
        default:
            return state;
    }
}