import { EndPointApis } from "app/constants";
import { ListQuery } from "app/models/redux/ListQuery";
import queryString from 'query-string';
import { Api } from "../api";

export class AdminService {
  /**
   * Get list report
   * @param query 
   */
  static getListReport(query: ListQuery) {
    let ListQuery = ``;
    if (query?.q) {
      for (const key in query.q) {
        if (Object.prototype.hasOwnProperty.call(query.q, key)) {
          const element = query.q[key];
          if (key.indexOf("in") !== -1) {
            element.map((item: any) => {
              ListQuery = ListQuery + `&q[${key}][]=${item}`
            })
          } else {
            if (element) {
              let q = queryString.stringify({ [key]: element })
              ListQuery = ListQuery + `&q[${q.replace('=', ']=')}`
            }
          }
        }
      }
    }

    return Api.getNoQuery(
      `${EndPointApis.Admin.GET_REPORT}?page=${query.page}&per_page=${query.per_page}${ListQuery}`, {}, true
    );
  }

  /**
   * Login admin
   * @param body 
   */
  static loginAdmin(body: any) {
    return Api.post(EndPointApis.Admin.LOGIN_ADMIN, body);
  }

  /**
   * Get Payment plans
   */

  static getPaymentPlans() {
    return Api.getNoQuery(EndPointApis.Admin.GET_PAYMENT_PLANS, {}, true)
  }


  /**
  * update Payment memo
  * @param data
  */

  static updatePaymentMemo(data: any) {
    return Api.put(EndPointApis.Admin.UPDATE_PAYMENT_MEMO(data.id), data.body, {}, true)
  }

  /**
* update Payment memo
* @param data
*/

  static deactivateUser(data: any) {
    return Api.delete(EndPointApis.Admin.DEACTIVATE_USER, {}, {}, { user_ids: data.body }, true)
  }

  /**
  * update invoice
  * @param data
  */

  static updateInvoice(data: any) {
    let { userId, values } = data;
    return Api.put(EndPointApis.Admin.UPDATE_INVOICE, { user_ids: userId, ...values }, {}, true)
  }

}
