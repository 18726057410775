import {
  SET_PAYMENT_PLAN_ID,
  SET_PAYMENT_INFORMATION,
  SET_EDITED_PAYMENT_PLAN,
  SET_EDITED_TO_DEFAULT_PAYMENT_PLAN,
  UPDATE_ONLY_PAYMENT_PLAN,
  UPDATE_ONLY_PAYMENT_PLAN_SUCCESS,
  UPDATE_ONLY_PAYMENT_PLAN_FAILED,
  UPDATE_PAYMENT_WITH_CREDITCARD,
  UPDATE_PAYMENT_WITH_CREDITCARD_SUCCESS,
  UPDATE_PAYMENT_WITH_CREDITCARD_FAILED,
  CHANGE_PAYMENT_TO_BANK,
  CHANGE_PAYMENT_TO_BANK_SUCCESS,
  CHANGE_PAYMENT_TO_BANK_FAILED,
  CREATE_NEW_BANK_PAYMENT,
  CREATE_NEW_BANK_PAYMENT_SUCCESS,
  CREATE_NEW_BANK_PAYMENT_FAILED
} from "./actions";
import { Action } from "../../../models/redux/action";
import {
  setPaymentPlanInformation,
  setPaymentInformation,
  updateOnlyPaymentPlan,
  updateOnlyPaymentPlanSucess,
  updateOnlyPaymentPlanFailed,
  updatePaymentWithCreditCard,
  updatePaymentWithCreditCardSuccess,
  updatePaymentWithCreditCardFailed,
  changePaymentToBank,
  changePaymentToBankSuccess,
  changePaymentToBankFailed,
  createNewBankPaymentTransfer,
  createNewBankPaymentTransferSuccess,
  createNewBankPaymentTransferFailed
} from "app/helpers/reduxAction";

/**
 * Loading Reducer
 * @param state
 * @param action
 */
export const paymentPlanIdReducer = (
  state: any = {},
  action: Action
) => {
  switch (action.type) {
    case SET_PAYMENT_PLAN_ID:
      return setPaymentPlanInformation(action, state);
    // TODO
    case SET_PAYMENT_INFORMATION:
      return setPaymentInformation(action, state);
    case SET_EDITED_PAYMENT_PLAN:
      return {
        ...state,
        isEdited: true
      };
    case SET_EDITED_TO_DEFAULT_PAYMENT_PLAN:
      return {
        ...state,
        isEdited: false
      }
    case CREATE_NEW_BANK_PAYMENT:
      return createNewBankPaymentTransfer(action, state);
    case CREATE_NEW_BANK_PAYMENT_SUCCESS:
      return createNewBankPaymentTransferSuccess(action, state);
    case CREATE_NEW_BANK_PAYMENT_FAILED:
      return createNewBankPaymentTransferFailed(action, state);
    case UPDATE_ONLY_PAYMENT_PLAN:
      return updateOnlyPaymentPlan(action, state);
    case UPDATE_ONLY_PAYMENT_PLAN_SUCCESS:
      return updateOnlyPaymentPlanSucess(action, state);
    case UPDATE_ONLY_PAYMENT_PLAN_FAILED:
      return updateOnlyPaymentPlanFailed(action, state);
    case UPDATE_PAYMENT_WITH_CREDITCARD:
      return updatePaymentWithCreditCard(action, state);
    case UPDATE_PAYMENT_WITH_CREDITCARD_SUCCESS:
      return updatePaymentWithCreditCardSuccess(action, state);
    case UPDATE_PAYMENT_WITH_CREDITCARD_FAILED:
      return updatePaymentWithCreditCardFailed(action, state);
    case CHANGE_PAYMENT_TO_BANK:
      return changePaymentToBank(action, state);
    case CHANGE_PAYMENT_TO_BANK_SUCCESS:
      return changePaymentToBankSuccess(action, state);
    case CHANGE_PAYMENT_TO_BANK_FAILED:
      return changePaymentToBankFailed(action, state);
    default:
      return state;
  }
};
