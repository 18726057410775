import { Action } from "app/models/redux/action";
import BookingListModel from "app/models/FormBookingList";
import { ReduxCallbacks } from "app/models/redux/Callbacks";
import { ListQuery } from "app/models/redux/ListQuery";
import { BookingCreateModel } from "app/models/FormBookingCreate";

export const GET_FORM_BOOKING_LIST = "GET_FORM_BOOKING_LIST";
export const GET_FORM_BOOKING_LIST_SUCCESS = "GET_FORM_BOOKING_LIST_SUCCESS";
export const GET_FORM_BOOKING_LIST_FAILED = "GET_FORM_BOOKING_LIST_FAILED";
export const GET_FORM_BOOKING_LIST_CANCELLED = "GET_FORM_BOOKING_LIST_CANCELLED";

export const GET_FORM_BOOKING_INIT = "GET_FORM_BOOKING_INIT";
export const GET_FORM_BOOKING_INIT_SUCCESS = "GET_FORM_BOOKING_INIT_SUCCESS";
export const GET_FORM_BOOKING_INIT_FAILED = "GET_FORM_BOOKING_INIT_FAILED";
export const GET_FORM_BOOKING_INIT_CANCELLED = "GET_FORM_BOOKING_INIT_CANCELLED";

export const CREATE_FORM_BOOKING = "CREATE_FORM_BOOKING";
export const CREATE_FORM_BOOKING_SUCCESS = "CREATE_FORM_BOOKING_SUCCESS";
export const CREATE_FORM_BOOKING_FAILED = "CREATE_FORM_BOOKING_FAILED";
export const CREATE_FORM_BOOKING_CANCELLED = "CREATE_FORM_BOOKING_CANCELLED";

export const EDIT_FORM_BOOKING = "EDIT_FORM_BOOKING";
export const EDIT_FORM_BOOKING_SUCCESS = "EDIT_FORM_BOOKING_SUCCESS";
export const EDIT_FORM_BOOKING_FAILED = "EDIT_FORM_BOOKING_FAILED";
export const EDIT_FORM_BOOKING_CANCELLED = "EDIT_FORM_BOOKING_CANCELLED";

export const GET_DEFAULT_FORM = "GET_DEFAULT_FORM";
export const GET_DEFAULT_FORM_SUCCESS = "GET_DEFAULT_FORM_SUCCESS";
export const GET_DEFAULT_FORM_FAILED = "GET_DEFAULT_FORM_FAILED";
export const GET_DEFAULT_FORM_CANCELLED = "GET_DEFAULT_FORM_CANCELLED";

export const GET_DETAIL_FORM = "GET_DETAIL_FORM";
export const GET_DETAIL_FORM_SUCCESS = "GET_DETAIL_FORM_SUCCESS";
export const GET_DETAIL_FORM_FAILED = "GET_DETAIL_FORM_FAILED";
export const GET_DETAIL_FORM_CANCELLED = "GET_DETAIL_FORM_CANCELLED";

export const GET_LIST_CALENDAR = "GET_LIST_CALENDAR";
export const GET_LIST_CALENDAR_SUCCESS = "GET_LIST_CALENDAR_SUCCESS";
export const GET_LIST_CALENDAR_FAILED = "GET_LIST_CALENDAR_FAILED";
export const GET_LIST_CALENDAR_CANCELLED = "GET_LIST_CALENDAR_CANCELLED";

export const GET_TASK_LIST_FORM = "GET_TASK_LIST_FORM";
export const GET_TASK_LIST_FORM_SUCCESS = "GET_TASK_LIST_FORM_SUCCESS";
export const GET_TASK_LIST_FORM_FAILED = "GET_TASK_LIST_FORM_FAILED";
export const GET_TASK_LIST_FORM_CANCELLED = "GET_TASK_LIST_FORM_CANCELLED";

export const UPDATE_FORM_DEFAULT = "UPDATE_FORM_DEFAULT";
export const UPDATE_FORM_DEFAULT_SUCCESS = "UPDATE_FORM_DEFAULT_SUCCESS";
export const UPDATE_FORM_DEFAULT_FAILED = "UPDATE_FORM_DEFAULT_FAILED";
export const UPDATE_FORM_DEFAULT_CANCELLED = "UPDATE_FORM_DEFAULT_CANCELLED";

export const DELETE_FORM = "DELETE_FORM";
export const DELETE_FORM_SUCCESS = "DELETE_FORM_SUCCESS";
export const DELETE_FORM_FAILED = "DELETE_FORM_FAILED";
export const DELETE_FORM_CANCELLED = "DELETE_FORM_CANCELLED";

export interface BookingCreate {
  form: BookingCreateModel;
}

/**
 * Action get list form calendar
 * @param query 
 * @param nextPageToken 
 * @param callbacks 
 */
export const getListCalendar = (query?: any, nextPageToken?: string, callbacks?: ReduxCallbacks): Action => ({
  type: GET_LIST_CALENDAR,
  payload: { callbacks, query, nextPageToken }
});

/**
 * Action get list form calendar success
 * @param payload 
 */
export const getListCalendarSuccess = (payload: any): Action => ({
  type: GET_LIST_CALENDAR_SUCCESS,
  payload
});

/**
 * Action get list form calendar failed
 * @param error 
 */
export const getListCalendarFailed = (error: any ): Action => ({
  type: GET_LIST_CALENDAR_FAILED,
  payload: error,
});

/**
 * Action cancel get list form calendar
 */
export const getListCalendarCancelled = (): Action => ({
  type: GET_LIST_CALENDAR_CANCELLED,
});

/**
 * Action get form default
 * @param callbacks 
 */
export const getDefaultForm = (callbacks?: ReduxCallbacks ): Action => ({
  type: GET_DEFAULT_FORM,
  payload: { callbacks }
});

/**
 * Action get form default success
 * @param payload 
 */
export const getDefaultFormSuccess = (payload: any): Action => ({
  type: GET_DEFAULT_FORM_SUCCESS,
  payload
});

/**
 * Action get form default failed
 * @param error 
 */
export const getDefaultFormFailed = (error: any ): Action => ({
  type: GET_DEFAULT_FORM_FAILED,
  payload: error,
});

/**
 * Action cancel get form default
 */
export const getDefaultFormCancelled = (): Action => ({
  type: GET_DEFAULT_FORM_CANCELLED,
});

/**
 * Action get detail form
 * @param id 
 * @param callbacks 
 */
export const getDetailForm = (id: string, callbacks?: ReduxCallbacks ): Action => ({
  type: GET_DETAIL_FORM,
  payload: { id, callbacks }
});

/**
 * Action get detail form success
 * @param payload 
 */
export const getDetailFormSuccess = (payload: any): Action => ({
  type: GET_DETAIL_FORM_SUCCESS,
  payload
});

/**
 * Action get detail form failed
 * @param error 
 */
export const getDetailFormFailed = (error: any ): Action => ({
  type: GET_DETAIL_FORM_FAILED,
  payload: error,
});

/**
 * Action cancel get detail form
 */
export const getDetailFormCancelled = (): Action => ({
  type: GET_DETAIL_FORM_CANCELLED,
});

/**
 * Action get form listing
 * @param query 
 * @param callbacks 
 */
export const getFormList = (
  query: ListQuery,
  callbacks?: ReduxCallbacks
): Action => ({
  type: GET_FORM_BOOKING_LIST,
  payload: { query, callbacks },
});

/**
 * Action get form listing success
 * @param payload 
 */
export const getFormListSuccess = (
  payload: BookingListModel[]
): Action<BookingListModel[]> => ({
  type: GET_FORM_BOOKING_LIST_SUCCESS,
  payload,
});

/**
 * Action get form listing failed
 * @param error 
 */
export const getFormListFailed = (error: any): Action => ({
  type: GET_FORM_BOOKING_LIST_FAILED,
  payload: error,
});

/**
 * Action cancel get form listing
 */
export const getFormListCancelled = (): Action => ({
  type: GET_FORM_BOOKING_LIST_CANCELLED,
});

/**
 * Action get form init
 * @param callbacks 
 */
export const getFormInit = (callbacks?: ReduxCallbacks): Action => ({
  type: GET_FORM_BOOKING_INIT,
  payload: { callbacks },
});

/**
 * Action get form init success
 * @param data 
 */
export const getFormInitSuccess = (data: any): Action => ({
  type: GET_FORM_BOOKING_INIT_SUCCESS,
  payload: { data },
});

/**
 * Action get form init failed 
 * @param error 
 */
export const getFormInitFailed = (error: any): Action => ({
  type: GET_FORM_BOOKING_INIT_FAILED,
  payload: error,
});

/**
 * Action cancel get form init
 */
export const getFormInitCancelled = (): Action => ({
  type: GET_FORM_BOOKING_INIT_CANCELLED,
});

/**
 * Action create form booking
 * @param body 
 * @param callbacks 
 */
export const createForm = (
  body: any,
  callbacks?: ReduxCallbacks
): Action<any> => ({
  type: CREATE_FORM_BOOKING,
  payload: { body, callbacks },
});

/**
 * Action create form booking success
 * @param payload 
 */
export const createFormSuccess = (payload: any): Action<any> => ({
  type: CREATE_FORM_BOOKING_SUCCESS,
  payload,
});

/**
 * Action create form booking failed
 * @param error 
 */
export const createFormFailed = (error: any): Action => ({
  type: CREATE_FORM_BOOKING_FAILED,
  payload: error,
});

/**
 * Action cancel create form booking
 */
export const createFormCancelled = (): Action => ({
  type: CREATE_FORM_BOOKING_CANCELLED,
});

/**
 * Action edit form booking
 * @param id 
 * @param body 
 * @param callbacks 
 */
export const editFormBooking = (id:any, body:any, callbacks?: ReduxCallbacks): Action<any> => ({
  type: EDIT_FORM_BOOKING,
  payload: { id, body, callbacks }
});

/**
 * Action edit form booking success
 * @param payload 
 */
export const editFormBookingSuccess = (payload: any): Action<any> => ({
  type: EDIT_FORM_BOOKING_SUCCESS,
  payload,
});

/**
 * Action edit form booking failed
 * @param error 
 */
export const editFormBookingFailed = (error: any): Action => ({
  type: EDIT_FORM_BOOKING_FAILED,
  payload: error,
});

/**
 * Action cancel edit form booking
 */
export const editFormBookingCancelled = (): Action => ({
  type: EDIT_FORM_BOOKING_CANCELLED,
});

/**
 * Action get task list
 * @param callbacks 
 */
export const getTaskListForm = (callbacks?: ReduxCallbacks): Action<any> => ({
  type: GET_TASK_LIST_FORM,
  payload: { callbacks }
});

/**
 * Action get task list success
 * @param payload 
 */
export const getTaskListFormSuccess = (payload: any): Action<any> => ({
  type: GET_TASK_LIST_FORM_SUCCESS,
  payload,
});

/**
 * Action get task list failed
 * @param error 
 */
export const getTaskListFormFailed = (error: any): Action => ({
  type: GET_TASK_LIST_FORM_FAILED,
  payload: error,
});

/**
 * Action cancel get task list
 */
export const getTaskListFormCancelled = (): Action => ({
  type: GET_TASK_LIST_FORM_CANCELLED,
});

/**
 * Action update default form
 * @param body 
 * @param callbacks 
 */
export const updateFormDefault = (
  body: any,
  callbacks?: ReduxCallbacks
): Action<any> => ({
  type: UPDATE_FORM_DEFAULT,
  payload: { body, callbacks },
});

/**
 * Action update default form success
 * @param payload 
 */
export const updateFormDefaultSuccess = (payload: any): Action<any> => ({
  type: UPDATE_FORM_DEFAULT_SUCCESS,
  payload,
});

/**
 * Action update default form failed
 * @param error 
 */
export const updateFormDefaultFailed = (error: any): Action => ({
  type: UPDATE_FORM_DEFAULT_FAILED,
  payload: error,
});

/**
 * Action cancel update default form 
 */
export const updateFormDefaultCancelled = (): Action => ({
  type: UPDATE_FORM_DEFAULT_CANCELLED,
});

/**
 * Action delete form
 * @param body 
 * @param callbacks 
 */
export const deleteForm = (
  body: any,
  callbacks?: ReduxCallbacks
): Action<any> => ({
  type: DELETE_FORM,
  payload: { body, callbacks },
});

/**
 * Action delete form success
 * @param payload 
 */
export const deleteFormSuccess = (payload: any): Action<any> => ({
  type: DELETE_FORM_SUCCESS,
  payload,
});

/**
 * Action delete form failed
 * @param error 
 */
export const deleteFormFailed = (error: any): Action => ({
  type: DELETE_FORM_FAILED,
  payload: error,
});

/**
 * Action cancel delete form 
 */
export const deleteFormCancelled = (): Action => ({
  type: DELETE_FORM_CANCELLED,
});