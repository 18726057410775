import { ActionsObservable, ofType } from "redux-observable";
import { map, catchError, takeUntil, mergeMap } from "rxjs/operators";
import { of } from "rxjs";
import { Action } from "app/models/redux/action";
import { onEpicSuccess, onEpicFailed } from "app/helpers/reduxEpic";
import { CANCEL_ALL_REQUEST } from "app/redux/default";
import { BookingsFormService } from "app/services/forms";
import BookingListModel from "app/models/FormBookingList";
import * as Actions from "./actions";

/**
 * Handle when action is GET_LIST_CALENDAR
 * @param action$ 
 */
export const fetchListCalendar = (action$: ActionsObservable<Action>) =>
  action$.pipe(
    ofType(Actions.GET_LIST_CALENDAR),
    mergeMap((action) =>
      BookingsFormService.getCalendarsFormRequest(action.payload.query, action.payload.nextPageToken).pipe(
        map((ajaxResponse) => {
          const { response } = ajaxResponse;
          const { data } = response;
          if (data) {
            return onEpicSuccess(
              action,
              ajaxResponse,
              Actions.getListCalendarSuccess(data)
            );
          }
        }),
        catchError((error) => {
          return of(
            onEpicFailed(action, error, Actions.getListCalendarFailed(error))
          );
        }),
        takeUntil(
          action$.pipe(
            ofType(Actions.GET_DETAIL_FORM_CANCELLED, CANCEL_ALL_REQUEST)
          )
        )
      )
    )
  );

/**
 * Handle when action is GET_DETAIL_FORM
 * @param action$ 
 */
export const fetchDetailFormEpic = (action$: ActionsObservable<Action>) =>
  action$.pipe(
    ofType(Actions.GET_DETAIL_FORM),
    mergeMap((action) =>
      BookingsFormService.getDetailForm(action.payload.id).pipe(
        map((ajaxResponse) => {
          const { response } = ajaxResponse;
          const { data } = response;
          if (data) {
            return onEpicSuccess(
              action,
              ajaxResponse,
              Actions.getDefaultFormSuccess(data)
            );
          }
        }),
        catchError((error) => {
          return of(
            onEpicFailed(action, error, Actions.getDefaultFormFailed(error))
          );
        }),
        takeUntil(
          action$.pipe(
            ofType(Actions.GET_DETAIL_FORM_CANCELLED, CANCEL_ALL_REQUEST)
          )
        )
      )
    )
  );

/**
 * Handle when action is GET_DEFAULT_FORM
 * @param action$ 
 */
export const fetchDefaultFormEpic = (action$: ActionsObservable<Action>) =>
  action$.pipe(
    ofType(Actions.GET_DEFAULT_FORM),
    mergeMap((action) =>
      BookingsFormService.getDefaultForm().pipe(
        map((ajaxResponse) => {
          const { response } = ajaxResponse;
          const { data } = response;
          if (data) {
            return onEpicSuccess(
              action,
              ajaxResponse,
              Actions.getDefaultFormSuccess(data)
            );
          }
        }),
        catchError((error) => {
          return of(
            onEpicFailed(action, error, Actions.getDefaultFormFailed(error))
          );
        }),
        takeUntil(
          action$.pipe(
            ofType(Actions.GET_DEFAULT_FORM_CANCELLED, CANCEL_ALL_REQUEST)
          )
        )
      )
    )
  );

/**
 * Handle when action is GET_FORM_BOOKING_LIST
 * @param action$ 
 */
export const fetchFormListEpic = (action$: ActionsObservable<Action>) =>
  action$.pipe(
    ofType(Actions.GET_FORM_BOOKING_LIST),
    mergeMap((action) =>
      BookingsFormService.getFormList(action.payload.query).pipe(
        map((ajaxResponse) => {
          const { response } = ajaxResponse;
          const data = response.data?.records as BookingListModel[];
          const result =
            data &&
            data.map((item) => BookingListModel.parseFromResponse(item));
          if (result) {
            return onEpicSuccess(
              action,
              ajaxResponse,
              Actions.getFormListSuccess(result)
            );
          }
        }),
        catchError((error) => {
          return of(
            onEpicFailed(action, error, Actions.getFormListFailed(error))
          );
        }),
        takeUntil(
          action$.pipe(
            ofType(Actions.GET_FORM_BOOKING_LIST_CANCELLED, CANCEL_ALL_REQUEST)
          )
        )
      )
    )
  );

/**
 * Handle when action is GET_FORM_BOOKING_INIT
 * @param action$ 
 */
export const getFormInitEpic = (action$: ActionsObservable<Action>) =>
  action$.pipe(
    ofType(Actions.GET_FORM_BOOKING_INIT),
    mergeMap((action) =>
      BookingsFormService.loadFormInit().pipe(
        map(({ response }) => {
          if (response?.data) {
            return onEpicSuccess(
              action,
              response.data,
              Actions.getFormInitSuccess(response.data)
            );
          }
        }),
        catchError((error) => {
          return of(
            onEpicFailed(action, error, Actions.getFormInitFailed(error))
          );
        }),
        takeUntil(
          action$.pipe(
            ofType(Actions.GET_FORM_BOOKING_INIT_CANCELLED, CANCEL_ALL_REQUEST)
          )
        )
      )
    )
  );

/**
 * Handle when action is CREATE_FORM_BOOKING
 * @param action$ 
 */
export const addFormEpic = (action$: ActionsObservable<Action>) =>
  action$.pipe(
    ofType(Actions.CREATE_FORM_BOOKING),
    mergeMap((action) => {
      return BookingsFormService.createFormRequest(action.payload.body).pipe(
        map(({ response }) => {
          return onEpicSuccess(
            action,
            response,
            Actions.createFormSuccess(response)
          );
        }),
        catchError((error) => {
          return of(
            onEpicFailed(action, error, Actions.createFormFailed(error))
          );
        }),
        takeUntil(
          action$.pipe(
            ofType(Actions.CREATE_FORM_BOOKING_CANCELLED, CANCEL_ALL_REQUEST)
          )
        )
      );
    })
  );

/**
 * Handle when action is EDIT_FORM_BOOKING
 * @param action$ 
 */
export const editFormBookingEpic = (action$: ActionsObservable<Action>) =>
action$.pipe(
  ofType(Actions.EDIT_FORM_BOOKING),
  mergeMap((action) =>
    BookingsFormService.editFormRequest(action.payload.id, action.payload.body).pipe(
      map((ajaxResponse) => {
        const { response } = ajaxResponse;
        const { status } = response;
        if (status) {
          return onEpicSuccess(
            action,
            ajaxResponse,
            Actions.editFormBookingSuccess(ajaxResponse)
          )
        }
      }),
      catchError((error) => {
        return of(
          onEpicFailed(
            action,
            error,
            Actions.editFormBookingFailed(error))
          );
      }),
      takeUntil(
        action$.pipe(
          ofType(Actions.EDIT_FORM_BOOKING_CANCELLED, CANCEL_ALL_REQUEST)
        )
      )
    )
  )
);

/**
 * Handle when action is GET_TASK_LIST_FORM
 * @param action$ 
 */
export const fetchTagsListFormEpic = (action$: ActionsObservable<Action>) =>
  action$.pipe(
    ofType(Actions.GET_TASK_LIST_FORM),
    mergeMap((action) =>
    BookingsFormService.getTagsFormRequest().pipe(
        map((ajaxResponse) => {
          const { response } = ajaxResponse;
          const data = response.data?.records as any[];
          const result = data;
          if (result) {
            return onEpicSuccess(
              action,
              ajaxResponse,
              Actions.getTaskListFormSuccess(result)
            );
          } else {
            return onEpicFailed(action, ajaxResponse, Actions.getTaskListFormFailed(ajaxResponse));
          }
        }),
        catchError((error) => {
          return of(
            onEpicFailed(action, error, Actions.getTaskListFormFailed(error))
          );
        }),
        takeUntil(
          action$.pipe(
            ofType(Actions.GET_TASK_LIST_FORM_CANCELLED, CANCEL_ALL_REQUEST)
          )
        )
      )
    )
  );

/**
 * Handle when action is UPDATE_FORM_DEFAULT
 * @param action$ 
 */
export const updateFormDefaultEpic = (action$: ActionsObservable<Action>) =>
  action$.pipe(
    ofType(Actions.UPDATE_FORM_DEFAULT),
    mergeMap((action) => {
      return BookingsFormService.updateFormDefault(action.payload.body).pipe(
        map(({ response }) => {
          return onEpicSuccess(
            action,
            response,
            Actions.createFormSuccess(response)
          );
        }),
        catchError((error) => {
          return of(
            onEpicFailed(action, error, Actions.createFormFailed(error))
          );
        }),
        takeUntil(
          action$.pipe(
            ofType(Actions.UPDATE_FORM_DEFAULT_CANCELLED, CANCEL_ALL_REQUEST)
          )
        )
      );
    })
  );


  /**
 * Handle when action is UPDATE_FORM_DEFAULT
 * @param action$ 
 */
export const deleteFormEpic = (action$: ActionsObservable<Action>) =>
action$.pipe(
  ofType(Actions.DELETE_FORM),
  mergeMap((action) => {
    return BookingsFormService.deleteForm(action.payload.body.id).pipe(
      map(({ response }) => {
        return onEpicSuccess(
          action,
          response,
          Actions.deleteFormSuccess(response)
        );
      }),
      catchError((error) => {
        return of(
          onEpicFailed(action, error, Actions.deleteFormFailed(error))
        );
      }),
      takeUntil(
        action$.pipe(
          ofType(Actions.DELETE_FORM_CANCELLED, CANCEL_ALL_REQUEST)
        )
      )
    );
  })
);