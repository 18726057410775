import { Api } from "../api";
import { ListQueryBooking } from "app/models/redux/ListQuery";
import { EndPointApis } from "app/constants";
import { TIMEOUT_REQUEST } from "app/constants/constants";

/**
 * Service Booking
 */
export class BookingService {

  /**
   * Get booking list
   * @param query 
   */
  static getBookingList(query: ListQueryBooking) {
    return Api.getNoQuery(
      `${EndPointApis.Booking.INDEX}?page=${query.page}&year_month=${query.yearMonth}&beginning_of_month=${query.beginOfMonth}&per_page=${query.per_page}`
    );
  }

  /**
   * Get booking detail
   * @param id 
   */
  static getBookingDetail(id: any) {
    return Api.get(`${EndPointApis.Booking.INDEX}/${id}`);
  }

  /**
   * Delete booking
   * @param id 
   */
  static deleteBooking(id: any) {
    return Api.delete(`${EndPointApis.Booking.INDEX}/${id}`);
  }

  /**
   * Get calendar by id
   * @param id 
   */
  static getCalendarFormById(id: any) {
    return Api.getNoQuery(`${EndPointApis.Booking.GET_TIME_BOOKING}?form_id=${id}`)
  }

  /**
   * Create booking calendar
   * @param form_id_encode 
   * @param body 
   */
  static createBookingCalendar(form_id_encode: any, body: any, timeout = TIMEOUT_REQUEST) {
    return Api.post(EndPointApis.Booking.FORM(form_id_encode), body, {}, timeout);
  }
}
