import { Action } from "app/models/redux/action";
import { ListState } from "app/models/redux/listState";

import {
  GET_BOOKING_LIST,
  GET_BOOKING_LIST_SUCCESS,
  GET_BOOKING_LIST_FAILED,
  GET_BOOKING_DETAIL,
  GET_BOOKING_DETAIL_SUCCESS,
  GET_BOOKING_DETAIL_FAILED,
  DELETE_BOOKING_DETAIL,
  DELETE_BOOKING_DETAIL_SUCCESS,
  DELETE_BOOKING_DETAIL_FAILED,
} from "./actions";
import {
  fetchListState,
  fetchListStateSuccess,
  fetchListStateFailed,
  baseFetchListState,
  baseFetchDetailState,
  fetchDetailStateSuccess,
  fetchDetailStateFailed,
  fetchDetailState,
  deleteInit,
  deleteSuccess,
  deleteFailed,
} from "app/helpers/reduxAction";

const allBookingListState: ListState<any> = baseFetchListState();
const bookingDetail: any = baseFetchDetailState();

/**
 * Store booking list
 * @param state 
 * @param action 
 */
export const getBookingTimelineListReducer = (
  state: ListState<any> = allBookingListState,
  action: Action
) => {
  switch (action.type) {
    case GET_BOOKING_LIST:
      return fetchListState(action);
    case GET_BOOKING_LIST_SUCCESS:
      return fetchListStateSuccess(action, state);
    case GET_BOOKING_LIST_FAILED:
      return fetchListStateFailed(action, state); 
    case DELETE_BOOKING_DETAIL:
      return deleteInit(action, state);
    case DELETE_BOOKING_DETAIL_SUCCESS:
      return deleteSuccess(action, state);
    case DELETE_BOOKING_DETAIL_FAILED:
      return deleteFailed(action, state);
    default:
      return state;
  }
};

/**
 * Store booking detail
 * @param state 
 * @param action 
 */
export const getBookingTimelineDetailReducer = (
  state = bookingDetail,
  action: Action
) => {
  switch (action.type) {
    case GET_BOOKING_DETAIL:
      return fetchDetailState(action, state);
    case GET_BOOKING_DETAIL_SUCCESS:
      return fetchDetailStateSuccess(action, state);
    case GET_BOOKING_DETAIL_FAILED:
      return fetchDetailStateFailed(action, state); 
    default:
      return state;
  }
}