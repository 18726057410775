import { Action } from "app/models/redux/action";
import { ListState, ListTagColorState } from "app/models/redux/listState";
import {
  GET_TAG_LIST,
  GET_TAG_LIST_SUCCESS,
  GET_TAG_LIST_FAILED,
  GET_TAG_COLORS,
  GET_TAG_COLORS_SUCCESS,
  GET_TAG_COLORS_FAILED,
  GET_TAG_DETAIL,
  GET_TAG_DETAIL_SUCCESS,
  EDIT_TAG_DETAIL,
  EDIT_TAG_DETAIL_SUCCESS,
  EDIT_TAG_DETAIL_FAILED,
  DELETE_TAG,
  DELETE_TAG_SUCCESS,
  DELETE_TAG_FAILED,
} from "./actions";
import {
  fetchListState,
  fetchListStateSuccess,
  fetchListStateFailed,
  baseFetchListState,
  getTagColors,
  getTagColorsSuccess,
  getTagColorsFailed,
  fetchTagDetail,
  fetchTagDetailSuccess,
  fetchTagDetailFailed,
  editInit,
  editSuccess,
  editFailed,
  deleteInit,
  deleteFailed,
  deleteSuccess,
} from "app/helpers/reduxAction";

const allTagsListState: ListState<any> = baseFetchListState();

/**
 * Store tag list
 * @param state 
 * @param action 
 */
export const getTagsListReducer = (
  state: ListState<any> = allTagsListState,
  action: Action
) => {
  switch (action.type) {
    case GET_TAG_LIST:
      return fetchListState(action);
    case GET_TAG_LIST_SUCCESS:
      return fetchListStateSuccess(action, state);
    case GET_TAG_LIST_FAILED:
      return fetchListStateFailed(action, state); 
    default:
      return state;
  }
};

/**
 * Store tag detail
 * @param state 
 * @param action 
 */
export const getTagDetailReducer = (state: any = {}, action: Action) => {
  switch (action.type) {
    case GET_TAG_DETAIL:
      return fetchTagDetail(action, state);
    case GET_TAG_DETAIL_SUCCESS:
      return fetchTagDetailSuccess(action, state);
    case GET_TAG_COLORS_FAILED:
      return fetchTagDetailFailed(action, state); 
    case EDIT_TAG_DETAIL:
      return editInit(action, state);
    case EDIT_TAG_DETAIL_SUCCESS:
      return editSuccess(action, state);
    case EDIT_TAG_DETAIL_FAILED:
      return editFailed(action, state); 
    case DELETE_TAG:
      return deleteInit(action, state);
    case DELETE_TAG_SUCCESS:
      return deleteSuccess(action, state);
    case DELETE_TAG_FAILED:
      return deleteFailed(action, state);
    default:
      return state;
  }
}

/**
 * Store tag colors
 * @param state 
 * @param action 
 */
export const tagColorsReducer = (
  state: ListTagColorState<any> = {
    data: [],
    loading: false,
    error: {}
  },
  action: Action
) => {
  switch (action.type) {
    case GET_TAG_COLORS:
      return getTagColors(action, state);
    case GET_TAG_COLORS_SUCCESS:
      return getTagColorsSuccess(action, state);
    case GET_TAG_COLORS_FAILED:
      return getTagColorsFailed(action, state);
    default:
      return state;
  }
}