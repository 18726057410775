import BottomTabNavigation from 'app/components/Common/BottomTabNavigation/BottomTabNavigation';
import { Footer } from 'app/components/Common/Footer/Footer';
import { Header } from 'app/components/Common/Header/Header';
import ScrollToTop from 'app/components/Common/ScrollToTop';
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';
import './style.scss';
import { useRef } from 'react';

// Component DashboardLayout
export const DashboardLayout: FunctionComponent = ({ children }) => {

  const [isShowMenu, setShowMenu] = useState(true);
  const windowWHeightRef = useRef(window.innerHeight)

  /**
   * Handle when open virtual keyboard
   */
  const handleResize = useCallback(() => {
    if (windowWHeightRef.current - window.innerHeight > 100) {
      setShowMenu(false)
    } else {
      setShowMenu(true)
    }
  }, [window])

  useMemo(() => {
    window.addEventListener('resize', handleResize);
  }, [])

  return (
    <>
      <ScrollToTop />
      <Header />
      <main>{children}</main>
      {isShowMenu && <BottomTabNavigation />}
      <Footer isShowMenu={isShowMenu} />
    </>
  );
};
