import {
  baseFetchListState, fetchListState,

  fetchListStateFailed, fetchListStateSuccess
} from "app/helpers/reduxAction";
import { Action } from "app/models/redux/action";
import { ListState } from "app/models/redux/listState";
import {
  DEACTIVATE_USE, DEACTIVATE_USE_FAILED,

  DEACTIVATE_USE_SUCCESS, GET_PAYMENT_PLANS, GET_PAYMENT_PLANS_FAILED, GET_PAYMENT_PLANS_SUCCESS, GET_REPORT,
  GET_REPORT_FAILED, GET_REPORT_SUCCESS,

  LOGIN_ADMIN_FAILED, LOGIN_ADMIN_SUCCESS,
  LOGOUT_ADMIN_SUCCESS,

  UPDATE_INVOICE,

  UPDATE_INVOICE_FAILED,

  UPDATE_INVOICE_SUCCESS,

  UPDATE_PAYMENT_MEMO,
  UPDATE_PAYMENT_MEMO_FAILED, UPDATE_PAYMENT_MEMO_SUCCESS
} from "./actions";

const allTagsListState: ListState<any> = baseFetchListState();
const allPaymentPlansListState: ListState<any> = baseFetchListState();

/**
 * Store report list
 * @param state 
 * @param action 
 */
export const getListReportReducer = (
  state: ListState<any> = allTagsListState,
  action: Action
) => {
  switch (action.type) {
    case GET_REPORT:
      return fetchListState(action);
    case GET_REPORT_SUCCESS:
      return fetchListStateSuccess(action, state);
    case GET_REPORT_FAILED:
      return fetchListStateFailed(action, state);
    case DEACTIVATE_USE:
      return fetchListState(action);
    case DEACTIVATE_USE_SUCCESS:
      return fetchListStateSuccess(action, state);
    case DEACTIVATE_USE_FAILED:
      return fetchListStateFailed(action, state);
    case UPDATE_INVOICE:
      return fetchListState(action);
    case UPDATE_INVOICE_SUCCESS:
      return fetchListStateSuccess(action, state);
    case UPDATE_INVOICE_FAILED:
      return fetchListStateFailed(action, state);
    default:
      return state;
  }
};

const loginAdminInitState = {
  access_token: null,
  expires_at: null,
  refresh_token: null,
  actionType: "",
};

export const loginAdminReducer = (state: any = loginAdminInitState, action: Action) => {
  switch (action.type) {
    case LOGIN_ADMIN_SUCCESS:
      let data = action?.payload?.data
      return {
        ...state,
        actionType: action.type,
        access_token: data?.access_token,
        expires_at: data?.expires_at,
        refresh_token: data?.refresh_token,
      };
    case LOGIN_ADMIN_FAILED:
      return {
        actionType: action.type,
        access_token: null,
        expires_at: null,
        refresh_token: null,
      };
    case LOGOUT_ADMIN_SUCCESS:
      return {
        actionType: action.type,
        access_token: null,
        expires_at: null,
        refresh_token: null,
      };
    default:
      return state;
  }
};

export const paymentPlansReducer = (
  state: ListState<any> = allPaymentPlansListState,
  action: Action
) => {
  switch (action.type) {
    case GET_PAYMENT_PLANS:
      return fetchListState(action);
    case GET_PAYMENT_PLANS_SUCCESS:
      return fetchListStateSuccess(action, state);
    case GET_PAYMENT_PLANS_FAILED:
      return fetchListStateFailed(action, state);
    default:
      return state;
  }
}


export const paymentMemoReducer = (
  state: ListState<any> = allPaymentPlansListState,
  action: Action
) => {
  switch (action.type) {
    case UPDATE_PAYMENT_MEMO:
      return fetchListState(action);
    case UPDATE_PAYMENT_MEMO_SUCCESS:
      return fetchListStateSuccess(action, state);
    case UPDATE_PAYMENT_MEMO_FAILED:
      return fetchListStateFailed(action, state);
    default:
      return state;
  }
}