// Constant code of method when call api.
export const CodeResponseApis = {
    Https: {
        // 正常
        OK: 200,

        // パース不可能なリクエストボディーが来た場合の応答
        BAD_REQUEST: 400,

        // 存在しないリソースへのリクエストに応答
        NOT_FOUND: 404,

        // Not Allowed
        NOT_ALLOWED: 405,

        // 作成・更新しようとしたリソースが既に存在している場合に応答
        CONFLICT: 409,

        // バリデーションエラーに対しての応答
        UNPROCESSABLE_ENTITY: 422,

        // その他のサーバに起因するエラーにより処理続行できない場合
        INTERNAL_SERVER_ERROR: 500,

        // Unauthorize
        UNAUTHORIZE: 401
    },
    Privates: {
        SUCCESS : 2000,
        NOT_INTERNAL_REQUEST: 5102,
        RECORD_INVALID: 4001,
        EXISTED: 4205
    }
};
