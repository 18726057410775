import { Action } from "app/models/redux/action";
import { ListState } from "app/models/redux/listState";
import BookingListModel from "app/models/FormBookingList";
import {
  GET_FORM_BOOKING_LIST,
  GET_FORM_BOOKING_LIST_SUCCESS,
  GET_FORM_BOOKING_LIST_FAILED,
  CREATE_FORM_BOOKING,
  CREATE_FORM_BOOKING_SUCCESS,
  CREATE_FORM_BOOKING_FAILED,
  EDIT_FORM_BOOKING,
  EDIT_FORM_BOOKING_SUCCESS,
  EDIT_FORM_BOOKING_FAILED,
  GET_FORM_BOOKING_INIT,
  GET_FORM_BOOKING_INIT_SUCCESS,
  GET_FORM_BOOKING_INIT_FAILED,
  UPDATE_FORM_DEFAULT,
  UPDATE_FORM_DEFAULT_SUCCESS,
  UPDATE_FORM_DEFAULT_FAILED,
  DELETE_FORM_SUCCESS,
  DELETE_FORM,
  DELETE_FORM_FAILED
} from "./actions";
import {
  fetchListState,
  fetchListStateSuccess,
  fetchListStateFailed,
  baseFetchListState,
  addInit,
  addSuccess,
  addFailed,
  editInit,
  editSuccess,
  editFailed,
  deleteInit,
  deleteSuccess,
  deleteFailed
} from "app/helpers/reduxAction";

const allBookingState: ListState<BookingListModel> = baseFetchListState();
const initBookingState: ListState<any> = baseFetchListState();

/**
 * Get Form List Reducer
 * @param state 
 * @param action 
 */
export const getFormListReducer = (
  state: ListState<BookingListModel> = allBookingState,
  action: Action
) => {
  switch (action.type) {
    case GET_FORM_BOOKING_LIST:
      return fetchListState(action);
    case GET_FORM_BOOKING_LIST_SUCCESS:
      return fetchListStateSuccess(action, state);
    case GET_FORM_BOOKING_LIST_FAILED:
      return fetchListStateFailed(action, state);
    case CREATE_FORM_BOOKING:
      return addInit(action, state);
    case CREATE_FORM_BOOKING_SUCCESS:
      return addSuccess(action, state);
    case CREATE_FORM_BOOKING_FAILED:
      return addFailed(action, state);
    case EDIT_FORM_BOOKING:
      return editInit(action, state);
    case EDIT_FORM_BOOKING_SUCCESS:
      return editSuccess(action, state);
    case EDIT_FORM_BOOKING_FAILED:
      return editFailed(action, state);
    case UPDATE_FORM_DEFAULT:
      return addInit(action, state);
    case UPDATE_FORM_DEFAULT_SUCCESS:
      return addSuccess(action, state);
    case UPDATE_FORM_DEFAULT_FAILED:
      return addFailed(action, state);
    case DELETE_FORM:
      return deleteInit(action, state);
    case DELETE_FORM_SUCCESS:
      return deleteSuccess(action, state);
    case DELETE_FORM_FAILED:
      return deleteFailed(action, state);
    default:
      return state;
  }
};

/**
 * Get Form Init Reducer
 * @param state 
 * @param action 
 */
export const getFormInitReducer = (
  state: ListState<any> = initBookingState,
  action: Action
) => {
  switch (action.type) {
    case GET_FORM_BOOKING_INIT:
      return fetchListState(action);
    case GET_FORM_BOOKING_INIT_SUCCESS:
      return null;
    case GET_FORM_BOOKING_INIT_FAILED:
      return fetchListStateFailed(action, state);
    default:
      return state;
  }
};
