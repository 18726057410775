import env from 'app/helpers/env';

// Constant messages
export const Messages = {
  Auth: {
    SUCCESS: "ログインに成功しました",
    Failed: {
      MAIN: "ログインできませんアカウント情報を確認してください",
      GOOGLE_AUTHENTICATION_FAILED: "Google 認証できません",
      GOOGLE_AUTHENTICATION_NOT_G_SUITE_ACCOUNT: "G suiteをご利用のアカウントのみご利用いただけます",
    },
    AUTHENTICATION_FAILED: " 認証情報の有効期限が切れました。再度ログインしてください。",
    ADMIN_AUTHENTICATION_FAILED: "ユーザーIDまたはパスワードが違います。"

  },
  GoogleLogin: {
    IDPIFRAME_INITIALIZATION_FAILED: "第三者がアクセスできるようにクッキーを有効にしてください",
    POPUP_CLOSED_BY_USER: " ログインフローが未完了の状態でログインのポップアップを閉じました",
    ACCESS_DENIED: "アクセス権限無しのスコープのため拒否されます",
    IMMEDIATE_FAILED: "アクセス権限を付与してください",
    GOOGLE_LOGIN_FAILED: "ログインできません"
  },
  ChangeInvoice: {
    REQUIRED: "{item_name}は必ず入力してください",
    ITEM_MAX_LENGTH_255: "{item_name}は255文字以内に入力してください",
    ITEM_MAX_LENGTH_50: "{item_name}は50文字以内に入力してください",
  },
  Common: {
    EXCEPTION_DEFAULT: "Exception default",
    SUCCESS_DEFAULT: "Success default"
  },
  Form: {
    COPIED_URL_SUCCESS: "予約フォームのURLをコピーしました",
    SUCCESS: "ログインに成功しました",
    FAILED: "ログインに失敗しましたログイン情報を確認してください",
    EDIT_SUCCESS: "タグが更新されました",
    CREATE_SUCCESS: "タグが作成されました",
    UPDATE_SETTING_FORM_DEFAULT_SUCCESS: "タグが作成されました",
    COPIED_SUCCESS: "タグを複製しました",
    EDIT_FAILED: "タグが更新されました",
    CREATE_FAILED: "タグが作成されました",
    COPIED_FAILED: "タグを複製しました",
    COPIED_URL_FORM_UPDATE: "クリップボードにURLをコピーしました",
    COPIED_ITEM_NAME_EMAIL: "変数をコピーしました",
    FORM_IS_DIABLE: "Form is disable",
    BasicSetting: {
      QUESTION_1: "この項目を有効にすることと、次回以降新規で予約フォームを作成する際に、上記で入力したものがデフォルトで表示されるようになります。",
      QUESTION_2: "基本的な設定の入力フォームなどを作成した場合にご利用ください。デフォルトの入力情報は、各種設定からも変更できます。",
    },
    FreeTime: {
      QUESTION_1: "アポの前後に必要な移動や準備などにかかる時間を設定できます。この前後の空き時間はアポの時間とは別でカレンダー上には書き込まれません。",
      QUESTION_2: "アポが「20分」、前後の空き時間が「15分」の場合、合計「50分」かかる計算になります。"
    },
    QuestionAppointment: {
      QUESTION_1: "この設定では「予約フォームを開いた日」から何日後までの予約を受け付けるかの変更を行えます。",
      QUESTION_2: "例として「1日後」から「20日後」までの設定の時に、9月2日にフォームを開いた場合には「9月3日（1日後）から9月22日（20日後）」までの間でアポの予約が可能です。"
    },
    DeleteFormBooking: {
      QUESTION: "上記の予約フォームを削除します。\nこの操作をもとに戻すことはできません。\nよろしいですか？",
    }
  },
  Tag: {
    TAG_NAME_REQUIRE: "タグ名を入力してください",
    TAG_NAME_MAX_LENGTH: "タグ名は20文字以内に入力してください",
    COLOR_REQUIRED: "タグ色を入力してください",
    DELETE_SUCCESS: "タグが削除されました",
    NOT_FOUND_ITEM: "レコードが見つかりません",
    EDIT_SUCCESS: "タグが更新されました",
    CREATE_SUCCESS: "タグが作成されました",
    COPIED_SUCCESS: "タグを複製しました",
    DEFAULT_ERROR: "Error",
    TAG_NOT_EXISTED: "対象データが見つかりません"
  },
  Booking: {
    DELETE_SUCCESS: "予約が削除されました",
    CREATE_SUCCESS: "予約が完了しました",
    CREATE_FAILED: "予約の作成に失敗しました"
  },
  Setting: {
    UPDATE_PROFILE_SUCCESS: "プロフィール情報を更新しました",
  },
  Profile: {
    USERNAME_REQUIRE: "ユーザ名を入力してください",
    EMAIL_REQUIRE: "メールアドレスを入力してください",
    COMPANY_REQUIRE: "会社名を入力してください",
    USERNAME_MAX_LENGTH: "ユーザ名は20文字以内に入力してください",
    EMAIL_MAX_LENGTH: "メールアドレスは255文字以内に入力してください",
    COMPANY_MAX_LENGTH: "会社名は50文字以内に入力してください",
  },
  ValidateForm: {
    REQUIRED: "このフィールドを入力してください",
    REQUIRED_2: "{item_name}は必ず入力してください",
    INVALID_EMAIL: "入力されたものはメールアドレスとして不正です",
    INVALID_NUMBER: "入力されたものは電話番号として不正です",
    TEXT_MAX_LENGTH: "文字数は500文字を超えないこと",
    TEXT_MAX_LENGTH_50: "50文字以内に入力してください",
    TEXT_MAX_LENGTH_800: "800文字以内に入力してください",
    FORM_NAME_REQUIRED: "タイトルを入力してください",
    FORM_NAME_MAX_LENGTH: "タイトルは50文字以内に入力してください",
    FORM_DESC_REQUIRED: "説明を入力してください",
    FORM_DESC_MAX_LENGTH: "説明は255文字以内に入力してください",
    FORM_TAG_REQUIRED: "タグを入力してください",
    FORM_CALENDARS_REQUIRED: "日程調整を行うカレンダーを選択してください",
    FORM_APPS_ATTRIBUTES: "アポを入れる曜日を選択してください",
    MAIL_THANKS: "予約完了のお礼メールを設定してください",
    MAIL_REMIND: "アポ前日のお知らせメールを設定してください",
    INVALID_TIME_APPOINTMENT: "アポを入れる時間の設定範囲が正しくありません",
    INVALID_PEROID_TIME_APPOINTMENT: "アポを予約できる期間の設定範囲は正しくないです",
    INVALID_BREAK_TIME: "休憩時間を修正してください",
    INVALID_TIME_BOOK: "アポを予約できる期間の設定範囲は正しくないです",
    SETTING_DURATION_OF_ONE_APP_LESS_THAN_RANGE_ADDITIONAL_APP: "1回のアポにかかる時間はアポを入れる時間範囲より少なめに設定してください",
    FORM_ITEM_NAME_REQUIRED: "追加された項目に項目名を入力してください",
    FORM_ITEM_NAME_DUPLICATE: "項目名が重複しています",
    FORM_ITEM_NAME_DUPLICATE_WITH_MY_NAME: "自分の名前は追加できません ",
    FORM_ITEM_NAME_DUPLICATE_WITH_MY_COMPANY: "自分の企業名は追加できません",
    FORM_ITEM_NAME_DUPLICATE_WITH_MY_EMAIL: "自分のEmailは追加できません ",
    FORM_ITEM_NAME_MAX_LENGTH: "項目名は50文字以内に入力してください",
    APPOINTMENT_TIME_MUST_DIFFERENT_ZERO: "一回アポにかかる時間が0より大きい",
    MAX_CALENDAR: `日程調整を行うカレンダーの設定できる数は${env.LIMIT_CALENDAR}以内にしてください`,
    SUBJECT_EMAIL_REQUIRE: "件名を入力してください",
    BODY_EMAIL_REQUIRE: "本文を入力してください",
    SUBJECT_EMAIL_MAX_LENGTH: "件名は50文字以内に入力してください",
    BODY_EMAIL_MAX_LENGTH: "本文は800文字以内に入力してください",
    YOUR_TIME_NOT_VALID: "Your time not valid, ",
    Booking: {
      REQUIRED: "{item_name}を入力してください",
      INVALID_EMAIL: "メールアドレスは正しく入力してください",
      NUMBER_WITH_DASH: "{item_name}はハイフンなしで正しく入力してください",
      INVALID_NUMBER: "{item_name}は半角数字で入力してください",
      TEXT_MAX_LENGTH: "{item_name}は500文字以内に入力してください",
      TEXT_MAX_LENGTH_50: "{item_name}は50文字以内に入力してください",
    },
    CREATE_FORM_ERROR_VALIDATE: "入力内容に誤りがあります。ご確認の上、再度入力してください。",
    TEXT_MAX_LENGTH_1900: "1900文字以内で入力してください",
    CONTRACT_PERIOD_DATE: '下記項目の入力内容に誤りがあります',
    INPUT_ERROR: '入力内容に誤りがあります',
    CREDIT: {
      REGIST_USER_NAME_REQUIRE: '名義人を入力してください',
      REGIST_USER_NAME_MAX_LENGTH: '名義人は255文字以内で入力してください',
    },
    BANK_INVOICE: {
      REGIST_MAIL_ADDRESS_TYPE: '半角英数字記号のみで入力してください',
      REGIST_ACCOUNT_NUM_TYPE: '半角数字で入力してください',
      REGIST_ACCOUNT_NUM_MIN: '0以上で入力してください',
      REGIST_ACCOUNT_NUM_MAX: '2147483647より小さい値で入力してください'
    }
  },
  Calendar: {
    GET_ERROR: "レコードが見つかりません",
    GET_DAY_FAILED: "対象日付には予約できる日程がありません",
    CHOSE_A_DATE: "カレンダーから日付を選ぶ"
  },
  Contract: {
    CANCEL_SUCCESS: "解約されました。\nアカウントは翌月停止されます。ご利用ありがとうございました。",
    CANCEL_FAILED: "解約に失敗しました。"
  },
  Regist: {
    REGIST_FAILED: "登録に失敗しました。",
    EXPIRE_DAY_ALERT: "契約終了日まで1か月以上ありますが、プランを変更しなおしますか?"
  }
};
