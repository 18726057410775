import { Action } from "app/models/redux/action";
import {
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  LOGIN_CANCELLED,
  FETCH_CURRENT_USER_SUCCESS,
  FETCH_CURRENT_USER_FAILED,
  FETCH_PERMISSIONS_SUCCESS,
  FETCH_PERMISSIONS_FAILED,
  LOGOUT_SUCCESS,
  LOGIN_GOOGLE_SUCCESS,
  LOGIN_GOOGLE_FAILED,
  LOGIN_GOOGLE_CANCELLED,
  REFRESH_TOKEN_SUCCESS,
  REFRESH_TOKEN_FAILED,
} from "./actions";

const initState = {
  user: null,
  user_type: null,
  token: null,
  error: null,
  permissions: [],
  actionType: "",
};

/**
 * Auth Reducer
 * @param state 
 * @param action 
 */
export const authReducer = (state: any = initState, action: Action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
    case LOGIN_GOOGLE_SUCCESS:
      return {
        ...state,
        actionType: action.type,
        token: action.payload.token,
        expires_at: action.payload.expires_at,
        refresh_token: action.payload.refresh_token,
      };
    case LOGIN_FAILED:
    case LOGIN_GOOGLE_FAILED:
      return {
        user: null,
        token: null,
        permissions: [],
        actionType: action.type,
        error: action.payload,
      };
    case LOGIN_CANCELLED:
    case LOGIN_GOOGLE_CANCELLED:
    case LOGOUT_SUCCESS:
      return {
        user: null,
        token: null,
        permissions: [],
      };
    case FETCH_CURRENT_USER_SUCCESS:
      return {
        ...state,
        user: action.payload,
      };
    case FETCH_CURRENT_USER_FAILED:
      return {
        ...state,
        user: null,
        error: action.payload,
      };
    case FETCH_PERMISSIONS_SUCCESS:
      return {
        ...state,
        permissions: action.payload.permissions,
      };
    case FETCH_PERMISSIONS_FAILED:
      return {
        ...state,
        error: action.payload,
        permissions: [],
      };
    case REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        actionType: action.type,
        token: action.payload.token,
        expires_at: action.payload.expires_at,
      };
    case REFRESH_TOKEN_FAILED:
      return {
        user: null,
        token: null,
        permissions: [],
        actionType: action.type,
        error: action.payload,
      };

    default:
      return state;
  }
};

/**
 * Auth Refresh Reducer
 * @param state 
 * @param action 
 */
export const authRefreshReducer = (state: any = initState, action: Action) => {
  switch (action.type) {
    default:
      return state;
  }
};
