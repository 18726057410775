import { ActionsObservable, ofType } from "redux-observable";
import { map, catchError, takeUntil, mergeMap } from "rxjs/operators";
import { of } from "rxjs";
import { Action } from "app/models/redux/action";
import { onEpicSuccess, onEpicFailed } from "app/helpers/reduxEpic";
import { CANCEL_ALL_REQUEST } from "app/redux/default";
import { BookingService } from "app/services/booking";
import * as Actions from "./actions";

/**
 * Watch action type and request to create calendar
 * @param action$ 
 */
export const fetchCreateBookingCalendarEpic = (action$: ActionsObservable<Action>) =>
  action$.pipe(
    ofType(Actions.CREATE_BOOKING_CALENDAR),
    mergeMap((action) =>
      BookingService.createBookingCalendar(action.payload.formId, action.payload.body).pipe(
        map((ajaxResponse) => {
          const { response } = ajaxResponse;
          if (response) {
            return onEpicSuccess(
              action,
              ajaxResponse,
              Actions.getCalendarByFormIdSuccess(response),
              true
            );
          }
        }),
        catchError((error) => {
          return of(
            onEpicFailed(action, error, Actions.getCalendarByFormIdFailed(error), true)
          );
        }),
        takeUntil(
          action$.pipe(
            ofType(Actions.CREATE_BOOKING_CALENDAR_CANCELLED, CANCEL_ALL_REQUEST)
          )
        )
      )
    )
  );

/**
 * Watch action type and request to fetch list calendar
 * @param action$ 
 */
export const fetchCalendarFormByIdEpic = (action$: ActionsObservable<Action>) =>
  action$.pipe(
    ofType(Actions.GET_BOOKING_CALENDAR_BY_ID),
    mergeMap((action) =>
      BookingService.getCalendarFormById(action.payload.formId).pipe(
        map((ajaxResponse) => {
          const { response: { data } } = ajaxResponse;
          if (data) {
            return onEpicSuccess(
              action,
              ajaxResponse,
              Actions.getCalendarByFormIdSuccess(data),
              true
            );
          }
        }),
        catchError((error) => {
          return of(
            onEpicFailed(action, error, Actions.getCalendarByFormIdFailed(error), true)
          );
        }),
        takeUntil(
          action$.pipe(
            ofType(Actions.GET_BOOKING_CALENDAR_BY_ID_CANCELLED, CANCEL_ALL_REQUEST)
          )
        )
      )
    )
  );