import { RoutePaths } from 'app/constants';
import { FOOTER_DESC } from "app/constants/constants";
import React, { FunctionComponent } from 'react';
import { useLocation } from 'react-router-dom';
import './style.scss';

interface Props {
	className?: string;
	isShowMenu?: boolean
}

/**
 * Component Footer
 */
export const Footer: FunctionComponent<Props> = ({ className, isShowMenu }) => {
	const location = useLocation();
	let checkPathSetting = location.pathname.indexOf(RoutePaths.Setting.Index) !== -1;
	let checkPathAdmin = location.pathname.indexOf(RoutePaths.Admin.Index) !== -1;
	let checkNotFound = location.pathname.indexOf(RoutePaths.NotFound) !== -1;
	let checkBookingPath = location.pathname.indexOf('/booking/') !== -1;

	return (
		<>
			<div className="space-element"></div>
			<footer className={`box-shadow main-footer
			${isShowMenu
					&& !checkNotFound
					&& window.innerWidth <= 768
					&& !checkBookingPath
					? 'has-bottom-nav' : ''}
			${checkPathSetting || checkPathAdmin
					? 'footer-setting'
					: 'base-footer'}
					`}>
				<p>{` ${FOOTER_DESC}`}</p>
				{isShowMenu
					&& !checkNotFound
					&& window.innerWidth <= 768
					&& !checkBookingPath
					&& <p className="invisible-text">1</p>}
			</footer>
		</>
	);
};
