import { CHARACTERS } from "app/constants";
import { isEqual } from "lodash";
import { Messages } from 'app/constants';
const moment = require("moment")

export * from "./url";
export * from "./pagination";
export * from "./asyncLoadHelper";
export * from "./notify/notify";

const AJAX_ERROR = "AjaxError"
const AJAX_TIMEOUT_ERROR = "AjaxTimeoutError"

/**
 * Make id
 * @param length 
 */
export function makeid(length: number) {
  var result = "";

  var charactersLength = CHARACTERS.length;
  for (var i = 0; i < length; i++) {
    result += CHARACTERS.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

/**
 * Get common message error
 * @param error 
 * @param messageDefault 
 */
export const getCommonMessageError = (error: any, messageDefault: string = Messages.Common.EXCEPTION_DEFAULT) => {
  return error?.response?.error?.message || messageDefault;
}

/**
 * Get common message success
 * @param body 
 * @param messageDefault 
 */
export const getCommonMessageSuccess = (body: any, messageDefault: string = Messages.Common.SUCCESS_DEFAULT) => {
  return body?.response?.message || messageDefault;
}

/**
 * Get error message
 * @param error 
 * @param messageDefault 
 */
export const getErrorMessage = (error: any, messageDefault: string = Messages.Common.EXCEPTION_DEFAULT) => {
  if (error?.name === AJAX_ERROR || error?.name === AJAX_TIMEOUT_ERROR) {
    return getCommonMessageError(error, messageDefault)
  }
  return error?.response?.message || error?.response?.error?.details?.[0]?.messages?.join(',') || error?.response?.error?.message || error?.message;
}

/**
 * Get error message api
 * @param error 
 * @param defaultMessage 
 */
export const getErrorMessageApi = (error: any, defaultMessage: any = Messages.Common.EXCEPTION_DEFAULT) => {
  if (error?.name === AJAX_ERROR || error?.name === AJAX_TIMEOUT_ERROR) {
    return getCommonMessageError(error, defaultMessage)
  }
  return error?.response?.message
    || error?.response?.error?.message
    || error?.response?.error?.details?.[0]?.messages?.join(',')
    || defaultMessage
}

/**
 * Get date now
 * @param dateArg 
 */
export const getDateNow = (dateArg: any = '') => {
  const dateNow = dateArg ? new Date(dateArg) : new Date();
  const date = dateNow.getDate();
  const month = dateNow.getMonth() + 1;
  const year = dateNow.getFullYear();
  return { date, month, year, fullDateTime: dateNow }
}

/**
 * Suppress non numeric input
 * @param event 
 */
export const suppressNonNumericInput = (event: any) => {
  const key = event.which || event.keyCode;
  const ctrl =
    event.ctrlKey || event.metaKey ? event.ctrlKey || event.metaKey : key === 17 || key === 91
  // metaKey support for Mac OS
  if (
    !(
      key === 9 || // Tab
      key === 13 ||
      (key === 65 && ctrl) || // Ctrl + A
      (key === 86 && ctrl) || // Ctrl + V
      (key === 67 && ctrl) || // Ctrl + C
      key === 8 || // backspace
      key === 46 || // delete
      (key >= 35 && key <= 40) || // arrow keys/home/end
      (key >= 48 && key <= 57) || // numbers on keyboard
      (key >= 96 && key <= 105)
    ) // number on keypad
  ) {
    event.preventDefault(); // Prevent character input
  }
};

/**
 * Check has duplicate item
 * @param list 
 */
export const hasDuplicateItem = (list: any[]) => {
  return list && typeof list === 'object' && list.filter((el, index) => !isEqual(list.indexOf(el), index)).length > 0;
}

/**
 * Filter duplicate item
 * @param list 
 */
export const filterDuplicateItem = (list: any[]) => {
  return list.filter((el, index) => !isEqual(list.indexOf(el), index));
}

/**
 * Get first item in array
 * @param arr 
 */
export const getFirst = (arr: any[]) => {
  return arr?.length > 0 ? arr[0] : undefined
}

/**
 * Get last item in array
 * @param arr 
 */
export const getLast = (arr: any[]) => {
  return arr?.length > 0 ? arr[arr.length - 1] : undefined
}

/**
 * Format data
 * @param format 
 * @param data 
 */
export const formatData = (format: string, ...data: any) => {
  let result = format;
  data.forEach((elm: any, index: number) => {
    result = result.replace(`{${index}}`, `${elm ?? ""}`)
  })
  return result ?? "";
}

/**
 * Get minutes between firstTime and secondsTime
 * @param firstTime 
 * @param secondsTime 
 */
export const getBetweenTime = (firstTime: string, secondsTime: string) => {
  let first = moment(firstTime, "hh:mm");
  let seconds = moment(secondsTime, "hh:mm");
  let betWeen = first.hour() * 60 + first.minutes() - (seconds.hour() * 60 + seconds.minutes())
  if (!first.isAfter(seconds)) {
    return -betWeen;
  }
  return betWeen;
}

/**
 * Check event is press enter key
 * @param event 
 */
export const checkHasPressEnterKey = (event: any) => {
  if (event.keyCode === 13 || event.keyCode === 32) {
    return true
  }
  return false
}

/**
 * Convert date to year-month-day string
 * @param date 
 */
export const dateToYearMonthDay = (date: Date) => {
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return `${date.getFullYear()}-${month > 9 ? month : "0" + month}-${day > 9 ? day : "0" + day}`
}

/**
 * Convert date to year/month/day string
 * @param date 
 */
export const dateToYearMonthDay2 = (date: Date) => {
  return dateToYearMonthDay(date).replace(/-/g, "/")
}

const NUM_FULL_WIDTH = ["０", "１", "２", "３", "４", "５", "６", "７", "８", "９"]

/**
 * Convert number full-width to number half-width
 * @param input 
 */
export const convertFullWidthToHalfWidth = (input: string = "") => {
  if (!input) {
    return ""
  }
  return input.split('').map((char: any) => {
    if (NUM_FULL_WIDTH.indexOf(char) != -1) {
      return char.replace(/[\uFF10-\uFF19]/g, (m: any) => {
        return String.fromCharCode(m.charCodeAt(0) - 0xfee0);
      });
    }
    return char
  }).join("")
}

export const changeTitlePages = (title: string) => {
  document.title = title;
}
