import BookingFormIconActive from 'assets/images/booking-form-icon-active.png';
import BookingFormIcon from 'assets/images/booking-form-icon.png';
import BookingTimelineIconActive from 'assets/images/booking-timeline-icon-active.png';
import BookingTimelineIcon from 'assets/images/booking-timeline-icon.png';
import TagIconActive from 'assets/images/tag-icon-active.png';
import TagIcon from 'assets/images/tag-icon.png';
import SettingIconActive from 'assets/images/setting-icon-active.png';
import SettingIcon from 'assets/images/setting-icon.png';

import { RoutePaths } from 'app/constants/routes';

// Properties:Value property [ForScreen] is 1 for all screen, is 2 for PC screen, is 3 for mobile screen
export const Menus = [
    {
        Router: RoutePaths.BookingForm.Index,
        Name: "予約フォーム",
        Icon:BookingFormIcon,
        IconActive: BookingFormIconActive,
        ActiveOnlyWhenExact: true,
        ForScreen: 1
    },
    {
        Router: RoutePaths.Booking.Index,
        Name: "予約一覧",
        Icon:BookingTimelineIcon,
        IconActive: BookingTimelineIconActive,
        ActiveOnlyWhenExact: false,
        ForScreen: 1
    },
    {
        Router: RoutePaths.Tag.Index,
        Name: "タグ",
        Icon:TagIcon,
        IconActive: TagIconActive,
        ActiveOnlyWhenExact: false,
        ForScreen: 1
    },
    {
        Router: RoutePaths.Setting.Index,
        Name: "各種設定",
        Icon:SettingIcon,
        IconActive: SettingIconActive,
        ActiveOnlyWhenExact: false,
        ForScreen: 3
    }

];
