import { ActionsObservable, ofType } from "redux-observable";
import { map, catchError, mergeMap } from "rxjs/operators";
import { of } from "rxjs";
import { Action } from "app/models/redux/action";
import { onEpicSuccess, onEpicFailed } from "app/helpers/reduxEpic";
// import { ContractService } from "app/services/contract";
import * as Actions from "./actions";
import { RegistService } from "app/services/regist";

/**
 * Watch action type and request to fetch payment information
 * @param action$
 */
export const feachPaymentInformationEpic = (action$: ActionsObservable<Action>) =>
    action$.pipe(
        ofType(Actions.GET_PAYMENT_INFORMATION),
        mergeMap((action) =>
            RegistService.getPaymentInformation().pipe(
                map((ajaxResponse) => {
                    const { response } = ajaxResponse;
                    const data = response.data;
                    if (data) {
                        return onEpicSuccess(
                            action,
                            data,
                            Actions.getPaymentInformationSuccess(data)
                        );
                    }
                }),
                catchError((error) => {
                    return of(
                        onEpicFailed(action, error, Actions.getPaymentInformationFailed(error))
                    );
                })
            )
        )
    );

export const mergeUserPaymentEpic = (action$: ActionsObservable<Action>) =>
    action$.pipe(
        ofType(Actions.MERGE_USER_PAYMENT),
        mergeMap((action) =>
            RegistService.mergeUserPaymentRequest(action.payload.body).pipe(
                map((ajaxResponse) => {
                    const { response } = ajaxResponse;
                    const { status } = response;
                    if (status) {
                        return onEpicSuccess(
                            action,
                            ajaxResponse,
                            Actions.mergeUserPaymentSuccess(response)
                        );
                    }
                }),
                catchError((error) => {
                    return of(
                        onEpicFailed(action, error, Actions.mergeUserPaymentFailed(error))
                    );
                })
            )
        )
    );

/**
  * create New Bank Payment Transfer Epic
  * @param action$ 
  * @returns 
*/
export const createNewBankPaymentTransferEpic = (action$: ActionsObservable<Action>) =>
    action$.pipe(
        ofType(Actions.CREATE_NEW_BANK_PAYMENT),
        mergeMap((action) =>
            RegistService.createNewBankPaymentTransfer(action.payload.body).pipe(
                map((ajaxResponse) => {
                    const { response } = ajaxResponse;
                    if (response) {
                        return onEpicSuccess(action, response, Actions.createNewBankPaymentTransferSuccess(response));
                    }
                }),
                catchError((error) => {
                    return of(
                        onEpicFailed(action, error, Actions.createNewBankPaymentTransferFailed(error))
                    )
                })
            )
        )
    );

/**
 * update only payment plan epic
 * @param action$ 
 * @returns 
 */
export const updateOnlyPaymentPlanEpic = (action$: ActionsObservable<Action>) =>
    action$.pipe(
        ofType(Actions.UPDATE_ONLY_PAYMENT_PLAN),
        mergeMap((action) =>
            RegistService.updateOnlyPaymentPlanRequest(action.payload.body).pipe(
                map((ajaxResponse) => {
                    const { response } = ajaxResponse;
                    if (response) {
                        return onEpicSuccess(action, response, Actions.updateOnlyPaymentPlanSucess(response));
                    }
                }),
                catchError((error) => {
                    return of(
                        onEpicFailed(action, error, Actions.updateOnlyPaymentPlanFailed(error))
                    );
                })
            )
        )
    );

/**
 * update with Credit Card Infomation epic
 */
export const updatePaymentWithCreditCardEpic = (action$: ActionsObservable<Action>) =>
    action$.pipe(
        ofType(Actions.UPDATE_PAYMENT_WITH_CREDITCARD),
        mergeMap((action) =>
            RegistService.updatePaymentWithCreditCard(action.payload.body).pipe(
                map((ajaxResponse) => {
                    const { response } = ajaxResponse;
                    if (response) {
                        return onEpicSuccess(action, response, Actions.updatePaymentWithCreditCardSuccess(response));
                    }
                }),
                catchError((error) => {
                    return of(
                        onEpicFailed(action, error, Actions.updatePaymentWithCreditCardFailed(error))
                    )
                })
            ))
    );

/**
 * change payment to bank epic
 */
export const changePaymentToBankEpic = (action$: ActionsObservable<Action>) =>
    action$.pipe(
        ofType(Actions.CHANGE_PAYMENT_TO_BANK),
        mergeMap((action) =>
            RegistService.changePaymentToBank(action.payload.body).pipe(
                map((ajaxResponse) => {
                    const { response } = ajaxResponse;
                    if (response) {
                        return onEpicSuccess(action, response, Actions.changePaymentToBankSuccess(response));
                    }
                }),
                catchError((error) => {
                    return of(
                        onEpicFailed(action, error, Actions.changePaymentToBankFailed(error))
                    )
                })
            )
        )
    );