import React, { FunctionComponent } from 'react';
import { NavLink } from 'react-router-dom';
import { Images } from "assets/iconListing";
import { Footer } from 'app/components/Common/Footer/Footer';
import { TitleOrButton } from 'app/constants';

interface AuthLayoutProps {}

// Component AuthLayout
export const AuthLayout: FunctionComponent<AuthLayoutProps> = ({ children }) => {
	return (
		<>
			<header className={`header text-center`}>
				<NavLink to="/" exact>
					<img alt={TitleOrButton.Description.LOGO_DES} src={Images.Logo} className="logo" />
				</NavLink>
			</header>

			<main>
				<div className="flex-row-fluid d-flex flex-column position-relative p-7 overflow-hidden">
					{/* begin::Content body */}
					<div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">{children}</div>
					{/*end::Content body*/}
				</div>
			</main>

			<Footer className="login-footer"/>
		</>
	);
};
