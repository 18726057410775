import { useEffect } from 'react';

/**
 * Add effects to button
 */
export default (ref: any) => {
  return useEffect(() => {
    if (ref && ref.current) {
      ref.current.addEventListener("click", function (e: any) {
        // get x, y of button
        const rect = e.target.getBoundingClientRect();
        let x = e.clientX - rect.left;
        let y = e.clientY - rect.top;

        // create new element
        const newElement = document.createElement("DIV");
        newElement.className = "dzb-overlay-btn";
        newElement.style.left = `${x}px`;
        newElement.style.top = `${y}px`;
        ref.current.appendChild(newElement);

        // after 1s remove overlay
        // number second must be equal second in class .dzb-overlay-btn (check in /styles/scss/Main.scss)
        setTimeout(() => {
          newElement.remove();
        }, 600);
      });
    }
  }, [ref]);
}