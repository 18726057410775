import { Action } from "../models/redux/action";
import { ListState, ListTagColorState, TagDetail } from "../models/redux/listState";
import { ContractInfo } from "../models/redux/ContractInfo";
import { DEFAULT_PAGE_SIZE } from "../constants/pagination";

/**
 * Action Fetch list state
 * @param action
 */
export const fetchListState = (action: Action) => {
	return {
		list: [],
		loading: true,
		actionType: "",
		...action.payload,
	};
};

/**
 * Action Base fetch list state
 */
export const baseFetchListState: <T>() => ListState<T> = () => ({
	list: [],
	query: {
		per_page: DEFAULT_PAGE_SIZE,
		page: 1,
		filter: {},
	},
	totalElements: 0,
	actionType: "",
	error: null,
	loading: false,
});

/**
 * Action Fetch list state success
 * @param action
 * @param state
 */
export const fetchListStateSuccess = (action: Action, state: ListState) => {
	if (action?.payload) {
		for (let key of action.payload) {
			for (let x in key) {
				if (key[x] === null) {
					key[x] = "";
				}
				if (x === "agency") {
					key["isAgency"] = `${key[x]}`;
					delete key.agency;
				}
			}
		}
		return {
			...state,
			actionType: action.type,
			list: [...action.payload],
			totalElements: action.payload.totalElements,
			loading: false,
		};
	}
	return {
		...state,
		list: [...action.payload],
		loading: false,
	};
};

/**
 * Action Fetch list state failed
 * @param action
 * @param state
 */
export const fetchListStateFailed = (action: Action, state: ListState) => {
	return {
		...state,
		list: [],
		loading: false,
		actionType: action.type,
		error: action.payload,
	};
};

/**
 * Action Base fetch detail state
 */
export const baseFetchDetailState = () => ({
	loading: false,
	data: {},
});

/**
 * Action Fetch detail state
 * @param action
 * @param state
 */
export const fetchDetailState = (action: Action, state: ListState) => {
	return {
		...state,
		actionType: action.type,
		loading: true,
	}
}

/**
 * Action Fetch detail state success
 * @param action
 * @param state
 */
export const fetchDetailStateSuccess = (action: Action, state: ListState) => {
	return {
		...state,
		actionType: action.type,
		data: action.payload,
		loading: false,
	};
};

/**
 * Action Fetch detail state failed
 * @param action
 * @param state
 */
export const fetchDetailStateFailed = (action: Action, state: ListState) => {
	return {
		...state,
		loading: false,
		actionType: action.type,
		error: action.payload,
	};
};

/**
 * Action init Add
 * @param action
 * @param state
 */
export const addInit = (action: Action, state: ListState) => {
	return {
		...state,
		loading: true,
	};
};

/**
 * Action Add success
 * @param action
 * @param state
 */
export const addSuccess = (action: Action, state: ListState) => {
	return {
		...state,
		loading: false,
	};
};

/**
 * Action Add failed
 * @param action
 * @param state
 */
export const addFailed = (action: Action, state: ListState) => {
	return {
		...state,
		loading: false,
		error: action.payload,
	};
};

/**
 * Action init Edit
 * @param action
 * @param state
 */
export const editInit = (action: Action, state: ListState) => {
	return {
		...state,
		loading: true,
	};
};

/**
 * Action Edit success
 * @param action
 * @param state
 */
export const editSuccess = (action: Action, state: ListState) => {
	return {
		...state,
		loading: false,
	};
};

/**
 * Action Edit Failed
 * @param action
 * @param state
 */
export const editFailed = (action: Action, state: ListState) => {
	return {
		...state,
		loading: false,
		error: action.payload,
	};
};

/**
 * Action init Delete
 * @param action
 * @param state
 */
export const deleteInit = (action: Action, state: ListState) => {
	return {
		...state,
		loading: true,
	};
};

/**
 * Action Delete successs
 * @param action
 * @param state
 */
export const deleteSuccess = (action: Action, state: ListState) => {
	return {
		...state,
		loading: false,
	};
};

/**
 * Action Delete Failed
 * @param action
 * @param state
 */
export const deleteFailed = (action: Action, state: ListState) => {
	return {
		...state,
		loading: false,
		error: action.payload,
	};
};

/**
 * Action init AddShipSchedule
 * @param action
 * @param state
 */
export const addShipScheduleInit = (action: Action, state: ListState) => {
	return {
		...state,
		loading: true,
	};
};

/**
 * Action AddShipSchedule success
 * @param action
 * @param state
 */
export const addShipScheduleSuccess = (action: Action, state: ListState) => {
	return {
		...state,
		loading: false,
	};
};

/**
 * Action AddShipSchedule Failed
 * @param action
 * @param state
 */
export const addShipScheduleFailed = (action: Action, state: ListState) => {
	return {
		...state,
		loading: false,
		error: action.payload,
	};
};

/**
 * Action init DeleteShipSchedule
 * @param action
 * @param state
 */
export const deleteShipScheduleInit = (action: Action, state: ListState) => {
	return {
		...state,
		loading: true,
	};
};

/**
 * Action DeleteShipSchedule Success
 * @param action
 * @param state
 */
export const deleteShipScheduleSuccess = (action: Action, state: ListState) => {
	return {
		...state,
		loading: false,
	};
};

/**
 * Action DeleteShipSchedule failed
 * @param action
 * @param state
 */
export const deleteShipScheduleFailed = (action: Action, state: ListState) => {
	return {
		...state,
		loading: false,
		error: action.payload,
	};
};

/**
 * Action get Tag's Colors
 * @param action
 * @param state
 */
export const getTagColors = (action: Action, state: ListTagColorState) => {
	return {
		...state,
		loading: true,
	}
}

/**
 * Action get Tag's Colors success
 * @param action
 * @param state
 */
export const getTagColorsSuccess = (action: Action, state: ListTagColorState) => {
	return {
		...state,
		loading: false,
		data: action.payload,
	}
}

/**
 * Action get Tag's Colors failed
 * @param action
 * @param state
 */
export const getTagColorsFailed = (action: Action, state: ListTagColorState) => {
	return {
		...state,
		loading: false,
		error: action.payload
	}
}

/**
 * Action get Tag's Detail
 * @param action
 * @param state
 */
export const fetchTagDetail = (action: Action, state: TagDetail) => {
	return {
		...state,
		loading: true,
	}
}

/**
 * Action get Tag's Detail success
 * @param action
 * @param state
 */
export const fetchTagDetailSuccess = (action: Action, state: TagDetail) => {
	return {
		...state,
		loading: false,
		data: action.payload
	}
}

/**
 * Action get Tag's Detail failed
 * @param action
 * @param state
 */
export const fetchTagDetailFailed = (action: Action, state: TagDetail) => ({
	...state,
	loading: false,
	error: action.payload
})

/**
 * Action init Booking Calendar
 */
export const baseBookingCalendar = () => {
	return {
		detail: {},
		completeForm: {},
		listBookingCalendar: [],
	};
}

/**
 * Action set Booking Calendar
 * @param action
 * @param state
 */
export const setBookingCalendar = (action: Action, state: any) => ({
	...state,
	detail: action.payload,
})

/**
 * Action clear Booking Calendar
 * @param action
 * @param state
 */
export const clearBookingCalendar = (action: Action, state: any) => {
	return {
		...state,
		detail: {}
	};
}

/**
 * Action set Booking Calendar complete
 * @param action
 * @param state
 */
export const setBookingCalendarComplete = (action: Action, state: any) => ({
	...state,
	completeForm: action.payload
});

/**
 * Action clear Booking Calendar complete
 * @param action
 * @param state
 */
export const clearBookingCalendarComplete = (action: Action, state: any) => ({
	...state,
	completeForm: {}
});

/**
 * Action get User Contract
 * @param action
 * @param state
 * @returns
 */
export const getUserContract = (action: Action, state: ContractInfo) => {
	return {
		...state,
		loading: true
	}
};
/**
 * Action get User Contract success
 * @param action
 * @param state
 */
export const getUserContractSuccess = (action: Action, state: ContractInfo) => {
	return {
		...state,
		loading: false,
		data: action.payload
	}
};

/**
 * Action get User Contract failed
 * @param action
 * @param state
 * @returns
 */
export const getUserContractFailed = (action: Action, state: ContractInfo) => {
	return {
		...state,
		loading: false,
		error: action.payload
	}
};

/**
 * Action cancel User Contract
 * @param action
 * @param state
 * @returns
 */
export const cancelUserContract = (action: Action, state: ContractInfo) => {
	return {
		...state,
		loading: true
	}
};

/**
 * Action cancel user contract success
 * @param action
 * @param state
 */
export const cancelUserContractSuccess = (action: Action, state: ContractInfo) => {
	return {
		...state,
		loading: false,
	};
};

/**
 * Action cancel user contract failed
 * @param action
 * @param state
 */
export const cancelUserContractFailed = (action: Action, state: ContractInfo) => {
	return {
		...state,
		loading: false,
		error: action.payload,
	};
};

/**
 * Action set payment plan information
 * @param action
 * @param state
 */
export const setPaymentPlanInformation = (action: Action, state: any) => (
	action.payload
);

/**
 * Action set payment plan information
 * @param action
 * @param state
 */
export const setPaymentInformation = (action: Action, state: any) => (
	action.payload
);

/**
 *  Action new Bank Payment Transfer
 * @param action 
 * @param state 
 * @returns 
 */
export const createNewBankPaymentTransfer = (acion: Action, state: any) => {
	return {
		...state
	}
};

/**
 * Action new Bank Payment Transfer Success
 * @param action 
 * @param state 
 * @returns 
 */
export const createNewBankPaymentTransferSuccess = (action: Action, state: any) => {
	return {
		...state
	}
}

/**
 * Action new Bank Payment Transfer Failed
 * @param action 
 * @param state 
 * @returns 
 */
export const createNewBankPaymentTransferFailed = (action: Action, state: any) => {
	return {
		...state,
		error: action.payload
	}
};

/**
 * Action update only payment plan
 * @param action 
 * @param state 
 * @returns 
 */
export const updateOnlyPaymentPlan = (action: Action, state: any) => {
	return {
		...state
	}
};

/**
 * Action update only payment plan success
 * @param action 
 * @param state 
 * @returns 
 */
export const updateOnlyPaymentPlanSucess = (action: Action, state: any) => {
	return {
		...state
	}
};

/**
 * Action update only payment plan failed
 * @param action
 * @param state
 */
export const updateOnlyPaymentPlanFailed = (action: Action, state: any) => {
	return {
		...state,
		error: action.payload,
	};
};

/**
 * Action update payment with credit card infomation
 * @param action 
 * @param state 
 * @returns 
 */
export const updatePaymentWithCreditCard = (action: Action, state: any) => {
	return {
		...state
	};
};

/**
 * Action update payment with credit card infomation Success
 * @param action 
 * @param state 
 * @returns 
 */
export const updatePaymentWithCreditCardSuccess = (action: Action, state: any) => {
	return {
		...state
	}
};

/**
 * Action update payment with credit card infomation Failed
 * @param action 
 * @param state 
 * @returns 
 */
export const updatePaymentWithCreditCardFailed = (action: Action, state: any) => {
	return {
		...state,
		error: action.payload
	}
};

/**
 * Action change payment to bank
 * @param action 
 * @param state 
 * @returns 
 */
export const changePaymentToBank = (action: Action, state: any) => {
	return {
		...state
	}
};

/**
 * Action change payment to bank Success
 * @param action 
 * @param state 
 * @returns 
 */
export const changePaymentToBankSuccess = (action: Action, state: any) => {
	return {
		...state
	}
};

/**
 * Action change payment to bank Failed
 * @param action 
 * @param state 
 * @returns 
 */
export const changePaymentToBankFailed = (action: Action, state: any) => {
	return {
		...state,
		error: action.payload
	}
};