import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { RoutePaths } from '../constants/routes';

/**
 * Component PrivateRouteAdmin
 * @param props 
 */
const PrivateRouteAdmin = (props: any) => {
	const loginAdminData = useSelector((state: any) => state.loginAdmin);
	if (!!loginAdminData?.access_token) {
		return <Route {...props} />;
	}
	return <Redirect to={RoutePaths.Admin.LoginAdmin} />;
};

export default PrivateRouteAdmin;
