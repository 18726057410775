import env from 'app/helpers/env';
import { DashboardLayout } from "app/layouts/DashboardLayout";
import moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { RoutePaths } from '../constants/routes';
import { User } from '../models/User';
import { fetchUser } from '../redux/modules/auth';
import { AuthPages } from './auth';
import PrivateRoute from './PrivateRoutes';
import PrivateRouteAdmin from './PrivateRoutesAdmin';
import { BaseRoutes } from './routes';

interface RoutesProps {
	basename: string;
	fetchUser: () => void;
	expires_at: string;
	token: string;
	user: User | null;
	openPopup?: boolean;
}

const { INTRO_HOME_PAGE } = env
const NotFound = React.lazy(() => import('../pages/notfound/NotFound'));
const AccessDenied = React.lazy(() => import('../pages/AccessDenied'));
const Calendar = React.lazy(() => import("app/pages/booking/calendarAdjust/Calendar"));
const CreateBooking = React.lazy(() => import("app/pages/booking/createItem/CreateItemBooking"));
const CompleteBooking = React.lazy(() => import('app/pages/booking/completeBooking'));
const LoginAdmin = React.lazy(() => import('app/pages/admin/login/index'));
const Report = React.lazy(() => import('app/pages/admin/report'));

const mapStateToProps = (state: any) => ({
	user: state.auth.user,
	expires_at: state.auth.expires_at,
	token: state.auth.token,
	openPopup: state.modal.openModal
});

const mapDispatchToProps = {
	fetchUser,
};

/**
 * Component Routes
 */
export const Routes = connect(
	mapStateToProps,
	mapDispatchToProps
)(({ basename, fetchUser, user, expires_at, token, openPopup }: RoutesProps) => {

	React.useEffect(() => {
		if (moment().isBefore(moment(expires_at)) && token) {
			fetchUser();
		}
	}, [fetchUser]);

	const RedirectToInfoHomePage = () => {
		if (!token && !moment().isBefore(moment(expires_at))) window.location.href = INTRO_HOME_PAGE
		return <Redirect to={RoutePaths.BookingForm.Index} />
	}

	return (

		<Router basename={basename}>
			<Switch>
				{/* Normal route without authentication */}
				<Route from={RoutePaths.Redirect.Index} exact component={RedirectToInfoHomePage} />
				<Route path={RoutePaths.Booking.Complete} component={(propsRoutes: any) => <DashboardLayout><CompleteBooking {...propsRoutes} /></DashboardLayout>} />
				<Route path={`${RoutePaths.Booking.Create}/:form_id`} component={(propsRoutes: any) => <DashboardLayout><CreateBooking {...propsRoutes} /></DashboardLayout>} />
				<Route path={`${RoutePaths.Booking.Calendar}/:form_id`} component={(propsRoutes: any) => <DashboardLayout><Calendar {...propsRoutes} /></DashboardLayout>} />
				<Route path={RoutePaths.Auth.Login} exact component={AuthPages} />
				<Route from={RoutePaths.Admin.LoginAdmin} exact component={LoginAdmin} />
				<PrivateRouteAdmin path={RoutePaths.Admin.Report} component={Report} />
				<PrivateRoute path={RoutePaths.Dashboard.Index} component={BaseRoutes} />
				<Route path={RoutePaths.NotFound} component={NotFound} />
				<Route path={RoutePaths.AccessDenied} component={AccessDenied} />
			</Switch>
		</Router>
	);
});
