import { onEpicFailed, onEpicSuccess } from "app/helpers/reduxEpic";
import { Action } from "app/models/redux/action";
import { CANCEL_ALL_REQUEST } from "app/redux/default";
import { Auth } from "app/services/auth";
import { ActionsObservable, ofType } from "redux-observable";
import { of } from "rxjs";
import { catchError, map, mergeMap, takeUntil } from "rxjs/operators";
import * as Actions from "./actions";


/**
 * Handle when action is UPDATE_USER
 * @param action$ 
 */
export const updateUserEpic = (action$: ActionsObservable<Action>) =>
  action$.pipe(
    ofType(Actions.UPDATE_USER),
    mergeMap((action) =>
      Auth.updateProfile(action.payload.body).pipe(
        map(({ response }) => {
          return onEpicSuccess(
            action,
            response,
            Actions.updateUserSuccess(),
          );
        }),
        catchError((error) => {
          return of(onEpicFailed(action, error, Actions.updateUserFailed(error)));
        }),
        takeUntil(action$.pipe(ofType(Actions.UPDATE_USER_CANCELLED, CANCEL_ALL_REQUEST)))
      )
    )
  );

/**
 * Handle when action is LOGIN
 * @param action$ 
 */
export const loginEpic = (action$: ActionsObservable<Action>) =>
  action$.pipe(
    ofType(Actions.LOGIN),
    mergeMap((action) =>
      Auth.login(action.payload.user).pipe(
        map(({ response }) => {
          return onEpicSuccess(
            action,
            response,
            Actions.loginSuccess({
              token: response.result.accessToken,
            })
          );
        }),
        catchError((error) => {
          return of(onEpicFailed(action, error, Actions.loginFailed(error)));
        }),
        takeUntil(action$.pipe(ofType(Actions.LOGIN_CANCELLED, CANCEL_ALL_REQUEST)))
      )
    )
  );

/**
 * Handle when action is LOGIN_GOOGLE
 * @param action$ 
 */
export const loginByGoogleEpic = (action$: ActionsObservable<Action>) =>
  action$.pipe(
    ofType(Actions.LOGIN_GOOGLE),
    mergeMap((action) =>
      Auth.loginByGoogle(action.payload.params).pipe(
        map(({ response }) => {
          return onEpicSuccess(
            action,
            response,
            Actions.loginByGoogleSuccess({
              token: response.data.access_token,
              expires_at: response.data.expires_at,
              refresh_token: response.data.refresh_token,
            })
          );
        }),
        catchError((error) => {
          return of(onEpicFailed(action, error, Actions.loginByGoogleFailed(error)));
        }),
        takeUntil(
          action$.pipe(ofType(Actions.LOGIN_GOOGLE_CANCELLED, CANCEL_ALL_REQUEST))
        )
      )
    )
  );

/**
 * Handle when action is REFRESH_TOKEN
 * @param action$ 
 */
export const refreshTokenEpic = (action$: ActionsObservable<Action>) =>
  action$.pipe(
    ofType(Actions.REFRESH_TOKEN),
    mergeMap((action) =>
      Auth.refreshToken(action.payload.refresh_token).pipe(
        map(({ response }) => {
          return onEpicSuccess(
            action,
            response,
            Actions.refreshTokenSuccess({
              token: response.data.access_token,
              expires_at: response.data.expires_at,
            })
          );
        }),
        catchError((error) => {
          return of(onEpicFailed(action, error, Actions.refreshTokenFailed(error)));
        }),
        takeUntil(
          action$.pipe(ofType(Actions.REFRESH_TOKEN_CANCELLED, CANCEL_ALL_REQUEST))
        )
      )
    )
  );

/**
 * Handle when action is FETCH_CURRENT_USER
 * @param action$ 
 */
export const fetchUserEpic = (action$: ActionsObservable<Action>) =>
  action$.pipe(
    ofType(Actions.FETCH_CURRENT_USER),
    mergeMap((action) =>
      Auth.fetchUser().pipe(
        map(({ response }) => {
          return Actions.fetchUserSuccess(response.data);
        }),
        catchError((error) => {
          return of(Actions.fetchUserFailed(error));
        }),
        takeUntil(
          action$.pipe(ofType(Actions.FETCH_CURRENT_USER_CANCELLED, CANCEL_ALL_REQUEST))
        )
      )
    )
  );

/**
 * Handle when action is FETCH_CURRENT_USER_SUCCESS
 * @param action$, state$
 */
export const onFetchUserSuccessEpic = (
  action$: ActionsObservable<Action>,
  state$: any
) =>
  action$.pipe(
    ofType(Actions.FETCH_CURRENT_USER_SUCCESS),
    mergeMap((action) => {
      if (state$.value.currentUnit) {
        return of(Actions.fetchPermissions(state$.value.currentUnit.unitId));
      }

      return of();
    })
  );

/**
 * Handle when action is FETCH_PERMISSIONS
 * @param action$ 
 */
export const fetchPermissionsEpic = (action$: ActionsObservable<Action>) =>
  action$.pipe(
    ofType(Actions.FETCH_PERMISSIONS),
    mergeMap((action) =>
      Auth.fetchPermissions(action.payload.unitId).pipe(
        map(({ response }) => Actions.fetchPermissionsSuccess(response)),
        catchError((error) => of(Actions.fetchPermissionsFailed(error))),
        takeUntil(
          action$.pipe(ofType(Actions.FETCH_PERMISSIONS_CANCELLED, CANCEL_ALL_REQUEST))
        )
      )
    )
  );

/**
 * Handle when action is LOGOUT
 * @param action$ 
 */
export const logoutEpic = (action$: ActionsObservable<Action>) =>
  action$.pipe(
    ofType(Actions.LOGOUT),
    map((action) => onEpicSuccess(action, null, Actions.logoutSuccess()))
  );


  /**
 * Handle when action is UPDATE_SHOWED_GUIDE
 * @param action$ 
 */
export const updateShowedGuideEpic = (action$: ActionsObservable<Action>) =>
action$.pipe(
  ofType(Actions.UPDATE_SHOWED_GUIDE),
  mergeMap((action) => 
    Auth.updateShowedGuide().pipe(
      map(({ response }) => {
        return onEpicSuccess(
          action,
          response,
          Actions.updateShowedGuideSuccess(),
        );
      }),
      catchError((error) => {
        return of(onEpicFailed(action, error, Actions.updateShowedGuideFailed(error)));
      }),
      takeUntil(action$.pipe(ofType(Actions.UPDATE_SHOWED_GUIDE_CANCELLED, CANCEL_ALL_REQUEST)))
    )
  )
);


