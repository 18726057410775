import { ajax } from 'rxjs/ajax';
import queryString from 'query-string';
import env from '../helpers/env';
import store from '../redux';
import { isEmpty } from 'lodash'

const { API_URL, INTERNAL_TOKEN } = env;

/**
 * Api helper
 */
export class Api {
	/**
	 * Get Full Url
	 * @param url 
	 * @param query 
	 */
	private static getFullUrl(url: string, query?: { [key: string]: any }) {

		return API_URL + url + (query && !isEmpty(query) ? '?' + queryString.stringify(query) : '');
	}

	/**
	 * Get url no query
	 * @param url 
	 * @param query 
	 */
	private static getUrlNoQuery(url: string, query?: { [key: string]: any }) {
		return API_URL + url;
	}

	/**
	 * Get merge header
	 * @param headers 
	 * @param isAdmin 
	 */
	private static mergeHeader(headers: any, isAdmin: boolean = false) {
		const state = store.getState();
		const token = !isAdmin ? state.auth.token : state.loginAdmin.access_token;
		return Object.assign(headers, {
			'Content-Type': 'application/json',
			'Internal-Token': INTERNAL_TOKEN,
			Authorization: token ? 'Bearer ' + token : null,
		});
	}

	/**
	 * Send request with post method
	 * @param url 
	 * @param data 
	 * @param headers 
	 * @param timeout 
	 */
	static post(url: string, data: any = {}, headers: any = {}, timeout: number = 0) {
		if (timeout) {
			return ajax({
				url: Api.getFullUrl(url),
				method: 'POST',
				body: JSON.stringify(data),
				headers: Api.mergeHeader(headers),
				withCredentials: false,
				crossDomain: true,
				timeout: timeout,
			});
		}
		return ajax({
			url: Api.getFullUrl(url),
			method: 'POST',
			body: JSON.stringify(data),
			headers: Api.mergeHeader(headers),
			withCredentials: false,
			crossDomain: true,
		});
	}

	/**
	 * Send request with put method
	 * @param url 
	 * @param data 
	 * @param headers 
	 */
	static put(url: string, data: any = {}, headers: any = {}, isAdmin: boolean = false) {
		return ajax({
			url: Api.getFullUrl(url),
			method: 'PUT',
			body: JSON.stringify(data),
			headers: Api.mergeHeader(headers, isAdmin),
			withCredentials: false,
			crossDomain: true,
		});
	}

	/**
	 * Send request with get method
	 * @param url 
	 * @param query 
	 * @param headers 
	 */
	static get(url: string, query: any = {}, headers = {}, isAdmin: boolean = false) {
		return ajax({
			url: Api.getFullUrl(url, query),
			method: 'GET',
			headers: Api.mergeHeader(headers, isAdmin),
			withCredentials: false,
			crossDomain: true,
		});
	}

	/**
	 * Get No query
	 * @param url 
	 * @param headers 
	 * @param isAdmin 
	 */
	static getNoQuery(url: string, headers = {}, isAdmin: boolean = false) {
		return ajax({
			url: Api.getUrlNoQuery(url),
			method: 'GET',
			headers: Api.mergeHeader(headers, isAdmin),
			withCredentials: false,
			crossDomain: true,
		});
	}

	/**
	 * Send request with delete method
	 * @param url 
	 * @param query 
	 * @param data
	 * @param headers 
	 */
	static delete(url: string, query: any = {}, headers = {}, data: any = {}, isAdmin: boolean = false) {

		return ajax({
			url: Api.getFullUrl(url, query),
			method: "DELETE",
			headers: Api.mergeHeader(headers, isAdmin),
			body: JSON.stringify(data),
			withCredentials: false,
			crossDomain: true
		});
	}
}
