import LoadingOverlay from 'app/components/LoadingOverlay';

/**
 * Loading Manager
 */
export default class LoadingManager {
  private static loadingRef?: LoadingOverlay;

  /**
   * Set loading for ref
   * @param ref 
   */
  static setLoadingRef = (ref: LoadingOverlay) => {
    LoadingManager.loadingRef = ref;
  }

  /**
   * Set loading
   * @param loading 
   * @param opacity 
   */
  static setLoading = (loading: boolean, opacity?: number) => {
    if (LoadingManager.loadingRef) {
      LoadingManager.loadingRef.setLoading(loading, opacity);
    }
  }
}