import env from 'app/helpers/env';
import { cssTransition, toast, ToastContent, ToastOptions } from "react-toastify";
import './notify.scss';

/**
 * Init toast
 * @param options 
 */
const mergeOptions = (options?: ToastOptions) => {
	toast.dismiss();
	return Object.assign<ToastOptions, ToastOptions | undefined>(
		{
			autoClose: env.TIME_OUT_NOTIFY,
			position: "top-right",
			hideProgressBar: true,
			pauseOnFocusLoss: false,
			pauseOnHover: false,
			transition: cssTransition({
				enter: 'fadeIn',
				exit: 'fadeOut'
			})
		},
		options,
	);
}

/**
 * Show notification
 * @param content 
 * @param options 
 */
export const notify = (
	content: ToastContent,
	options?: ToastOptions | undefined
) => toast(content, mergeOptions(options));

/**
 * Show notification success type
 * @param content 
 * @param options 
 */
export const notifySuccess = (
	content: ToastContent,
	options?: ToastOptions | undefined
) => toast.success(content, mergeOptions(options));

/**
 * Show notification info type
 * @param content 
 * @param options 
 */
export const notifyInfo = (
	content: ToastContent,
	options?: ToastOptions | undefined
) => toast.info(content, mergeOptions(options));

/**
 * Show notification warning type
 * @param content 
 * @param options 
 */
export const notifyWarning = (
	content: ToastContent,
	options?: ToastOptions | undefined
) => toast.warn(content, mergeOptions(options));

/**
 * Show notification error type
 * @param content 
 * @param options 
 */
export const notifyError = (
	content: ToastContent,
	options?: ToastOptions | undefined
) => toast.error(content, mergeOptions(options));

/**
 * Show notification dark type
 * @param content 
 * @param options 
 */
export const notifyDark = (
	content: ToastContent,
	options?: ToastOptions | undefined
) => toast.dark(content, mergeOptions(options));

/**
 * Show custom notification success
 * @param message 
 * @param timeOut 
 * @param routeUrl 
 */
export const customNotifySuccess = (
	message: string,
	timeOut: number,
	routeUrl: any
) => {
	notifySuccess(message, {
		autoClose: timeOut,
		onClose: () => (window.location.href = routeUrl),
	});
};

/**
 * Show custom notification error
 * @param message 
 * @param timeOut 
 * @param routeUrl 
 */
export const customNotifyError = (
	message: string,
	timeOut: number,
	routeUrl?: any
) => {
	notifyError(message, {
		autoClose: timeOut,
		onClose: () => (window.location.href = routeUrl),
	});
};