import { ContractInfoValue } from 'app/constants/adminConstants';
import env from 'app/helpers/env';
import { DashboardLayout } from "app/layouts/DashboardLayout";
import { logout, refreshToken } from "app/redux/modules/auth";
import { RootStoreType } from "app/redux/modules/root";
import { getUserSelector } from 'app/redux/selectors';
import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { connect, useSelector } from "react-redux";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { RoutePaths } from "../constants/routes";

const { INTRO_HOME_PAGE } = env
const BookingTimeline = React.lazy(() => import("app/pages/booking/listing"));
const FormBooking = React.lazy(() => import("app/pages/form/listing"));
const CreateForm = React.lazy(() => import("app/pages/form/create/FormBooking"));
const DetailForm = React.lazy(() => import("app/pages/form/detail/DetailForm"));
const CopyForm = React.lazy(() => import("app/pages/form/copy/index"));
const EditForm = React.lazy(() => import("app/pages/form/edit/index"));
const Finish = React.lazy(() => import("app/pages/form/finish/index"));

const TagList = React.lazy(() => import("app/pages/tagUser/listing"));
const TagListCreate = React.lazy(() => import("app/pages/tagUser/create"));
const TagListDetail = React.lazy(() => import("app/pages/tagUser/detail"));

const Setting = React.lazy(() => import("app/pages/setting/mainPage"));
const Profile = React.lazy(() => import("app/pages/setting/profile"));
const SettingAccount = React.lazy(() => import('app/pages/setting/account'));
const SettingProfile = React.lazy(() => import('app/pages/setting/profile'));
const SettingFormDefault = React.lazy(() => import("app/pages/setting/settingDefault"));
const Tutorial = React.lazy(() => import("app/pages/setting/tutorial"));
const Contract = React.lazy(() => import("app/pages/setting/contract"));
const Cancel = React.lazy(() => import("app/pages/setting/cancel"));

const CopyLink = React.lazy(() => import('app/pages/booking/newBookingComplete'));
const NotFound = React.lazy(() => import('app/pages/notfound/NotFound'));

const RegistPlanPlan = React.lazy(() => import('app/pages/registPlan/plan'));
const RegistPlanPayment = React.lazy(() => import('app/pages/registPlan/payment'));
const RegistPlanFinish = React.lazy(() => import('app/pages/registPlan/finish'));
const EditMethodPayment = React.lazy(() => import('app/pages/registPlan/editPayment'));
const EditMethodOnlyPayment = React.lazy(() => import('app/pages/registPlan/editMethodOnlyPayment'));
const EditMethodOnlyFinish = React.lazy(() => import('app/pages/registPlan/editMethodOnlyfinish'));

interface Props {
  refreshToken: (refresh_token: string) => void;
  logout: () => void;
  expires_at: string;
  refresh_token: string;
  token: string;
  refresh_expires_at: string;
}

const mapStateToProps = (state: RootStoreType) => ({
  expires_at: state.auth.expires_at,
  token: state.auth.token,
  refresh_token: state.auth.refresh_token,
  refresh_expires_at: state.authRefresh.expires_at,
});

const actions = {
  refreshToken,
  logout
};

const TIME_BEFORE_REFRESH = 600000;

/**
 * Component BaseRoute
 */
export const BaseRoutes = connect(
  mapStateToProps,
  actions
)((props: Props) => {

  const { refreshToken, expires_at, refresh_token, token } = props;
  const location = useLocation();
  const checkBookingPath = location.pathname.indexOf('/booking/') !== -1;
  const [time, setTime] = useState(
    moment(expires_at).diff(moment(), "milliseconds") - TIME_BEFORE_REFRESH
  );

  const user = useSelector(getUserSelector);
  const effectiveContract = user?.payment?.contract_info !== ContractInfoValue.UNACTIVE;
  const intervalRef = useRef<any>(null)

  const RedirectToInfoHomePage = () => {
    if (!token && !moment().isBefore(moment(expires_at))) window.location.href = INTRO_HOME_PAGE
    return <Redirect to={RoutePaths.BookingForm.Index} />
  }


  /**
   * Refresh Token
   */
  const refreshJWTToken = useCallback(() => {
    if (!checkBookingPath) {
      refreshToken(refresh_token);
    }
  }, [refresh_token, refreshToken]);

  useEffect(() => {
    if (time <= 0) {
      refreshJWTToken();
    } else {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
      intervalRef.current = setInterval(() => {
        refreshJWTToken();
      }, time)
    }
  }, [time])

  useEffect(() => {
    if (expires_at) {
      setTime(moment(expires_at).diff(moment(), "milliseconds") - TIME_BEFORE_REFRESH);
    }
  }, [expires_at]);

  useEffect(() => {
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, []);

  const renderRouter = useCallback(() => {
    if (effectiveContract) {
      return <>
        <Switch>
          {/* Route for module form  */}
          <Route exact path={RoutePaths.BookingForm.Index} component={FormBooking} />
          <Route path={RoutePaths.BookingForm.Create} component={CreateForm} />
          <Route path={`${RoutePaths.BookingForm.Detail}/:form_id`} component={DetailForm} />
          <Route path={`${RoutePaths.BookingForm.Copy}/:form_id`} component={CopyForm} />
          <Route path={`${RoutePaths.BookingForm.Edit}/:form_id`} component={EditForm} />
          <Route path={`${RoutePaths.BookingForm.CopyUrl}/:form_id`} component={Finish} />

          {/* Route for module booking */}
          <Route exact path={RoutePaths.Booking.Index} component={BookingTimeline} />
          <Route path={RoutePaths.Booking.CopyLink} component={CopyLink} />

          {/* Route for module tag */}
          <Route exact path={RoutePaths.Tag.Index} component={TagList} />
          <Route exact path={RoutePaths.Tag.Create} component={TagListCreate} />
          <Route exact path={`${RoutePaths.Tag.Detail}/:id`} component={TagListDetail} />

          {/* Route for setting */}
          <Route exact path={RoutePaths.Setting.Index} component={Setting} />
          <Route exact path={RoutePaths.Setting.Account} component={SettingAccount} />
          <Route exact path={RoutePaths.Setting.Profile} component={Profile} />
          <Route exact path={RoutePaths.Setting.DefaultForm} component={SettingFormDefault} />
          <Route exact path={RoutePaths.Setting.Tutorial} component={Tutorial} />
          <Route exact path={RoutePaths.Setting.Contract} component={Contract}></Route>
          <Route exact path={RoutePaths.Setting.Cancel} component={Cancel}></Route>

          {/* Route for regist plan */}
          <Route exact path={RoutePaths.RegistPlan.Plan} component={RegistPlanPlan} />
          <Route exact path={RoutePaths.RegistPlan.Payment} component={RegistPlanPayment} />
          <Route exact path={RoutePaths.RegistPlan.Finish} component={RegistPlanFinish} />
          <Route exact path={RoutePaths.EditPlan.Payment} component={EditMethodPayment} />
          <Route exact path={RoutePaths.EditPlan.MethodOnlyPayment} component={EditMethodOnlyPayment} />
          <Route exact path={RoutePaths.EditPlan.MethodOnlyFinish} component={EditMethodOnlyFinish} />

          {/* Redirect not found */}
          <Route path={RoutePaths.NotFound} component={NotFound} />
          <Redirect to={RoutePaths.NotFound} />
        </Switch>
      </>
    }
    return <>
      <Switch>
        {/* It is possible to add effect routers when the account expires 無料ユーザー遷移可能画面 */}
        <Route path={RoutePaths.Pay.Index} component={() => (<p>
          This is the payment page
        </p>)} />

        {/* Route for setting */}
        <Route exact path={RoutePaths.Setting.Index} component={Setting} />
        <Route exact path={RoutePaths.Setting.Account} component={SettingAccount} />
        <Route exact path={RoutePaths.Setting.Profile} component={Profile} />
        <Route exact path={RoutePaths.Setting.Contract} component={Contract}></Route>

        {/* Route for regist plan */}
        <Route exact path={RoutePaths.RegistPlan.Plan} component={RegistPlanPlan} />
        <Route exact path={RoutePaths.RegistPlan.Payment} component={RegistPlanPayment} />
        <Route exact path={RoutePaths.RegistPlan.Finish} component={RegistPlanFinish} />
        <Redirect to={RoutePaths.BookingForm.Index} />
      </Switch>
    </>
  }, [effectiveContract])

  return (
    <>
      <DashboardLayout>
        <Switch>
          {/* Route for common */}
          <Route from={RoutePaths.Dashboard.Index} exact component={RedirectToInfoHomePage} />
          {/* Route for module form  */}
          <Route exact path={RoutePaths.BookingForm.Index} component={FormBooking} />
          {
            renderRouter()
          }
        </Switch>
      </DashboardLayout>
    </>
  )
});