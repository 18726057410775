import { ActionsObservable, ofType } from "redux-observable";
import { map, catchError, mergeMap, takeUntil } from "rxjs/operators";
import { of } from "rxjs";
import { onEpicSuccess, onEpicFailed } from "app/helpers/reduxEpic";
import { Action } from "app/models/redux/action";
import { ContractService } from "app/services/contract";
import { CANCEL_ALL_REQUEST } from "app/redux/default";
import * as Actions from "./actions";

/**
 * Watch action type and request to fetch user contract
 * @param action$
 */
export const feachUserContractEpic = (action$: ActionsObservable<Action>) =>
    action$.pipe(
        ofType(Actions.GET_USER_CONTRACT),
        mergeMap((action) =>
            ContractService.getUserContract().pipe(
                map((ajaxResponse) => {
                    const { response } = ajaxResponse;
                    const { data } = response
                    if (data) {
                        return Actions.getUserContractSuccess(data)
                    }
                }),
                catchError((error) => {
                    return of(Actions.getUserContractFailed(error));
                }),
                takeUntil(
                    action$.pipe(
                        ofType(Actions.GET_USER_CONTRACT_CANCELLED, CANCEL_ALL_REQUEST)
                    )
                )
            )
        )
    );

/**
 * Handle when action is CANCEL_USER_CONTRACT
 * @param action$ 
 * @returns 
 */
export const cancelUserContractEpic = (action$: ActionsObservable<Action>) =>
    action$.pipe(
        ofType(Actions.CANCEL_USER_CONTRACT),
        mergeMap((action) => {
            return ContractService.cancelUserContract(action.payload.body).pipe(
                map((ajaxResponse) => {
                    return onEpicSuccess(
                        action,
                        ajaxResponse,
                        Actions.cancelUserContractSuccess(ajaxResponse)
                    );
                }),
                catchError((error) => {
                    return of(
                        onEpicFailed(action, error, Actions.cancelUserContractFailed(error))
                    );
                }),
                takeUntil(
                    action$.pipe(
                        ofType(Actions.CANCEL_USER_CONTRACT_CANCELLED, CANCEL_ALL_REQUEST)
                    )
                )
            );
        }))