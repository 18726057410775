import { checkHasPressEnterKey } from 'app/helpers';
import { toggleModal } from 'app/redux/modules/popup';
import { Images } from 'assets/iconListing';
import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import './style.scss';

interface Props {
	header: string;
	isActive: boolean;
	onClose: () => void;
	headerClass?: string;
	bodyClass?: string;
	modalClassName?: string;
	showClose?: boolean
}

/**
 * Component Popup
 */
const Popup: React.FC<Props> = ({ headerClass, header, isActive, onClose, children, modalClassName, showClose = true, bodyClass }) => {

	const dispatch = useDispatch();
	useEffect(() => {
		window.addEventListener('scroll', () => {
			document.documentElement.style.setProperty('--scroll-y', `${window.scrollY}px`);
		});
		if (isActive) {
			const scrollY = document.documentElement.style.getPropertyValue('--scroll-y');
			const body = document.body;
			body.style.position = 'fixed';
			body.style.top = `-${scrollY}`;
		} else {
			const body = document.body;
			const scrollY = body.style.top;
			body.style.position = '';
			body.style.top = '';
			window.scrollTo(0, parseInt(scrollY || '0') * -1);
		}

	}, [isActive])


	return (
		<Modal className={`${!!modalClassName ? modalClassName : ''} app-modal`}
			show={isActive}
			onHide={() => {
				onClose();
				dispatch(toggleModal(false))
			}}
			animation={true}
			backdropClassName="modal__backdrop"
			backdrop="static"
			onClick={(e: MouseEvent) => e.stopPropagation()}
			onShow={() => {
				dispatch(toggleModal(true))
			}}
		>
			<div className={`modal__header ${headerClass}`}>
				{
					showClose && (
						<>
							<div className="modal__header--icon">
								<img src={Images.IconTopPopup} alt="" />
							</div>
							<div className="close-btn text-right">
								<i className="fas fa-times" tabIndex={0} onClick={() => {
									dispatch(toggleModal(false))
									onClose();
								}}
									onKeyDown={(event) => {
										if (checkHasPressEnterKey(event)) {
											dispatch(toggleModal(false))
											onClose();
										}
									}}
								/>
							</div>
						</>
					)
				}
				<h2 className="modal__text-color">{header}</h2>
			</div>
			{children}
		</Modal>
	);
};

export default Popup;
