import { Action } from "../../../models/redux/action";
import { DISABLE_BUTTON, RELEASE_DISABLE_BUTTON } from "./action";


export const registCreditButtonReducer = (
    state: any = { status: false },
    action: Action
) => {
    switch (action.type) {
        case DISABLE_BUTTON:
            return {
                ...state,
                status: true
            };
        case RELEASE_DISABLE_BUTTON:
            return {
                ...state,
                status: false
            };
        default:
            return {
                ...state
            };
    }
};