import { ActionsObservable, ofType } from "redux-observable";
import { map, catchError, takeUntil, mergeMap } from "rxjs/operators";
import { of } from "rxjs";
import { Action } from "app/models/redux/action";
import { onEpicSuccess, onEpicFailed } from "app/helpers/reduxEpic";
import { CANCEL_ALL_REQUEST } from "app/redux/default";
import { TagsService } from "app/services/tags";
import * as Actions from "./actions";

/**
 * Watch action type and request to fetch list tag
 * @param action$ 
 */
export const fetchTagsListEpic = (action$: ActionsObservable<Action>) =>
  action$.pipe(
    ofType(Actions.GET_TAG_LIST),
    mergeMap((action) =>
      TagsService.getTagsList(action.payload.query).pipe(
        map((ajaxResponse) => {
          const { response } = ajaxResponse;
          const data = response.data?.records as any[];
          const result = data;
          if (result) {
            return onEpicSuccess(
              action,
              ajaxResponse,
              Actions.getTagsListSuccess(result)
            );
          } else {
            return onEpicFailed(action, ajaxResponse, Actions.getTagsListFailed(ajaxResponse));
          }
        }),
        catchError((error) => {
          return of(
            onEpicFailed(action, error, Actions.getTagsListFailed(error))
          );
        }),
        takeUntil(
          action$.pipe(
            ofType(Actions.GET_TAG_LIST_CANCELLED, CANCEL_ALL_REQUEST)
          )
        )
      )
    )
  );

/**
 * Watch action type and request to fetch list tag colors
 * @param action$ 
 */
export const fetchTagColorsEpic = (action$: ActionsObservable<Action>) =>
  action$.pipe(
    ofType(Actions.GET_TAG_COLORS),
    mergeMap((action) =>
      TagsService.getTagColors().pipe(
        map((ajaxResponse) => {
          const { response } = ajaxResponse;
          const data = response.data?.records as any[];
          if (data) {
            return Actions.getTagColorsListSuccess(data)
          }
        }),
        catchError((error) => {
          return of(Actions.getTagColorsListFailed(error));
        }),
        takeUntil(
          action$.pipe(
            ofType(Actions.GET_TAG_COLORS_CANCELLED, CANCEL_ALL_REQUEST)
          )
        )
      )
    )
  );

/**
 * Watch action type and request to fetch tag detail
 * @param action$ 
 */
export const fetchTagDetailEpic = (action$: ActionsObservable<Action>) =>
  action$.pipe(
    ofType(Actions.GET_TAG_DETAIL),
    mergeMap((action) =>
      TagsService.getTagDetail(action.payload).pipe(
        map((ajaxResponse) => {
          const { response } = ajaxResponse;
          const { data } = response;
          if (data) {
            return Actions.getTagDetailSuccess(data)
          }
        }),
        catchError((error) => {
          return of(Actions.getTagDetailFailed(error));
        }),
        takeUntil(
          action$.pipe(
            ofType(Actions.GET_TAG_DETAIL_CANCELLED, CANCEL_ALL_REQUEST)
          )
        )
      )
    )
  );

/**
 * Watch action type and request to edit tag detail
 * @param action$ 
 */
export const editTagDetailEpic = (action$: ActionsObservable<Action>) =>
  action$.pipe(
    ofType(Actions.EDIT_TAG_DETAIL),
    mergeMap((action) =>
      TagsService.editTagDetail(action.payload.id, action.payload.body).pipe(
        map((ajaxResponse) => {
          const { response } = ajaxResponse;
          const { status } = response;
          if (status) {
            return onEpicSuccess(
              action,
              ajaxResponse,
              Actions.editTagDetailSuccess()
            )
          }
        }),
        catchError((error) => {
          return of(
            onEpicFailed(
              action,
              error,
              Actions.editTagDetailFailed(error))
            );
        }),
        takeUntil(
          action$.pipe(
            ofType(Actions.EDIT_TAG_DETAIL_CANCELLED, CANCEL_ALL_REQUEST)
          )
        )
      )
    )
  );

/**
 * Watch action type and request to create tag
 * @param action$ 
 */
export const createTagEpic = (action$: ActionsObservable<Action>) =>
  action$.pipe(
    ofType(Actions.CREATE_TAG),
    mergeMap((action) =>
      TagsService.createTag(action.payload.body).pipe(
        map((ajaxResponse) => {
          const { response } = ajaxResponse;
          const { status } = response;
          if (status) {
            return onEpicSuccess(
              action,
              ajaxResponse,
              Actions.createTagSuccess()
            )
          }
        }),
        catchError((error) => {
          return of(
            onEpicFailed(
              action,
              error,
              Actions.createTagFailed(error))
            );
        }),
        takeUntil(
          action$.pipe(
            ofType(Actions.CREATE_TAG_CANCELLED, CANCEL_ALL_REQUEST)
          )
        )
      )
    )
  );

/**
 * Watch action type and request to delete tag
 * @param action$ 
 */
export const deleteTagEpic = (action$: ActionsObservable<Action>) =>
action$.pipe(
  ofType(Actions.DELETE_TAG),
  mergeMap((action) =>
    TagsService.deleteTag(action.payload.id).pipe(
      map((ajaxResponse) => {
        const { response } = ajaxResponse;
        const { status } = response;
        if (status) {
          return onEpicSuccess(
            action,
            ajaxResponse,
            Actions.deleteTagSuccess()
          )
        }
      }),
      catchError((error) => {
        return of(
          onEpicFailed(
            action,
            error,
            Actions.deleteTagFailed(error))
          );
      }),
      takeUntil(
        action$.pipe(
          ofType(Actions.DELETE_TAG_CANCELLED, CANCEL_ALL_REQUEST)
        )
      )
    )
  )
);

/**
 * Watch action type and request to check use tag in any form
 * @param action$ 
 */
export const checkIsUseTagEpic = (action$: ActionsObservable<Action>) =>
action$.pipe(
  ofType(Actions.CHECK_USE_TAG),
  mergeMap((action) =>
    TagsService.checkIsUseTag(action.payload.id).pipe(
      map((ajaxResponse) => {
        const { response } = ajaxResponse;
        const { status } = response;
        if (status) {
          return onEpicSuccess(
            action,
            ajaxResponse,
            Actions.checkUseTagSuccess()
          )
        }
      }),
      catchError((error) => {
        return of(
          onEpicFailed(
            action,
            error,
            Actions.checkUseTagFailed(error))
          );
      }),
      takeUntil(
        action$.pipe(
          ofType(Actions.CHECK_USE_TAG_CANCELLED, CANCEL_ALL_REQUEST)
        )
      )
    )
  )
);