import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { RoutePaths } from '../constants/routes';

/**
 * Component PrivateRoute
 * @param props 
 */
const PrivateRoute = (props: any) => {
	const user = useSelector((state: any) => state.auth.user);
	if (user) {
		return <Route {...props} />;
	}

	return <Redirect to={RoutePaths.Auth.Login} />;
};

export default PrivateRoute;
