import { Action } from "app/models/redux/action";
import { ReduxCallbacks } from "app/models/redux/Callbacks";
import { PaymentMergeModel } from "app/models/PaymentMergeModel";
import { PaymentPlan } from 'app/models/PaymentPlan';
import { PaymentInformation } from 'app/models/PaymentInformation';

export const GET_PAYMENT_INFORMATION = "GET_PAYMENT_INFORMATION";
export const GET_PAYMENT_INFORMATION_SUCCESS = "GET_PAYMENT_INFORMATION_SUCCESS";
export const GET_PAYMENT_INFORMATION_FAILED = "GET_PAYMENT_INFORMATION_FAILED";

export const MERGE_USER_PAYMENT = "MERGE_USER_PAYMENT";
export const MERGE_USER_PAYMENT_SUCCESS = "MERGE_USER_PAYMENT_SUCCESS";
export const MERGE_USER_PAYMENT_FAILED = "MERGE_USER_PAYMENT_FAILED";

export const SET_PAYMENT_PLAN_ID = "SET_PAYMENT_PLAN_ID";
export const SET_PAYMENT_INFORMATION = "SET_PAYMENT_INFORMATION";

export const SET_EDITED_PAYMENT_PLAN = "SET_EDITED_PAYMENT_PLAN";
export const SET_EDITED_TO_DEFAULT_PAYMENT_PLAN = "SET_EDITED_TO_DEFAULT_PAYMENT_PLAN";

export const CREATE_NEW_BANK_PAYMENT = "CREATE_NEW_BANK_PAYMENT";
export const CREATE_NEW_BANK_PAYMENT_SUCCESS = "CREATE_NEW_BANK_PAYMENT_SUCCESS";
export const CREATE_NEW_BANK_PAYMENT_FAILED = "CREATE_NEW_BANK_PAYMENT_FAILED";

export const UPDATE_ONLY_PAYMENT_PLAN = "UPDATE_ONLY_PAYMENT_PLAN";
export const UPDATE_ONLY_PAYMENT_PLAN_SUCCESS = "UPDATE_ONLY_PAYMENT_PLAN_SUCCESS";
export const UPDATE_ONLY_PAYMENT_PLAN_FAILED = "UPDATE_ONLY_PAYMENT_PLAN_FAILED";

export const UPDATE_PAYMENT_WITH_CREDITCARD = "UPDATE_PAYMENT_WITH_CREDITCARD";
export const UPDATE_PAYMENT_WITH_CREDITCARD_SUCCESS = "UPDATE_PAYMENT_WITH_CREDITCARD_SUCCESS";
export const UPDATE_PAYMENT_WITH_CREDITCARD_FAILED = "UPDATE_PAYMENT_WITH_CREDITCARD_FAILED";

export const CHANGE_PAYMENT_TO_BANK = "CHANGE_PAYMENT_TO_BANK";
export const CHANGE_PAYMENT_TO_BANK_SUCCESS = "CHANGE_PAYMENT_TO_BANK_SUCCESS";
export const CHANGE_PAYMENT_TO_BANK_FAILED = "CHANGE_PAYMENT_TO_BANK_FAILED";


export interface PaymentMerge {
  payment: PaymentMergeModel;
}
/**
 * Action get payment_infomation
 */
export const getPaymentInformation = (callbacks?: ReduxCallbacks): Action => ({
  type: GET_PAYMENT_INFORMATION,
  payload: { callbacks }
});

/**
 * Action get payment_infomation success
 * @param payload
 */
export const getPaymentInformationSuccess = (
  payload: any[]
): Action<any[]> => ({
  type: GET_PAYMENT_INFORMATION_SUCCESS,
  payload,
});

/**
 * Action get payment_infomation failed
 * @param error
 */
export const getPaymentInformationFailed = (error: any): Action => ({
  type: GET_PAYMENT_INFORMATION_FAILED,
  payload: error
});

/**
 * Action merge user payment
 */
export const mergeUserPayment = (body: any, callbacks?: ReduxCallbacks): Action => ({
  type: MERGE_USER_PAYMENT,
  payload: { body, callbacks }
});

/**
 * Action merge user payment success
 * @param payload
 */
export const mergeUserPaymentSuccess = (
  payload: any[]
): Action<any[]> => ({
  type: MERGE_USER_PAYMENT_SUCCESS,
  payload,
});

/**
 * Action merge user payment failed
 * @param error
 */
export const mergeUserPaymentFailed = (error: any): Action => ({
  type: MERGE_USER_PAYMENT_FAILED,
  payload: error
});

/**
 * Action set payment plan id
 * @param id
 */
export const setPaymentPlanId = (plan: PaymentPlan): Action => ({
  type: SET_PAYMENT_PLAN_ID,
  payload: plan
});

/**
 * Action set payment information
 * @param id
 */
export const setPaymentInformation = (paymentInformation: PaymentInformation): Action => ({
  type: SET_PAYMENT_INFORMATION,
  payload: paymentInformation
});

/**
 * Action set plan edit_fg
 */
export const setEditedPaymentPlan = (): Action => ({
  type: SET_EDITED_PAYMENT_PLAN
});

/**
 * Action set default plan edit_fg
 */
export const setEditedToDefaultPaymentPlan = (): Action => ({
  type: SET_EDITED_TO_DEFAULT_PAYMENT_PLAN
});

/**
 * Action new Bank Payment Transfer
 * 
 * @param body 
 * @param callbacks 
 * @returns 
 */

export const createNewBankPaymentTransfer = (body: any, callbacks?: ReduxCallbacks): Action => ({
  type: CREATE_NEW_BANK_PAYMENT,
  payload: { body, callbacks }
});

/**
 * Action new Bank Payment Transfer Success
 * @param payload 
 * @returns 
 */
export const createNewBankPaymentTransferSuccess = (payload: any): Action => ({
  type: CREATE_NEW_BANK_PAYMENT_SUCCESS,
  payload
});

/**
 * Action new Bank Payment Transfer Failed
 * @param error 
 * @returns 
 */
export const createNewBankPaymentTransferFailed = (error: any): Action => ({
  type: CREATE_NEW_BANK_PAYMENT_FAILED,
  payload: error
});

/**
 * Action update only payment plan
 * @param body 
 * @param callbacks 
 * @returns
 */
export const updateOnlyPaymentPlan = (body: any, callbacks?: ReduxCallbacks): Action => ({
  type: UPDATE_ONLY_PAYMENT_PLAN,
  payload: { body, callbacks }
});

/**
 * Action update only payment plan success
 */
export const updateOnlyPaymentPlanSucess = (payload: any): Action<any> => ({
  type: UPDATE_ONLY_PAYMENT_PLAN_SUCCESS,
  payload
});

/**
 * Action update only payment plan failed
 */
export const updateOnlyPaymentPlanFailed = (error: any): Action<any> => ({
  type: UPDATE_ONLY_PAYMENT_PLAN_FAILED,
  payload: error
});

/**
 * Action update with Credit Card Infomation
 */
export const updatePaymentWithCreditCard = (body: any, callbacks?: ReduxCallbacks): Action => ({
  type: UPDATE_PAYMENT_WITH_CREDITCARD,
  payload: { body, callbacks }
});

/**
 * Action update with Credit Card Infomation Success
 */
export const updatePaymentWithCreditCardSuccess = (payload: any): Action<any> => ({
  type: UPDATE_PAYMENT_WITH_CREDITCARD_SUCCESS,
  payload: payload
});

/**
 * Action update with Credit Card Infomation Falied
 */
export const updatePaymentWithCreditCardFailed = (error: any): Action<any> => ({
  type: UPDATE_PAYMENT_WITH_CREDITCARD_FAILED,
  payload: error
});


/**
 * Action change payment to bank
 */
export const changePaymentToBank = (body: any, callbacks?: ReduxCallbacks): Action => ({
  type: CHANGE_PAYMENT_TO_BANK,
  payload: { body, callbacks }
});

/**
 * Action change payment to bank Success
 * @param payload  
 * @returns 
 */
export const changePaymentToBankSuccess = (payload: any): Action => ({
  type: CHANGE_PAYMENT_TO_BANK_SUCCESS,
  payload
});

/**
 * Action change payment to bank Failed
 * @param error 
 * @returns 
 */
export const changePaymentToBankFailed = (error: any): Action => ({
  type: CHANGE_PAYMENT_TO_BANK_FAILED,
  payload: error
});