import React from 'react';
import CustomMenuLink from 'app/components/Common/MenuLink/CustomMenuLink';
import { Menus } from 'app/constants/menu';
import './style.scss';
import { useLocation } from 'react-router-dom';
import { path } from 'app/constants/checkPath';

/**
 * Component Bottom Tab Navigation
 */
const BottomTabNavigation = () => {

	const location = useLocation();
	const checkPathIsBooking = location.pathname.indexOf(path.isBooking) !== -1;
	const checkPathIsNotFound = location.pathname.indexOf(path.isNotFourd) !== -1;

	/**
	 * Create menu
	 */
	const createMenus = Menus.filter((item) => {
		return item.ForScreen === 1 || item.ForScreen === 3;
	}).map((item, index) => (
		<li key={index} className="bottom-tab__nav--item">
			<CustomMenuLink
				to={item.Router}
				label={item.Name}
				activeOnlyWhenExact={item.ActiveOnlyWhenExact}
				activeIcon={item.IconActive}
				baseIcon={item.Icon}
				mobile={true}
			/>
		</li>
	));

	return (
		<div className={`bottom-tab  ${checkPathIsBooking || checkPathIsNotFound ? `d-none` : `d-lg-none`}`}>
			<ul className="d-flex justify-content-between align-items-center bottom-tab__nav">{createMenus}</ul>
		</div>
	);
};

export default BottomTabNavigation;
