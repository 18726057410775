import { Action } from "app/models/redux/action";
import { ReduxCallbacks } from "app/models/redux/Callbacks";
import { ListQueryBooking } from "app/models/redux/ListQuery";

export const GET_BOOKING_LIST = "GET_BOOKING_LIST";
export const GET_BOOKING_LIST_SUCCESS = "GET_BOOKING_LIST_SUCCESS";
export const GET_BOOKING_LIST_FAILED = "GET_BOOKING_LIST_FAILED";
export const GET_BOOKING_LIST_CANCELLED = "GET_BOOKING_LIST_CANCELLED";

export const GET_BOOKING_DETAIL = "GET_BOOKING_DETAIL";
export const GET_BOOKING_DETAIL_SUCCESS = "GET_BOOKING_DETAIL_SUCCESS";
export const GET_BOOKING_DETAIL_FAILED = "GET_BOOKING_DETAIL_FAILED";
export const GET_BOOKING_DETAIL_CANCELLED = "GET_BOOKING_DETAIL_CANCELLED";

export const DELETE_BOOKING_DETAIL = "DELETE_BOOKING_DETAIL";
export const DELETE_BOOKING_DETAIL_SUCCESS = "DELETE_BOOKING_DETAIL_SUCCESS";
export const DELETE_BOOKING_DETAIL_FAILED = "DELETE_BOOKING_DETAIL_FAILED";
export const DELETE_BOOKING_DETAIL_CANCELLED = "DELETE_BOOKING_DETAIL_CANCELLED";

/**
 * Actions delete booking timeline
 * @param id 
 * @param callbacks 
 */
export const deleteBookingTimelineDetail = (id: string | number | null, callbacks: any) : Action => ({
  type: DELETE_BOOKING_DETAIL,
  payload: { id, callbacks },
});

/**
  Action delete booking timeline success
 */
export const deleteBookingTimelineDetailSuccess = (payload: any) : Action => ({
  type: DELETE_BOOKING_DETAIL_SUCCESS,
  payload,
});

/**
 * Action delete booking timeline failed
 * @param error 
 */
export const deleteBookingTimelineDetailFailed = (error: any) : Action => ({
  type: DELETE_BOOKING_DETAIL_FAILED,
  payload: error,
});

/**
 * Action cancel delete booking timeline
 */
export const deleteBookingTimelineDetailCancelled = () : Action => ({
  type: DELETE_BOOKING_DETAIL_CANCELLED,
});

/**
 * Action get booking timeline list
 * @param query 
 * @param callbacks 
 */
export const getBookingTimelineList = (
  query: ListQueryBooking,
  callbacks?: ReduxCallbacks
): Action => ({
  type: GET_BOOKING_LIST,
  payload: { query, callbacks },
});

/**
 * Action get booking timeline list success
 * @param payload 
 */
export const getBookingTimelineListSuccess = (
  payload: any[]
): Action<any[]> => ({
  type: GET_BOOKING_LIST_SUCCESS,
  payload,
});


/**
 * Action get booking timeline list failed
 * @param error 
 */
export const getBookingTimelineListFailed = (error: any): Action => ({
  type: GET_BOOKING_LIST_FAILED,
  payload: error,
});

/**
 * Action cancel get booking timeline list
 */
export const getBookingTimelineListCancelled = (): Action => ({
  type: GET_BOOKING_LIST_CANCELLED,
});

/**
 * Action get detail booking timeline
 * @param payload 
 */
export const getBookingTimelineDetail = (payload: any) : Action => ({
  type: GET_BOOKING_DETAIL,
  payload,
});

/**
 * Action get detail booking timeline scuccess
 * @param payload 
 */
export const getBookingTimelineDetailSuccess = (payload: any) : Action => ({
  type: GET_BOOKING_DETAIL_SUCCESS,
  payload,
});

/**
 * Action get detail booking timeline error
 * @param error 
 */
export const getBookingTimelineDetailFailed = (error: any) : Action => ({
  type: GET_BOOKING_DETAIL_FAILED,
  payload: error,
});

/**
 * Action cancel get detail booking timeline
 */
export const getBookingTimelineDetailCancelled = () : Action => ({
  type: GET_BOOKING_DETAIL_CANCELLED,
});

