import { Action } from "app/models/redux/action";

export const DISABLE_BUTTON = "DISABLE_BUTTON";
export const RELEASE_DISABLE_BUTTON = "RELEASE_DISABLE_BUTTON";

/**
 * Action Disable Credit Button
 *
 */
export const disableCreditButton = (): Action => ({
    type: DISABLE_BUTTON
});


/**
 * Action Release Disable Credit Button
 * @returns 
 */
export const releaseDisableCreditButton = (): Action => ({
    type: RELEASE_DISABLE_BUTTON
});