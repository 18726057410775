export const ContractMethod = [
    { value: 1, label: 'クレカ', checked: true },
    { value: 2, label: '請求書', checked: true },
    { value: 0, label: '未登録', checked: true },
]

export const ContractInfo = [
    { value: 0, label: '有効', checked: true },
    { value: 2, label: '停止', checked: true },
    { value: 1, label: 'フリー（3年）', checked: true },
]

export const PaymentMethod = [
    { label: '年間', value: 1 }, { label: "月間", value: 0 }
]

export const PaymentMethodPlanUnitPrice = {
    perMonth: 0,
    perYear: 1,
}

export const ContractInfoValue = {
    ACTIVE: 0,
    UNACTIVE: 2,
    FREE: 1
}
