import { Action } from "app/models/redux/action";
import { ReduxCallbacks } from "app/models/redux/Callbacks";

export const GET_BOOKING_CALENDAR_BY_ID = "GET_BOOKING_CALENDAR_BY_ID";
export const GET_BOOKING_CALENDAR_BY_ID_SUCCESS = "GET_BOOKING_CALENDAR_BY_ID_SUCCESS";
export const GET_BOOKING_CALENDAR_BY_ID_FAILED = "GET_BOOKING_CALENDAR_BY_ID_FAILED";
export const GET_BOOKING_CALENDAR_BY_ID_CANCELLED = "GET_BOOKING_CALENDAR_BY_ID_CANCELLED";

export const CREATE_BOOKING_CALENDAR = "CREATE_BOOKING_CALENDAR";
export const CREATE_BOOKING_CALENDAR_SUCCESS = "CREATE_BOOKING_CALENDAR_SUCCESS";
export const CREATE_BOOKING_CALENDAR_FAILED = "CREATE_BOOKING_CALENDAR_FAILED";
export const CREATE_BOOKING_CALENDAR_CANCELLED = "CREATE_BOOKING_CALENDAR_CANCELLED";

export const SET_BOOKING_CALENDAR = "SET_BOOKING_CALENDAR";
export const CLEAR_BOOKING_CALENDAR = "CLEAR_BOOKING_CALENDAR";

export const SET_BOOKING_CALENDAR_COMPLETE = "SET_BOOKING_CALENDAR_COMPLETE";
export const CLEAR_BOOKING_CALENDAR_COMPLETE = "CLEAR_BOOKING_CALENDAR_COMPLETE";

/**
 * Actions create booking calendar
 * @param formId 
 * @param body 
 * @param callbacks 
 */
export const createBookingCalendar = (formId: any, body: any, callbacks: ReduxCallbacks) : Action => ({
  type: CREATE_BOOKING_CALENDAR,
  payload: { formId, body, callbacks }
});

/**
 * Actions create booking calendar success
 * @param payload 
 */
export const createBookingCalendarSuccess = (payload: any) : Action => ({
  type: CREATE_BOOKING_CALENDAR_SUCCESS,
  payload,
});

/**
 * Actions create booking calendar failed
 * @param error 
 */
export const createBookingCalendarFailed = (error: any) : Action => ({
  type: CREATE_BOOKING_CALENDAR_FAILED,
  payload: error,
});

/**
 * Actions cancel create booking calendar
 */
export const createBookingCalendarCancelled = () : Action => ({
  type: CREATE_BOOKING_CALENDAR_CANCELLED,
});

/**
 * Action get calendar by form id
 * @param formId 
 * @param callbacks 
 */
export const getCalendarByFormId = (formId: any | string, callbacks: ReduxCallbacks) : Action => ({
  type: GET_BOOKING_CALENDAR_BY_ID,
  payload: { formId, callbacks }
});

/**
 * Action get calendar by form id success
 * @param payload 
 */
export const getCalendarByFormIdSuccess = (payload: any) : Action => ({
  type: GET_BOOKING_CALENDAR_BY_ID_SUCCESS,
  payload,
});

/**
 * Action get calendar by form id failed
 * @param error 
 */
export const getCalendarByFormIdFailed = (error: any) : Action => ({
  type: GET_BOOKING_CALENDAR_BY_ID_FAILED,
  payload: error,
});

/**
 * Action cancel get calendar by form id
 */
export const getCalendarByFormIdCancelled = () : Action => ({
  type: GET_BOOKING_CALENDAR_BY_ID_CANCELLED,
});

/**
 * Action set form create complete
 * @param payload 
 */
export const setFormComplete = (payload: any) : Action => ({
  type: SET_BOOKING_CALENDAR_COMPLETE,
  payload,
});

/**
 * Action clear form complete
 * @param payload 
 */
export const clearFormComplete = (payload: any) : Action => ({
  type: CLEAR_BOOKING_CALENDAR_COMPLETE
});

/**
 * Action set booking calendar
 * @param payload 
 */
export const setBookingCalendar = (payload: any) : Action => ({
  type: SET_BOOKING_CALENDAR,
  payload,
});

/**
 * Action clear booking calendar
 */
export const clearBookingCalendar = () : Action => ({
  type: CLEAR_BOOKING_CALENDAR,
});