// Set text for all button and title using system.
export const TitleOrButton = {
	DisableBooking: {
		ACTIVE_BOOKING: '有効',
		INACTIVE_BOOKING: '無効',
	},
	Button: {
		ADD_BOOKING_FORM: '新しい予約フォームを作成',
		ADD_TAG: 'タグの新規作成',
		ADJUSTED_BOOKING: 'ノリスケ日程調整',
		BACK: '一覧に戻る',
		COPY_URL: 'URLコピー',
		COPY_FORM: '複製',
		COPY: '複製',
		CANCEL: 'キャンセル',
		DELETE_BOOKING: '予定を削除',
		EDIT: '編集',
		GOOGLE: 'Googleアカウントでログイン',
		MAIL_COPY: '変数コピー',
		POPUP_ENABLE: '予約フォームを有効にする',
		POPUP_DISABLE: '予約フォームを無効にする',
		SAVE: '保存',
		CREATE: '決定',
		BACK_TO_LISTING: 'タグの一覧に戻る',
		BACK_TO_SETING: '各種設定に戻る',
		DELETE_TAG: 'タグ削除',
		CLOSE_SCREEN: 'この画面を閉じる',
		BACK_TO_ACCOUNT: 'アカウントに戻る',
		DELETE_APPOINTMENT: '予定を削除',
		DELETE_FORM_BOOKING: '予約フォームを削除',
		YES: 'はい',
		SCHEDULE_CHANGE: '日程変更',
		RESERVATION_IS_DECIDED_BY_THE_ABOVE_CONTENTS: "上記の内容で予約を決定",
		RETURN_TO_SCHEDULE_SELECTION: '日程の選択に戻る',
		BACK_TO_FORM_LIST: '予約フォームの一覧に戻る',
		ADD_ITEM: "項目の追加",
		NEXT: '次へ',
		PREV: '前へ',
		START: '始める',
		SKIP: 'スキップ',
		LOGIN: "ログイン",
		SEARCH: '検索',
		CHANGE_TO_INVOICE: '請求書に変更',
		FREE: '無料化',
		MOVE_TO_CONTRACT_INFO_INFORMATION: 'ご契約情報に移動',
		REGIST_CONTRACT: "契約情報の登録",
		CHANGE_PLAN: "プランの変更",
		INVOICE_CHANGE_FORM: "こちら",
		CHANGE_PAYMENT: "お支払い情報の変更",
		BACK_TO_CONTRACT: "ご契約情報に戻る",
		CANCEL_CONTRACT: "解約する",
		TO_CANCEL_CONTRACT: "有料プランのご解約はこちら",
		TO_NORISUKEBIZHELP_FORM: "お問い合わせフォームへ",
		GO_NEXT: "次に進む",
		CHANGE_CARD_INFOMATION: "カード情報を変更する",
		CANCEL_CHANGE_CARD_INFOMATION: "カード情報の変更をキャンセル",
		REGIST_COMPLETE_TO_CONTRACT: "お支払い情報に戻る",
		REGIST_COMPLETE: "上記の内容で決定",
		CONTACT: "お問い合わせ"
	},
	TitleTopPage: {
		BOOKING_FORM: '予約フォーム一覧',
		BOOKING_TIMELINE: '予約一覧',
		TAG: 'タグ',
		TITLE_TAG: 'の詳細情報',
		CREATE_TAG: 'タグの新規作成',
		COPY_TAG: 'タグの複製',
		EDIT_TAG: 'タグの編集',
		UPDATE_PROFILE: 'プロフィール',
		FORM_DETAIL: 'ノリスケ初回用アポ',
		FORM_EDIT: '予約フォームの編集',
		CONTRACT_INFO: 'ご契約情報'
	},
	Title: {
		BOOKING_INFO: '基本情報',
		TAG_INFO_DETAIL: '基本情報',
		LOGIN_OPTIONS: 'ログインアカウントを選択',
		LOGIN_REQUIRE: '※G suiteをご利用のアカウントでのみご利用いただけます',
		LISTING_EMPTY: '予約フォームを作成すると\nここに表示されます',
		LISTING_ERROR: '予約フォーム一覧の取得ができません。',
		POPUP_ENABLE: '予約フォームを有効にする',
		POPUP_DISABLE: '予約フォームを無効にする',
		REMIND: '予約時のフォーム項目',
		INFO_TAG: '基本情報',
		DELETE_TAG: 'タグ削除',
		TAG_INFOMATION: 'タグの情報',
		TAG_LIST: 'タグ一覧',
		TIME_SUB_TITLE: '予約フォームを開いた時から',
		PROFILE_SETTING: 'プロフィール設定',
		ENTER_THE_FOLLOWING_CONTENT: "次に以下の内容を入力ください",
		FINISH_TITLE: '予約フォームを保存しました',
		REQUEST_APPOINTMENT_SCHEDULE_ADJUSTMENT: "ノリスケ日程調整のアポのお願い",
		CALENDAR_SELECT_ALERT: 'ご都合の良い日程を以下より選択してください。',
		SHOW_APPOINTMENTS_FOR_PAST_MONTH: "過去の{0}月の予定を表示",
		RESERVATION_DETAILS: "予約の詳細",
		DELETE_RESERVATION: "予約の削除",
		CREATE_FORM: "予約フォームの新規作成",
		COPY_FORM: "予約フォームの複製",
		DELETE_FORM_BOOKING: "予約フォームの削除",
		POPUP_NAME_COMPANY: '会社名を入力してください',
		POPUP_ACCOUNT_SUSPENSION: 'アカウントの停止',
		CHANGE_TO_INVOICE: '請求書に変更',
		WARNING: (date: any) => `有料期間が${date}に終了します。終了後は予約フォームの作成や、フォームを利用した日程調整は行えなくなりますので、ご注意ください。`,
		WARNING_2: (date: any) => `無料期間が${date}に終了します。終了後は予約フォームの作成や、フォームを利用した日程調整は行えなくなりますので、ご注意ください。`,
		WARNING_MOBILE: (date: any) => `有料期間が${date}に終了します。\n終了後は日程調整を行えなくなります。`,
		WARNING_2_MOBILE: (date: any) => `無料期間が${date}に終了します。\n終了後は日程調整を行えなくなります。`,
		WARNING_STOP: '無料期間が終了しています。予約フォームの作成や、フォームを利用した日程調整は行えません。',
		CONTRACT_INFO_SETTING: 'お支払い情報',
		CONTRACT_CANCEL: 'ご解約',
		CANCEL_QUESTIONNAIRE: 'アンケート',
		REGIST_PAYMENT_INFO: 'お支払い情報の登録'
	},
	TopBar: {
		COMPLETE_BOOKING: '予約が完了しました',
		SETTING: '各種設定',
		PROFILE: 'プロフィール',
		ACCOUNT: 'アカウント',
		RESERVATION_FORM: '予約フォーム',
		SETTING_FORM_DEFAULT: '予約フォームの初期設定',
		SIGNOUT: 'ログアウト',
		TUTORIAL: '使い方ガイド',
		CONTRACT: 'ご契約情報'
	},
	Description: {
		POPUP_ENABLE: '予約フォームを有効にすると、URLを共有することはできるようになります。',
		POPUP_ACC_SUSPENSIONS: `このアカウントを停止します。\n 停止されたアカウントは各種機能を利用できなくなります。\n\n 本当に停止してもよろしいですか？`,
		POPUP_DISABLE:
			'予約フォームを無効にすると、すでに共有しているURLも含め、フォームにアクセスすることが出来なくなります。',
		TEXT: '本文',
		TAG_EMPTY: 'タグを作成すると\nここに表示されます',
		BOOKING_EMPTY: '予約が行われると\nここに表示されます',
		USER_EMPTY: 'プロファイルなし',
		SELECT_TIME_BOOKING_EMPTY: 'この日付には予約できる日程がありません。\n他の日付を再選択してください。',
		POPUP_DELETE_TAG: 'Description Delete Tag',
		CALENDAR_TITLE_DES: '備考の部分にどちらの製品のアポをご希望かご記入ください。',
		CALENDAR_TITLE_DES_2: '例）ノリスケ日程調整、ノリスケ採用管理',
		TOP_PAGE_SETTING_FORM_DEFAULT: '予約フォームの新規作成の際に、予め初期入力として表示される情報を変更できます。',
		BOOKING_FORM_ITEMS: "フォームを受け取った方が予約を行う際に、どんな情報を入力必須として予約を行っていただくかの項目設定・編集が行えます。項目を入力必須としたい際は「必須」にチェックを入れてください。\nまた「複数行」にチェックを入れると改行入力が可能になりますので、文字量が多い項目を選択させたい場合はこちらにもチェックを入れてください。",
		LOGO_DES: "厚生労働省（別窓リンク）",
		COPY_URL_AND: "以下のURLをコピーして、",
		SHARE_AND_SCHEDULE_ADJUSTMENT: " 共有するだけで日程調整が完了します",
		POPUP_NAME_COMPANY: 'まずは会社名を入力してください。会社名は自動送信メールの文面などに使用することができます。',
		TOTAL_USER: (totalUsers: any) => `件のアカウントが見つかりました（総数${totalUsers}件）`,
		INVOICE_CHANGE_INFO1: '銀行振り込みの場合の料金プラン・お支払い方法の変更は',
		INVOIC_CHANGE_INFO2: 'よりお問い合わせください',
		INVOICE_CHANGE_INFO1_MOBILE: '銀行振り込みの場合の料金プラン・お支払い方法の',
		INVOICE_CHANGE_INFO2_MOBILE: '変更は',
		INVOICE_CHANGE_INFO3_MOBILE: 'よりお問い合わせください',
		CANCEL_CREDIT_CARD: '有効期限が切れると予約フォームの作成や、フォームを利用した日程調整は行えなくなります。\nすでに共有したURLなどがご利用いただけなくなるのでご注意ください。',
		MOBILE_CANCEL_CREDIT_CARD: '有効期限が切れると予約フォームの作成や、フォーム\nを利用した日程調整は行えなくなります。\nすでに共有したURLなどがご利用いただけなくなるので\nご注意ください。',
		CANCEL_INVOICE: '法人プランの方は、下記のリンクよりアクセスできるフォームよりお問い合わせください。',
		MOBILE_CANCEL_INVOICE: '法人プランの方は、下記のリンクより\nアクセスできるフォームよりお問い合わせください。',
		REGIST_SELECT_PLAN: '以下の内容でお支払い情報の登録を行います。\nご確認の上、よろしければ次に進むボタンを押してください。',
		MOBILE_REGIST_SELECT_PLAN: '以下の内容でお支払い情報の登録を行います。\nご確認の上、よろしければ次に進むボタンを\n押してください。',
		REGIST_PAYMENT_METHOD: 'お支払い方法を選択してください。',
		REGIST_PAYMENT_COMPLETE: '以下の内容でお支払い情報を登録しました。'
	},
	Question: {
		BOOK_APPOINTMENT: 'アポを予約できる期間とは？',
		FREE_TIME: 'アポの前後の空き時間とは？',
		POPUP_ENABLE: '有効にしてもよろしいでしょうか？',
		POPUP_DISABLE: '無効にしてもよろしいでしょうか？',
		TIME_BOOK_APPOINTMENT: 'アポを予約できる期間とは？',
		POPUP_DELETE_TAG: 'このタグを削除しますが、よろしいですか。',
		CONFIRM_SIGN_OUT: 'ノリスケ日程調整からログアウトしますか？',
		CONFIRM_SIGN_OUT_ADMIN: 'ノリスケ日程調整の管理者画面からログアウトします。よろしいですか?',
		DELETE_TAG_USED: `上記のタグは予約フォームに使用されます。\n上記のタグを削除すると予約フォームからも該当タグが削除されます。\nこの操作をもとに戻すことはできません。\nよろしいですか？`,
		DELETE_TAG_NOT_USED: `上記のタグを削除します。\nこの操作をもとに戻すことはできません。\nよろしいですか？`,
		DELETE_BOOKING: "上記の予約を削除します。",
		DELETE_BOOKING_ALERT: "この操作をもとに戻すことはできません。",
		DELETE_BOOKING_CONFIRM: "よろしいですか？",
	},
	Label: {
		APPOINTMENT_TIME_REQUIRED: '1回のアポにかかる時間',
		APPOINTMENT_TIME_FREE: 'アポの前後の空き時間',
		APPOINTMENT_SETTINGS: 'アポの設定',
		APPOINTMENT_TIME: 'アポを入れる曜日・時間',
		ABOUT_BASIC_SETTING: 'この内容を基本設定にするについて',
		BOOKING_NAME: 'タイトル',
		BOOKING_DESC: '説明',
		BREAK_TIME: '休憩時間',
		BOOKING_THANK: 'アポの予約ありがとうございます',
		BASIC_SETTING: 'この内容を基本設定にする',
		CALENDAR_SCHEDULE: '日程調整を行うカレンダー',
		EMAIL: 'メール',
		EMAIL_THANKS: '予約完了のお礼メール',
		EMAIL_THANKS_EDIT: '予約完了お礼メールの編集',
		EMAIL_REMIND_EDIT: 'アポ前日のお知らせメールの編集',
		OTHER: 'その他',
		MAIL: '変数の挿入',
		METHOD_ADJUST_SCHEDULE_FORM: '日程調整の方法',
		UNTIL: 'まで',
		REMARKS: '備考',
		SettingsSchedule: '日程調整の設定',
		SUBJECT: '件名',
		TAG: 'タグ',
		TAG_NAME: 'タグ名',
		TAG_COLOR: 'タグの色',
		DELETE_TAG: 'タグ削除',
		REQUIRED: '(必須)',
		USER_NAME: 'ユーザー名',
		MAIL_ADDRESS: 'メールアドレス',
		COMPANY: '会社名',
		DATE_SELECTED: '選択した日付',
		FORM_NAME: 'タイトル',
		MAIL_REMIND: '明日のアポについてのご連絡',
		NOT_SET: "設定しない",
		SET: "設定する",
		REQUIRED_2: "必須",
		MULTI_LINE: "複数行",
		ITEM_NAME: "項目名",
		EX: "例 ) 備考",
		NO_HYPHEN: "（ハイフンなし）",
		BOOKED_DATE: "予約した日程",
		PLS_CLOSE_SCREEN: "このまま画面を閉じてください",
		AND: "全員が参加（全員が空いている日程を表示させる）",
		OR: "誰か1人が参加（1人以上が空いている日程を表示させる)",
		POPUP_NAME_COMPANY: '※設定より後から入力することもできます。',
		PASSWORD: "パスワード",
		END_DATE: "最終アクセス日",
		COMPANY_NAME: "企業名",
		FULL_NAME: "氏名",
		NUMBER_OF_SCHEDULE_ADJUSTMENTS_CUMULATIVE: "日程調整回数（累計）",
		NUMBER_OF_SCHEDULE_ADJUSTMENTS_30_DAYS: "直近30日 / 調整累計",
		LAST_ACCESS_DATE_TO_THE_FORM: "フォームへの最終アクセス日",
		CONTRACT_STATUS: "契約情報",
		PAYMENT_METHOD: '決済方法',
		PRICE: '金額（税込)',
		NOTE: 'メモ',
		CONTRACT_STATUS_TABLE_HEADER: '契約状況',
		CONTRACT_STATUS_INVALID: '解約済み',
		CONTRACT_STATUS_VALIDITY: '契約中',
		START_DATE: '利用開始日',
		CONTRACT_PERIOD: '契約期間',
		CONTRACT_METHOD: '契約方法',
		CONTRACT_START_DATE: '契約開始日',
		BILLING_INTERVAL: '金額 / 請求間隔',
		PERSON_IN_CHARGE: '担当者',
		PHONE_NUMBER: '担当者電話番号',
		PHONE: '電話番号',
		ORGANIZATION_NAME: '組織名',
		CONTRACT_EMAIL_ADDRESS: '担当者メールアドレス',
		APPLICATION_ACCOUNT_NUMBER: 'お申し込みアカウント数',
		INVOICE: '請求書',
		PAYMENT_METHOD_MONTH: '円 / 月',
		PAYMENT_METHOD_YEAR: '円 / 年',
		ADMIN_FILTER_CONTRACT_STATUS: '契約状態',
		ADMIN_FILTER_PAYMENT_METHOD: '決済方法',
		PLAN_FEE: "料金プラン",
		PAYMENT_METHOD2: "お支払い方法",
		CANCEL_REASON: "解約理由",
		SELECT_PLAN: "プランの選択",
		SELECT_PLAN_CONTRACT: "ご契約プラン",
		SELECT_PAYMENT_METHOD: "お支払い方法の選択",
		REGIST_COMPLETE: "登録完了",
		REGISTED_CREDIT_INFO: "登録中のカード情報"
	},
	Placeholder: {
		FORM_NAME: '例 ) ノリスケ日程調整のアポについて',
		FORM_DESC: '例）日程調整は「ノリスケ日程調整」というツールを利用しております。\n下記のURLからご設定をお願いいたします。',
		MAIL: 'メールアドレス',
		SELECT: '選択する',
		TAG_NAME: '例 ) 初回アポ用',
		COMPANY: '会社名',
		COMPANY_POPUP: "例 ) 株式会社ノリスケ",
		USER_NAME: 'ユーザー名',
		URL: 'URLコピー',
		EMAIL_EX: "example@example.jp"
	},
	Note: {
		APPOINTMENT_RESERVED: 'アポを予約できる期間',
		APPOINTMENT_NOTIFY: 'アポ前日のお知らせメール',
		BOOKING_NOTE: '入力された基本情報は、フォームを受け取った方が予約を行う際、本アポの内容の説明として表示されます。',
		BOOKING_NOTE_FORM_ITEM: 'フォームを受け取った方が予約を行う際に必須で記入いただきたい項目を設定できます。\n追加項目を設けたい場合は＞マークより追加ください。',
		BOOKING_NOTE_TAG: '作成するフォームにタグ付けを行うことが可能です。商材ごと・参加者ごと等自由に管理しやすいように、任意でタグの付与が行えます。（フォームを受け取る方には表示はされません）',
		BOOKING_NOTE_APPOINTMENT_SETTINGS: 'アポイント設定可能な時間の設定を行います。休日設定、お昼の休憩時間やアポの前後の移動時間等アポが入らないように設定しておくことが可能です。',
		BOOKING_NOTE_CALENDAR_SCHEDULE: `日程調整を行いたい方のカレンダーを選択します。複数人での設定や会議室の選択も可能です。（※会議室を選択する場合は「全員が参加」をご選択ください。「誰か1人が参加」設定にしてしまうと会議室のみが日程調整されてしまう場合がございます。予約可能なカレンダーを増やしたい場合は、こちら(<a class= 'norisuke-form-explain-url'  target = '_blank' href = 'http://lp.norisukebiz.jp/how-calendar-access.html' > http://lp.norisukebiz.jp/how-calendar-access.html</a>)をご確認ください）`,
		BOOKING_NOTE_MAIL: '各種メールを自動送信する際の内容を設定しておくことが可能です。\n・予約完了のお礼メール：日程調整の完了の際に自動送信されるメール\n・アポ前日のお知らせメール：前日17時に自動送信されるリマインドメール',
		NOT_TAGGED: 'タグが設定されていません',
		NOT_CALENDAR: 'カレンダーが設定されていません',
		UNABLE_CHANGE: '(変更不可)',
		TEXT_DEFAULT: '予約フォーム',
		BOOKING_ADJUST: '予定作成者以外への予約完了メール・リマインドメールの送信を行いたい場合は、Googleカレンダーの「名前」がメールアドレスの場合のみメール送信可能となります。',
		CONTRACT_UNREGISTER: 'お支払い情報が未登録です。\n契約情報の登録を行ってください。',
		NEXT_BILLING_DATE: '次回ご請求日',
		CHANGE_BILLING_METHOD_NOTE: '*次回請求日分からお支払い方法が変更されます。',
		CONTRACT_CANCEL: '解約手続きをしても、有効期限満了まで\nご利用いただけます。',
		CANCEL_QUESTIONNAIRE: '解約理由について、今後のサービス向上のため、お手数ですが下記アンケートへのご協力をお願いします。\n当てはまるものにチェックを入れてください。',
		SELECT_METHOD_BANK_TRANSFER: '※複数人のお支払いはこちらをお選びください\n担当者からご連絡をさせていただきます。',
		AVAILABLE_CREDIT_CARD: 'ご利用いただけるクレジットカード',
		CREDIT_CARD_MARK_NOTE: '以下のマークがついているクレジットカードを\nご利用いただけます',
		ACCOUNT_NUMBER_NOTE: '※会社内で複数アカウントをご利用の場合は、\nアカウント数を入力してください。'
	},
	Date: {
		DATE_FORMAT: "{0}年{1}月{2}日",
		YEAR_MONTH_FORMAT: "{0}/{1}月",
		START_END_FORMAT: "{0} 〜 {1}",
		DATE_START_END_FORMAT: "{0}年{1}月{2}日 {3} 〜 {4}",
		MONTH: "月"
	},
	Email: {
		SUBJECT_THANKS: "",
		BODY_THANKS: "",
		SUBJECT_REMIND: "",
		BODY_REMIND: "",
	},
	FormItem: {
		OPTIONS_GROUP_PARTNER: '相手の情報',
		OPTIONS_GROUP_MY_PROFILE: '自分の情報',
		MY_NAME: '自分の名前',
		MY_COMPANY: '自分の企業名',
		MY_EMAIL: '自分のEmail'
	},
	NotForud: {
		TITLE: "URLは正しくありません",
	},
	Booking: {
		FORM_DELETED: "削除されたフォーム"
	},
	PlanStatus: {
		UNREGIST: '未登録',
		MONTHLY: '月払い',
		YEARLY: '年払い (10%OFF)',
		YEN: '円 (税込)'
	},
	PaymentStatus: {
		CREDITCARD: 'クレジットカード払い',
		BANKINVOICE: '銀行振り込み'
	},
	PaymentInfoLabel: {
		CARD_NUMBER: 'カード番号',
		CARD_NAME: '名義人',
		CARD_EXP: '有効期限',
		CARD_CVC: 'セキュリティコード',
		CARD_NUMBER_SECURTY: '****-****-****-',
		CARD_CVC_SECURTY: '***',
		INVOICE_CONTACT_PERSON: '担当者',
		INVOICE_COMPANY_NAME: '組織名',
		INVOICE_MAIL_ADDRESS: '担当者メールアドレス',
		INVOICE_PHONE_NUMBER: '担当者電話番号'
	},
	StepIndicator: {
		SELECT_PLAN: "プランの選択",
		SELECT_PAYMENT_METHOD: "お支払い方法の選択",
		COMPLETE: "完了"
	},
	StepIndicatorDot: {
		PC: "･････････････････････････",
		MOBILE: "････"
	},
	PlanCard: {
		SELECTED: "選択中",
		MONTHLY: '月払い',
		YEARLY: '年払い',
		PERCENT_OFF: '(10%OFF)',
		MONTHLY_YEN: '円/月（税込）',
		YEAR_LABEL: '年間',
		YEN: '円',
		COMPANYPLAN: '法人プラン',
		COMPANYNOTE: "複数人や法人でのご利用は\nお問い合わせください"
	},
	BankForm: {
		ACCOUNT: 'アカウント'
	}
};
