import { Api } from "../api"
import { EndPointApis } from 'app/constants';
import { CancelUserContract } from 'app/redux/modules/contracts';
/**
 * Service Contract
 */
export class ContractService {
    /**
     * get User Contract
     */
    static getUserContract() {
        return Api.get(EndPointApis.Contract.GET_USER_CONTRACT);
    }

    /**
   * Update form default
   * @param params
   */
  static cancelUserContract(params: CancelUserContract) {
    return Api.put(`${EndPointApis.Contract.CANCEL_USER_CONTRACT}`, { ...params });
  }
}