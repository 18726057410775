import { Action } from "app/models/redux/action";
import { ReduxCallbacks } from "app/models/redux/Callbacks";
import { ListQuery } from "app/models/redux/ListQuery";
export const LOGIN_ADMIN = "LOGIN_ADMIN";
export const LOGIN_ADMIN_SUCCESS = "LOGIN_ADMIN_SUCCESS";
export const LOGIN_ADMIN_FAILED = "LOGIN_ADMIN_FAILED";
export const LOGIN_ADMIN_CANCELLED = "LOGIN_ADMIN_CANCELLED";


export const LOGOUT_ADMIN = "LOGOUT_ADMIN";
export const LOGOUT_ADMIN_SUCCESS = "LOGOUT_ADMIN_SUCCESS";

/**
 * Action LOGIN_ADMIN
 * @param body 
 * @param callbacks 
 */
export const loginAdmin = (
  body: any,
  callbacks?: ReduxCallbacks
): Action => ({
  type: LOGIN_ADMIN,
  payload: { body, callbacks },
});

/**
 * Action LOGIN_ADMIN_SUCCESS
 * @param payload 
 */
export const loginAdminSuccess = (
  payload: any[]
): Action<any[]> => ({
  type: LOGIN_ADMIN_SUCCESS,
  payload,
});

/**
 * Action LOGIN_ADMIN_FAILED
 * @param error 
 */
export const loginAdminFailed = (error: any): Action => ({
  type: LOGIN_ADMIN_FAILED,
  payload: error,
});

/**
 * Action LOGIN_ADMIN_CANCELLED
 */
export const loginAdminCancelled = (): Action => ({
  type: LOGIN_ADMIN_CANCELLED,
});

export const GET_REPORT = "GET_REPORT";
export const GET_REPORT_SUCCESS = "GET_REPORT_SUCCESS";
export const GET_REPORT_FAILED = "GET_REPORT_FAILED";
export const GET_REPORT_CANCELLED = "GET_REPORT_CANCELLED";

/**
 * Action GET_REPORT
 * @param params 
 * @param callbacks 
 */
export const getReport = (
  params: ListQuery,
  callbacks?: ReduxCallbacks
): Action => ({
  type: GET_REPORT,
  payload: { params, callbacks },
});

/**
 * Action GET_REPORT_SUCCESS
 * @param payload 
 */
export const getReportSuccess = (
  payload: any[],
): Action<any[]> => ({
  type: GET_REPORT_SUCCESS,
  payload,
});

/**
 * Action GET_REPORT_FAILED
 * @param error 
 */
export const getReportFailed = (error: any, callbacks?: ReduxCallbacks): Action => ({
  type: GET_REPORT_FAILED,
  payload: { error, callbacks },
});

/**
 * Action GET_REPORT_CANCELLED
 */
export const getReportCancelled = (): Action => ({
  type: GET_REPORT_CANCELLED,
});

/**
 * Action logout user
 * @param callbacks 
 */
export const logoutAdmin = (callbacks?: ReduxCallbacks): Action => ({
  type: LOGOUT_ADMIN,
  payload: { callbacks },
});

/**
 * Action logout user success
 */
export const logoutAdminSuccess = (): Action => ({
  type: LOGOUT_ADMIN_SUCCESS,
});


/**
 *  Action Plans list
 */


export const GET_PAYMENT_PLANS = "GET_PAYMENT_PLANS";
export const GET_PAYMENT_PLANS_SUCCESS = "GET_PAYMENT_PLANS_SUCCESS";
export const GET_PAYMENT_PLANS_FAILED = "GET_PAYMENT_PLANS_FAILED";
export const GET_PAYMENT_PLANS_CANCELLED = "GET_PAYMENT_PLANS_CANCELLED";


/**
 * Action GET_PAYMENT_PLANS
 * @param params 
 * @param callbacks 
 */
export const getPaymentPlans = (
  callbacks?: ReduxCallbacks
): Action => ({
  type: GET_PAYMENT_PLANS,
  payload: { callbacks },
});
/**
 * Action GET_PAYMENT_PLANS_SUCCESS
 * @param payload 
 */
export const getPaymentPlansSuccess = (
  payload: any[],
): Action<any[]> => ({
  type: GET_PAYMENT_PLANS_SUCCESS,
  payload,
});

/**
 * Action GET_PAYMENT_PLANS_FAILED
 * @param error 
 */
export const getPaymentPlansFailed = (error: any, callbacks?: ReduxCallbacks): Action => ({
  type: GET_PAYMENT_PLANS_FAILED,
  payload: { error, callbacks },
});

/**
 * Action GET_PAYMENT_PLANS_CANCELLED
 */
export const getPaymentPlansCancelled = (): Action => ({
  type: GET_PAYMENT_PLANS_CANCELLED,
});


/**
 *  Action Plans list
 */
export const UPDATE_PAYMENT_MEMO = "UPDATE_PAYMENT_MEMO";
export const UPDATE_PAYMENT_MEMO_SUCCESS = "UPDATE_PAYMENT_MEMO_SUCCESS";
export const UPDATE_PAYMENT_MEMO_FAILED = "UPDATE_PAYMENT_MEMO_FAILED";
export const UPDATE_PAYMENT_MEMO_CANCELLED = "UPDATE_PAYMENT_MEMO_CANCELLED";


/**
 * Action UPDATE_PAYMENT_MEMO
 * @param params 
 * @param callbacks 
 */
export const updatePaymentMemo = (
  data: any,
  callbacks?: ReduxCallbacks
): Action => {

  return ({
    type: UPDATE_PAYMENT_MEMO,
    payload: { data, callbacks },
  })
};
/**
 * Action UPDATE_PAYMENT_MEMO_SUCCESS
 * @param payload 
 */
export const updatePaymentMemoSuccess = (
  payload: any[],
): Action<any[]> => ({
  type: UPDATE_PAYMENT_MEMO_SUCCESS,
  payload,
});

/**
 * Action UPDATE_PAYMENT_MEMO_FAILED
 * @param error 
 */
export const updatePaymentMemoFailed = (error: any, callbacks?: ReduxCallbacks): Action => ({
  type: UPDATE_PAYMENT_MEMO_FAILED,
  payload: { error, callbacks },
});

/**
 * Action UPDATE_PAYMENT_MEMO_CANCELLED
 */
export const updatePaymentMemoCancelled = (): Action => ({
  type: UPDATE_PAYMENT_MEMO_CANCELLED,
});


/**
 *  Action Plans list
 */
export const DEACTIVATE_USE = "DEACTIVATE_USE";
export const DEACTIVATE_USE_SUCCESS = "DEACTIVATE_USE_SUCCESS";
export const DEACTIVATE_USE_FAILED = "DEACTIVATE_USE_FAILED";
export const DEACTIVATE_USE_CANCELLED = "DEACTIVATE_USE_CANCELLED";


/**
 * Action DEACTIVATE_USE
 * @param params 
 * @param callbacks 
 */
export const deactivateUser = (
  data: any,
  callbacks?: ReduxCallbacks
): Action => {

  return ({
    type: DEACTIVATE_USE,
    payload: { data, callbacks },
  })
};
/**
 * Action DEACTIVATE_USE_SUCCESS
 * @param payload 
 */
export const deactivateUserSuccess = (
  payload: any[],
): Action<any[]> => ({
  type: DEACTIVATE_USE_SUCCESS,
  payload,
});

/**
 * Action DEACTIVATE_USE_FAILED
 * @param error 
 */
export const deactivateUserFailed = (error: any, callbacks?: ReduxCallbacks): Action => ({
  type: DEACTIVATE_USE_FAILED,
  payload: { error, callbacks },
});


/**
 *  Action Update invoice
 */
export const UPDATE_INVOICE = "UPDATE_INVOICE";
export const UPDATE_INVOICE_SUCCESS = "UPDATE_INVOICE_SUCCESS";
export const UPDATE_INVOICE_FAILED = "UPDATE_INVOICE_FAILED";
export const UPDATE_INVOICE_CANCELLED = "UPDATE_INVOICE_CANCELLED";


/**
 * Action UPDATE_INVOICE
 * @param params 
 * @param callbacks 
 */
export const updateInvoice = (
  data: any,
  callbacks?: ReduxCallbacks
): Action => {
  return ({
    type: UPDATE_INVOICE,
    payload: { data, callbacks },
  })
};
/**
 * Action UPDATE_INVOICE_SUCCESS
 * @param payload 
 */
export const updateInvoiceSuccess = (
  payload: any[],
): Action<any[]> => ({
  type: UPDATE_INVOICE_SUCCESS,
  payload,
});

/**
 * Action UPDATE_INVOICE_FAILED
 * @param error 
 */
export const updateInvoiceFailed = (error: any, callbacks?: ReduxCallbacks): Action => ({
  type: UPDATE_INVOICE_FAILED,
  payload: { error, callbacks },
});

/**
 * Action UPDATE_PAYMENT_MEMO_CANCELLED
 */
export const updateInvoiceCancelled = (): Action => ({
  type: UPDATE_PAYMENT_MEMO_CANCELLED,
});


