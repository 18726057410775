import { ActionsObservable, ofType } from "redux-observable";
import { map, catchError, takeUntil, mergeMap } from "rxjs/operators";
import { of } from "rxjs";
import { Action } from "app/models/redux/action";
import { onEpicSuccess, onEpicFailed } from "app/helpers/reduxEpic";
import { CANCEL_ALL_REQUEST } from "app/redux/default";
import * as Actions from "./actions";
import { AdminService } from "app/services/admin";
import { BookingsFormService } from "app/services/forms";

/**
 * Watch action type and request to LoginAdmin
 * @param action$ 
 */
export const loginAdminEpic = (action$: ActionsObservable<Action>) =>
  action$.pipe(
    ofType(Actions.LOGIN_ADMIN),
    mergeMap((action) =>
      AdminService.loginAdmin(action.payload.body).pipe(
        map((ajaxResponse) => {
          const { response } = ajaxResponse;
          const { status } = response;
          if (status) {
            return onEpicSuccess(
              action,
              ajaxResponse,
              Actions.loginAdminSuccess(response),
              false,
              true
            )
          }
        }),
        catchError((error) => {
          return of(
            onEpicFailed(
              action,
              error,
              Actions.loginAdminFailed(error),
              false,
              true
            )
          );
        }),
        takeUntil(
          action$.pipe(
            ofType(Actions.LOGIN_ADMIN_CANCELLED, CANCEL_ALL_REQUEST)
          )
        )
      )
    )
  );

/**
 * Watch action type and request to get report data
 * @param action$ 
 */
export const getReportEpic = (action$: ActionsObservable<Action>) =>
  action$.pipe(
    ofType(Actions.GET_REPORT),
    mergeMap((action) =>
      AdminService.getListReport(action.payload.params).pipe(
        map((ajaxResponse) => {
          const { response } = ajaxResponse;
          const { status } = response;
          if (status) {
            return onEpicSuccess(
              action,
              ajaxResponse,
              Actions.getReportSuccess(response),
              false,
              true
            )
          }
        }),
        catchError((error) => {
          return of(
            onEpicFailed(
              action,
              error,
              Actions.getReportFailed(error),
              false,
              true
            )
          );
        }),
        takeUntil(
          action$.pipe(
            ofType(Actions.GET_REPORT_CANCELLED, CANCEL_ALL_REQUEST)
          )
        )
      )
    )
  );


/**
* Watch action type and request to get report data
* @param action$ 
*/
export const getPaymentPlansEpic = (action$: ActionsObservable<Action>) =>
  action$.pipe(
    ofType(Actions.GET_PAYMENT_PLANS),
    mergeMap((action) =>
      AdminService.getPaymentPlans().pipe(
        map((ajaxResponse) => {
          const { response } = ajaxResponse;
          const { status } = response;
          if (status) {
            return onEpicSuccess(
              action,
              ajaxResponse,
              Actions.getPaymentPlansSuccess(response.data?.records as any[]),
              false,
              true
            )
          }
        }),
        catchError((error) => {
          return of(
            onEpicFailed(
              action,
              error,
              Actions.getPaymentPlansFailed(error),
              false,
              true
            )
          );
        }),
        takeUntil(
          action$.pipe(
            ofType(Actions.GET_PAYMENT_PLANS_CANCELLED, CANCEL_ALL_REQUEST)
          )
        )
      )
    )
  );


/**
* Watch action type and request to update payment memo
* @param action$ 
*/
export const updatePaymentMemoEpic = (action$: ActionsObservable<Action>) =>
  action$.pipe(
    ofType(Actions.UPDATE_PAYMENT_MEMO),
    mergeMap((action) => {
      return AdminService.updatePaymentMemo(action.payload.data).pipe(
        map((ajaxResponse) => {
          const { response } = ajaxResponse;
          const { status } = response;
          if (status) {
            return onEpicSuccess(
              action,
              ajaxResponse,
              Actions.updatePaymentMemoSuccess(response.data?.records as any[]),
              false,
              true
            )
          }
        }),
        catchError((error) => {
          return of(
            onEpicFailed(
              action,
              error,
              Actions.updatePaymentMemoFailed(error),
              false,
              true
            )
          );
        }),
        takeUntil(
          action$.pipe(
            ofType(Actions.UPDATE_PAYMENT_MEMO_CANCELLED, CANCEL_ALL_REQUEST)
          )
        )
      )
    }

    )
  );


/**
* Watch action type and request to DEACTIVATE_USE
* @param action$ 
*/
export const deactivateUserEpic = (action$: ActionsObservable<Action>) =>
  action$.pipe(
    ofType(Actions.DEACTIVATE_USE),
    mergeMap((action) => {
      return AdminService.deactivateUser(action.payload.data).pipe(
        map((ajaxResponse) => {
          const { response } = ajaxResponse;
          const { status } = response;
          if (status) {
            return onEpicSuccess(
              action,
              ajaxResponse,
              Actions.deactivateUserSuccess(response.data?.records as any[]),
              false,
              true
            )
          }
        }),
        catchError((error) => {
          return of(
            onEpicFailed(
              action,
              error,
              Actions.deactivateUserFailed(error),
              false,
              true
            )
          );
        }),
        takeUntil(
          action$.pipe(
            ofType(Actions.DEACTIVATE_USE_CANCELLED, CANCEL_ALL_REQUEST)
          )
        )
      )
    }

    )
  );

/**
* Watch action type and request to update invoice
* @param action$ 
*/
export const updateInvoiceEpic = (action$: ActionsObservable<Action>) =>
  action$.pipe(
    ofType(Actions.UPDATE_INVOICE),
    mergeMap((action) => {
      return AdminService.updateInvoice(action.payload.data).pipe(
        map((ajaxResponse) => {
          const { response } = ajaxResponse;
          const { status } = response;
          if (status) {
            return onEpicSuccess(
              action,
              ajaxResponse,
              Actions.updateInvoiceSuccess(response.data?.records as any[]),
              false,
              true
            )
          }
        }),
        catchError((error) => {
          return of(
            onEpicFailed(
              action,
              error,
              Actions.updateInvoiceFailed(error),
              false,
              true
            )
          );
        }),
        takeUntil(
          action$.pipe(
            ofType(Actions.UPDATE_INVOICE_CANCELLED, CANCEL_ALL_REQUEST)
          )
        )
      )
    }

    )
  );





/**
 * Handle when action is LOGOUT ADMIN
 * @param action$ 
 */
export const logoutAdminEpic = (action$: ActionsObservable<Action>) =>
  action$.pipe(
    ofType(Actions.LOGOUT_ADMIN),
    map((action) => onEpicSuccess(action, null, Actions.logoutAdminSuccess()))
  );

