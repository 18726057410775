import React from "react";
import styled, { keyframes } from "styled-components";

interface IProps {}

interface IState {
  loading: boolean;
  opacity?: number;
}

class LoadingOverlay extends React.PureComponent<IProps, IState> {
  _timer?: number;
  constructor(props: IProps) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  componentWillUnmount() {
    this.clearTimer();
  }

  setLoading = (loading: boolean, opacity?: number) => {
    if (loading !== this.state.loading) {
      this.setState({
        loading,
        opacity,
      });

      this.setTimerForLoading();
    }
  };

  clearTimer = () => {
    if (this._timer) {
      clearTimeout(this._timer);
    }
  };

  setTimerForLoading = () => {
    this.clearTimer();
    this._timer = setTimeout(() => {
      this.setState({
        loading: false,
      });
    }, 30 * 1000);
  };

  render() {
    const { loading, opacity } = this.state;
    return (
      <Overlay loading={loading ? 1 : 0} opacity={opacity}>
        <CircularProgress />
      </Overlay>
    );
  }
}

export default LoadingOverlay;

const Overlay = styled.div<{ loading: boolean | number; opacity?: number }>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10000;
  display: ${({ loading }: any) => (loading ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  background-color: ${({ opacity }: any) => {
    if (!opacity) {
      opacity = 0.5;
    }

    return "rgba(255, 255, 255, " + opacity + ")";
  }};
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const CircularProgress = styled.div`
  display: inline-block;
  animation: ${rotate} 2s linear infinite;
  width: 65px;
  height: 65px;
  border: 4px solid #2196f3;
  border-radius: 50%;
  border-top-color: transparent;
`;
