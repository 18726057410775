import { Api } from "../api";
import { ListQuery } from "app/models/redux/ListQuery";
import { EndPointApis } from "app/constants";

export class TagsService {
  /**
   * Get tag list
   * @param query 
   */
  static getTagsList(query: ListQuery) {
    return Api.getNoQuery(
      `${EndPointApis.Tag.INDEX}?page=${query.page}&per_page=${query.per_page}`
    );
  }

  /**
   * Get all tag color
   */
  static getTagColors() {
    return Api.getNoQuery(EndPointApis.Tag.COLOR);
  }

  /**
   * Get tag detail
   * @param id 
   */
  static getTagDetail(id: number) {
    return Api.getNoQuery(`${EndPointApis.Tag.INDEX}/${id}`);
  }

  /**
   * Create tag
   * @param body 
   */
  static createTag(body: any) {
    return Api.post(EndPointApis.Tag.INDEX, body);
  }

  /**
   * Edit tag
   * @param id 
   * @param body 
   */
  static editTagDetail(id:any, body: any) {
    return Api.put(`${EndPointApis.Tag.INDEX}/${id}`, body);
  }

  /**
   * Delete tag
   * @param id 
   */
  static deleteTag(id:any) {
    return Api.delete(`${EndPointApis.Tag.INDEX}/${id}`);
  }

  /**
   * Check is use tag
   * @param id 
   */
  static checkIsUseTag(id:any) {
    return Api.getNoQuery(EndPointApis.Tag.USED(id));
  }
}
