import { Action } from "../../../models/redux/action";

export const ENABLE_WARNING = "ENABLE_WARNING";
export const DISABLE_WARNING = "DISABLE_WARNING";


/**
 * Action enable warning
 * @returns 
 */
export const enableWarning = (): Action => ({
	type: ENABLE_WARNING
});

/**
 * Action disable warning
 * @returns 
 */
export const disableWarning = (): Action => ({
	type: DISABLE_WARNING
});