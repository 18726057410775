import React from "react";
import { useSelector } from "react-redux";
import { AuthLayout } from "../../layouts/AuthLayout";
import { Redirect } from "react-router-dom";
import { RoutePaths } from "../../constants/routes";
import Login from "../../pages/auth/Login";

/**
 * Component AuthPages
 */
export const AuthPages = (props: any) => {
	const user = useSelector((state: any) => state.auth.user);

	if (user) {
		return <Redirect  to={RoutePaths.BookingForm.Index} />; 
	}

	return (
		<AuthLayout>
			<Login {...props} />
		</AuthLayout>
	);
};
