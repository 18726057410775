// Booking list model
export default class BookingListModel {
  id: number;
  form_id_encode ? : any;
  name?: string;
  description?: string;
  isActive?: number;
  tags?: Array<{
    tag_name: string;
    back_color: string;
  }>;

  constructor() {
    this.id = 0;
  }
  /**
   * Parse from response
   * @param data 
   */
  static parseFromResponse = (data: any): BookingListModel => {
    let obj = new BookingListModel();
    if (data) {
      const { form_id, form_name, form_desc, valid_flg, form_tags, form_id_encode } = data;
      obj.id = form_id;
      obj.form_id_encode = form_id_encode ?? "";
      obj.name = form_name ?? "";
      obj.description = form_desc ?? "";
      obj.isActive = valid_flg ?? 1;
      obj.tags = form_tags ?? [];
    }
    return obj;
  };
}
