import { Api } from "../api"
import { EndPointApis } from 'app/constants';
import { PaymentMergeModel } from 'app/models/PaymentMergeModel';

/**
 * Service Regist
 */
export class RegistService {
  /**
   * get Payment Information
   */
  static getPaymentInformation() {
    return Api.get(EndPointApis.Regist.GET_PAYMENT_INFORMATION);
  }

  static mergeUserPaymentRequest(params: any) {
    return Api.post(EndPointApis.Regist.CREATE_NEW_PAYMENT_CREDIT, { ...params });
  }

  /**
   * create New Bank Payment Transfer
   * @param params 
   * @returns 
   */
  static createNewBankPaymentTransfer(params: any) {
    return Api.post(EndPointApis.Regist.CREATE_NEW_PAYMENT_BANK, {...params });
  };

  /**
   * update only payment plan
   */
  static updateOnlyPaymentPlanRequest(params: any) {
    return Api.put(EndPointApis.Regist.UPDATE_ONLY_PAYMENT_PLAN, { ...params })
  }

  /**
   * update payment with credit card infomation
   */
  static updatePaymentWithCreditCard(params: any) {
    return Api.put(EndPointApis.Regist.UPDATE_PAYMENT_CREDIT_CARD, { ...params })
  }

  /**
   * change payment to bank
   */
  static changePaymentToBank(params: any) {
    return Api.put(EndPointApis.Regist.CHANGE_PAYMENT_TO_BANK, { ...params })
  }
}