import { Action } from "../../../models/redux/action";
import { ENABLE_WARNING, DISABLE_WARNING } from "./action";


export const warningRedeucer = (
    state: any = { warning: false, isDelete: false },
    action: Action
) => {
    switch (action.type) {
        case ENABLE_WARNING:
            return {
                warning: true
            };
        case DISABLE_WARNING:
            return {
                warning: false,
                isDelete: true
            };
        default:
            return state;
    }
};